import React, { useContext, createRef, useState } from "react";
import axiosClient from "../axios-client";
import { useStateContext } from "../Context/ContextProvider";
import { Link, Navigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import * as Icon from "react-feather";


const Login = () => {

    const emailRef = createRef()
    const passwordRef = createRef()
    const { setUser, setToken, setRole, userRole } = useStateContext()
    const [message, setMessage] = useState(null)
    const [btnLoading, setBtnLoading] = useState(false)

    const [isFocused, setIsFocused] = useState(false);
    const [inputValue, setInputValue] = useState('');

    const [passwordType, setPasswordType] = useState(false)

    function handleInputFocus() {
        setIsFocused(true);
    }

    function handleInputBlur() {
        setIsFocused(false);
    }

    function handleInputChange(event) {
        setInputValue(event.target.value);
    }


    // const onSignup = (e) =>{
    //     e.preventDefault()
    //     {<Navigate to="newdealer"/>}
    // }
    const onSubmit = (e) => {
        e.preventDefault()
        setBtnLoading(true)
        const payload = {
            email: emailRef.current.value,
            password: passwordRef.current.value,
        }
        axiosClient.post('/login', payload)
            .then(({ data }) => {
                setUser(data.user)
                setRole(data.role)
                setToken(data.token);
                setBtnLoading(false)
                toast.success("Login Successful", {
                    position: "bottom-right",
                    autoClose: 1500,
                    theme: "colored",
                });
                window.location.replace("/");
            })
            .catch((err) => {
                setBtnLoading(false)
                const response = err.response;
                if (response && response.status === 422) {
                    setMessage(response.data.message)
                }
                toast.error(response.data.message, {
                    position: "bottom-right",
                    autoClose: 1500,
                    theme: "colored",
                });
            })
    }

    return (
        <div className="container darkMode">
            <div className="centerForm">
                <div className="authForm box-shadow-1 mb-4">
                    <form onSubmit={onSubmit}>

                        {/* <div className="input-container">
                            <label
                                className={isFocused || inputValue ? 'input-label input-label--focused' : 'input-label'}
                            >
                                Placeholder text
                            </label>
                            <input
                                type="text"
                                className="form-control"
                                onFocus={handleInputFocus}
                                onBlur={handleInputBlur}
                                onChange={handleInputChange}
                                value={inputValue}
                            />
                        </div> */}

                        <h4 className="mt-1 text-white">Login</h4>
                        <p className="text-white">Welcome back, please login to your account</p>

                        <div className="form-outline mb-3">
                            <input ref={emailRef} type="text" className="form-control" placeholder="Your Email" aria-label="Username" aria-describedby="addon-wrapping" />
                        </div>

                        <div className="form-outline mb-3">
                            <div className="inputIcon">
                                <input ref={passwordRef} type={passwordType?("text"):("password")} className="form-control" placeholder="You Password" />
                                <div className="inputIcons">
                                    {
                                        passwordType?
                                        (
                                            <span onClick={() => {setPasswordType(false)}} className="eyeOff"><Icon.EyeOff /></span>
                                        ):(
                                            <span onClick={() => {setPasswordType(true)}} className="eye"><Icon.Eye /></span>
                                        )
                                    }
                                </div>
                            </div>
                        </div>

                        <div className="remember mb-3">
                            <div className="form-check">
                                <input className="form-check-input" type="checkbox" value="" id="exampleCheck1" />
                                <label className="form-check-label text-white" for="exampleCheck1">Remember Me</label>
                            </div>
                            <div>
                                <Link to="/forgot-password" className="ml-2 forgot_password">Forgot password?</Link>
                            </div>
                        </div>

                        <div className="d-grid">
                            <button type="submit" className="btn btn-primary btn-block" onClick={onSubmit} disabled={btnLoading}>
                                {
                                    btnLoading ?
                                        (
                                            <>Please Wait <span className="spinner-grow spinner-grow-sm ml-2" role="status" aria-hidden="true"></span></>
                                        ) : (
                                            <>Login</>
                                        )
                                }
                            </button>
                        </div>

                    </form>
                </div>
                {/* <div className="text-center text-white">
                    <p className="text-white">
                        <span>Not a Member yet? </span>
                        <Link to="/register" className="ml-2">Register</Link>
                    </p>
                </div> */}
            </div>
        </div>
    )
}

export default Login

