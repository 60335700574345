import React, { useContext, useEffect, useRef, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import axiosClient from "../axios-client";
import Userimg from "../images/man-2.png";
import axios from "axios";
import {
  useTable,
  useFilters,
  useGlobalFilter,
  useSortBy,
  useAsyncDebounce,
  usePagination,
} from "react-table";
import * as Icon from "react-feather";
import { ComponentLoading } from "../Components/ComponentLoading";
import { ToastContainer, toast } from "react-toastify";
import Swal from "sweetalert2";
import { Modal, Button, Nav, Tab, Row, Col } from "react-bootstrap";
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import moment from 'moment';
import Select from 'react-select'
import { ActionMeta, OnChangeValue } from 'react-select';

import DatePicker from "react-datepicker";

import logo from '../images/cr-computer.png'

//token
const accessToken = localStorage.getItem("ACCESS_TOKEN");

function EmployeeTerminationModal({
    prop_refreshEditCompany,
    prop_companyEmployeesRequest,
    prop_employeeTerminationAddModal,
    prop_handleEmployeeModalTerminationAddClose,
    prop_employeeId
}) {

    const modalBodyRef = useRef(null);

    const [getComponentLoading, setComponentLoading] = useState(true);
    const [btnLoading, setBtnLoading] = useState(false)

    // TerminationAdd Company Modal
    const [employeeTerminationAddModal, setEmployeeTerminationAddModal] = useState(false);
    const handleEmployeeTerminationAddModalClose = () => {
        setEmployeeTerminationAddModal(false)
        prop_companyEmployeesRequest()
        prop_handleEmployeeModalTerminationAddClose()
        scrollToTop()

        set_new_employee_name('')
        set_job_title('')
        set_phone_number('')
        set_location('')
        set_employment_status('')
        set_termination_date('')
        set_cancellation('')
        set_equipment('')
        set_software('')
        set_special_requirements('')
        set_special_requests('')
        set_approved_supervisor_name('')
        set_approved_supervisor_date('')
        
        handleFormStepBtn('first')
        setStepsData({
            step_first: 'on',
            step_second: 'remain',
            step_third: 'remain',
        });

    };

    const [getLocations, setLocations] = useState(["main","warehouse","port_chester","sunrise_warehouse","blendex"])

    const [getCancellation, setCancellation] = useState(["remote_access","computer_access","preserve_data_files","assign_data_to","email_access","preserve_email_files","forward_emails","create_shared_email_box","assign_email_to"])

    const [getEquipment, setEquipment] = useState(["laptop_desktop","tablet","printer","scanner","docking_station","mobile_device","camera","keyboard_mouse"])

    const [getSoftwares, setSoftwares] = useState(["office_360","rfms","timberline","timberscan","measure_it","pro_tech","quickbooks","quicken","nitropdf","plan_swift","xactimate","ms_project","adobe_acrobat_pro","rms_operations"])

    const [getFormStep, setFormStep] = useState("first");
    const [getEmployeeId, setEmployeeId] = useState(null);

    const [get_company_name, set_company_name] = useState("");

    // Step first
    const [get_new_employee_name, set_new_employee_name] = useState("");
    const [get_job_title, set_job_title] = useState("");
    const [get_phone_number, set_phone_number] = useState("");
    const [get_location, set_location] = useState("");
    
    // Step second
    const [get_employment_status, set_employment_status] = useState("");
    const [get_termination_date, set_termination_date] = useState("");
    const [get_cancellation, set_cancellation] = useState("");
    const [get_equipment, set_equipment] = useState("");
    const [get_software, set_software] = useState("");
    
    // Step third
    const [get_special_requirements, set_special_requirements] = useState("");
    const [get_special_requests, set_special_requests] = useState("");
    const [get_approved_supervisor_name, set_approved_supervisor_name] = useState(null);
    const [get_approved_supervisor_date, set_approved_supervisor_date] = useState(null);
    
    const [getStepsData, setStepsData] = useState({
        step_first: 'on',
        step_second: 'remain',
        step_third: 'remain',
    });

    const handleFormStepBtn = (step) => {
        setFormStep(step)
        scrollToTop()
    }

    const getEmployeeData = (empId) => {
        let config = {
          method: "get",
          url: process.env.REACT_APP_API_URL + "/employees/"+empId+"/get",
          headers: {
            Accept: "application/json",
            Authorization: `Bearer ${accessToken}`,
          },
        };
    
        axios(config)
            .then((response) => {

                const emp = response.data.employee

                if(emp.company){ set_company_name(emp.company.name) }
                
                // Step first
                if(emp.new_employee_name){ set_new_employee_name(emp.new_employee_name) }
                if(emp.job_title){ set_job_title(emp.job_title) }
                if(emp.phone_number){ set_phone_number(emp.phone_number) }
                if(emp.location){ set_location(emp.location) }

                if(emp.status){ set_employment_status(emp.status) }
                if(emp.employee_termination){
                    const et = emp.employee_termination
                    // Step second
                    if(et.termination_date){
                        const d = moment(et.termination_date).format('MM/D/YYYY');
                        set_termination_date(new Date(d))
                    }
                    if(et.cancellation){ set_cancellation(JSON.parse(et.cancellation)) }
                    if(et.equipment){ set_equipment(JSON.parse(et.equipment)) }
                    if(et.software){ set_software(JSON.parse(et.software)) }
                    
                    // Step third
                    if(et.special_requirements){ set_special_requirements(et.special_requirements) }
                    if(et.special_requests){ set_special_requests(et.special_requests) }
                    if(et.approved_supervisor_name){ set_approved_supervisor_name(et.approved_supervisor_name) }
                    if(et.approved_supervisor_date){
                        const d = moment(et.approved_supervisor_date).format('MM/D/YYYY');
                        set_approved_supervisor_date(new Date(d))
                    }
                }

                setComponentLoading(false);

            })
            .catch((error) => {
                setComponentLoading(false);
            });
    };

    useEffect(() => {

        if(prop_employeeId){
            // console.log('prop_employeeId=',prop_employeeId)
            setEmployeeId(prop_employeeId)
            getEmployeeData(prop_employeeId)
        }

        if(prop_employeeTerminationAddModal){
            setEmployeeTerminationAddModal(prop_employeeTerminationAddModal)
        }

        // console.log('getFormStep=>>>',getFormStep)
        
    }, [prop_employeeTerminationAddModal, prop_employeeId]);

    const handleSoftwareChange = (value) => {
        if (get_software.includes(value)) {
            set_software(get_software.filter(item => item !== value));
        } else {
            set_software([...get_software, value]);
        }
    }

    const handleEquipmentChange = (value) => {
        if (get_equipment.includes(value)) {
            set_equipment(get_equipment.filter(item => item !== value));
        } else {
            set_equipment([...get_equipment, value]);
        }
    }

    const handleCancellationChange = (value) => {
        if (get_cancellation.includes(value)) {
            set_cancellation(get_cancellation.filter(item => item !== value));
        } else {
            set_cancellation([...get_cancellation, value]);
        }
    }

    const handleDateSelectApprovedSupervisorDate = (date) => {
        console.log('handleDateSelectApprovedSupervisorDate=',date)
        set_approved_supervisor_date(date)
    }

    const handleDateTerminationDate = (date) => {
        // console.log('handleDateTerminationDate=',date)
        set_termination_date(date)
    }

    const scrollToTop = () =>{
        if (modalBodyRef.current) {
            modalBodyRef.current.scrollTop = 0;
        }
    }

    const saveBtn = () => {
        setBtnLoading(true)
        let payload = {

            step: getFormStep,
            employee_id: getEmployeeId,
            
            // Step second
            employment_status: get_employment_status,
            termination_date: get_termination_date,
            cancellation: get_cancellation,
            equipment: get_equipment,
            software: get_software,

            // Step third
            special_requirements: get_special_requirements,
            special_requests: get_special_requests,
            approved_supervisor_name: get_approved_supervisor_name,
            approved_supervisor_date: get_approved_supervisor_date,

        }
        axiosClient.post('/employee/'+getEmployeeId+"/termination", payload)
            .then((res) => {
                setBtnLoading(false)
                console.log('payload=',payload.start_date)
                if(res.data.step == 'second'){
                    setStepsData(prevState => {
                        return {
                            ...prevState,
                            step_first: 'completed',
                            step_second: 'on'
                        };
                    });
                }
                if(res.data.step == 'third'){
                    setStepsData(prevState => {
                        return {
                            ...prevState,
                            step_second: 'completed',
                            step_third: 'on'
                        };
                    });
                }
                if(res.data.step == 'first' || res.data.step == 'second' || res.data.step == 'third'){
                    setFormStep(res.data.step)
                    scrollToTop()
                } else {
                    setTimeout(() => {
                        toast.success(res.data.message, {
                            position: "bottom-right",
                            autoClose: 1500,
                            theme: "colored",
                        });
                        prop_refreshEditCompany()
                        prop_companyEmployeesRequest()
                        handleEmployeeTerminationAddModalClose()
                    }, 500);
                }
                console.log('getFormStep2=',getFormStep)
            })
            .catch(err => {
                setBtnLoading(false)
                var firstErrorMessage = '';
                if (err.response.data.errors && Object.keys(err.response.data.errors).length != 0) {
                var errors = err.response.data.errors
                const errorMessages = Object.values(errors); // Get an array of all error messages
                firstErrorMessage = errorMessages.shift(); // Get the first error message
                toast.error(firstErrorMessage[0], {
                    position: "bottom-right",
                    autoClose: 1500,
                    theme: "colored",
                });
                } else {
                // console.log('firstErrorMessage',firstErrorMessage[0])
                toast.error(err.response.data.message, {
                    position: "bottom-right",
                    autoClose: 1500,
                    theme: "colored",
                });
                }
                // console.log(err);
            });

    }

    useEffect(() => {
        console.log('getFormStep=>>>',getFormStep)
    }, [getFormStep]);

  return (
    <>
    <Modal
        className="customModal" // hide-btn-close
        backdrop="static"
        centered
        size="xl"
        show={employeeTerminationAddModal}
        onHide={handleEmployeeTerminationAddModalClose}
      >
        <Modal.Header closeButton>
          <Modal.Title>
            <img className="modalLogo" src={logo} />

            <div>
                <ul className="stepsHeading">
                    <li>
                        <span className={"step step_first "+getStepsData.step_first}>
                            {getStepsData.step_first!='remain'?(<Icon.CheckCircle />):(<Icon.Circle />)}
                        </span>
                        <span className="heading">Employee Information</span>
                    </li>
                    <li>
                        <span className={"step step_second "+getStepsData.step_second}>
                            {getStepsData.step_second!='remain'?(<Icon.CheckCircle />):(<Icon.Circle />)}
                        </span>
                        <span className="heading">Status Information</span>
                    </li>
                    <li>
                        <span className={"step step_third "+getStepsData.step_third}>
                            {getStepsData.step_third!='remain'?(<Icon.CheckCircle />):(<Icon.Circle />)}
                        </span>
                        <span className="heading">Requirements & Requests</span>
                    </li>
                </ul>
            </div>

          </Modal.Title>
        </Modal.Header>
        <Modal.Body ref={modalBodyRef}>
          <form>
            {getComponentLoading ? (
                <div className="card loadingCard">
                <div className="card-body">
                    <ComponentLoading></ComponentLoading>
                </div>
                </div>
            ) : (
                <>
                {/* {getEmployeeId} */}
                <div className="row">
                    <div className="col-sm-3"></div>
                    <div className="col-sm-6">
                        {
                            getFormStep == 'first'?
                            (
                                <>
                                <h4>Employee Information</h4>
                                <table className="table table-striped">
                                    <tbody>
                                        <tr>
                                            <td width={200}>Terminated Employee Name:</td>
                                            <td>
                                                <strong>{get_new_employee_name}</strong>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>Location:</td>
                                            <td>
                                                <strong>{get_location}</strong>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>Company:</td>
                                            <td>
                                                <strong>{get_company_name}</strong>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>Phone:</td>
                                            <td>
                                                <strong>{get_phone_number}</strong>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>Job Title:</td>
                                            <td>
                                                <strong>{get_job_title}</strong>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                                </>
                            ):getFormStep == 'second'?
                            (
                                <>
                                <h4>Status Information</h4>
                                <div className="mb-3">
                                    <label className="form-label">
                                        Employment Stauts<span className="text-danger">*</span>
                                    </label>
                                    <div class="form-check">
                                        <input
                                            className="form-check-input"
                                            type="radio"
                                            id="get_employment_status_exit"
                                            name="get_employment_status"
                                            value={'exit'}
                                            checked={get_employment_status === 'exit'}
                                            onChange={e => set_employment_status(e.target.value)}
                                        />
                                        <label className="form-check-label" htmlFor="get_employment_status_exit">Exit</label>
                                    </div>
                                    <div class="form-check">
                                        <input
                                            className="form-check-input"
                                            type="radio"
                                            id="get_employment_status_terminated"
                                            name="get_employment_status"
                                            value={'terminated'}
                                            checked={get_employment_status === 'terminated'}
                                            onChange={e => set_employment_status(e.target.value)}
                                        />
                                        <label className="form-check-label" htmlFor="get_employment_status_terminated">Terminated</label>
                                    </div>
                                </div>
                                <div className="mb-3">
                                    <label for="get_termination_date" className="form-label">
                                        Termination Date<span className="text-danger">*</span>
                                    </label>
                                    <div>
                                        <DatePicker
                                            className="form-control"
                                            id="get_termination_date"
                                            selected={get_termination_date}
                                            onSelect={handleDateTerminationDate}
                                            dateFormat="MM/dd/yyyy"
                                        />
                                    </div>
                                </div>
                                <div className="mb-3">
                                    <label for="get_location" className="form-label">
                                    Cancellation Please check all required access revocation<span className="text-danger">*</span>
                                    </label>
                                    {getCancellation.map(item => (
                                    <div className="form-check" key={item}>
                                        <input
                                        className="form-check-input"
                                        type="checkbox"
                                        id={'location-'+item}
                                        name="item"
                                        value={item}
                                        checked={get_cancellation.includes(item)}
                                        onChange={e => handleCancellationChange(e.target.value)}
                                        />
                                        <label className="form-check-label" htmlFor={'location-'+item}>{item}</label>
                                    </div>
                                    ))}
                                </div>
                                <div className="mb-3">
                                    <label className="form-label">
                                    Equipment Please check all device that you will be confiscated and stored in the server room
                                    </label>
                                    {getEquipment.map(item => (
                                    <div className="form-check" key={item}>
                                        <input
                                        className="form-check-input"
                                        type="checkbox"
                                        id={'equipment-'+item}
                                        name="item"
                                        value={item}
                                        checked={get_equipment.includes(item)}
                                        onChange={e => handleEquipmentChange(e.target.value)}
                                        />
                                        <label className="form-check-label" htmlFor={'equipment-'+item}>{item}</label>
                                    </div>
                                    ))}
                                </div>
                                <div className="mb-3">
                                    <label className="form-label">
                                    Software
                                    </label>
                                    {getSoftwares.map(item => (
                                    <div className="form-check" key={item}>
                                        <input
                                        className="form-check-input"
                                        type="checkbox"
                                        id={'software-'+item}
                                        name="item"
                                        value={item}
                                        checked={get_software.includes(item)}
                                        onChange={e => handleSoftwareChange(e.target.value)}
                                        />
                                        <label className="form-check-label" htmlFor={'software-'+item}>{item}</label>
                                    </div>
                                    ))}
                                </div>
                                </>
                            ):(
                                <>
                                <h4>Requirements & Requests</h4>
                                <div className="mb-3">
                                    <label for="get_special_requirements" className="form-label">
                                        Special Requirements
                                    </label>
                                    <textarea type="text" className="form-control" id="get_special_requirements" required value={get_special_requirements} onChange={e => set_special_requirements(e.target.value)}></textarea>
                                </div>
                                <div className="mb-3">
                                    <label for="get_special_requests" className="form-label">
                                        Special Requests
                                    </label>
                                    <textarea type="text" className="form-control" id="get_special_requests" required value={get_special_requests} onChange={e => set_special_requests(e.target.value)}></textarea>
                                </div>
                                <div className="mb-3">
                                    <label for="get_approved_supervisor_name" className="form-label">
                                        Approved by Supervisor: (Name)<span className="text-danger">*</span>
                                    </label>
                                    <input type="text" className="form-control" id="get_approved_supervisor_name" required value={get_approved_supervisor_name} onChange={e => set_approved_supervisor_name(e.target.value)} />
                                </div>
                                <div className="mb-3">
                                    <label for="get_approved_supervisor_date" className="form-label">
                                        Date<span className="text-danger">*</span>
                                    </label>
                                    <div>
                                        <DatePicker
                                            className="form-control"
                                            id="get_start_date"
                                            selected={get_approved_supervisor_date}
                                            onSelect={handleDateSelectApprovedSupervisorDate}
                                            dateFormat="MM/dd/yyyy"
                                        />
                                    </div>
                                    {/* <input type="text" className="form-control" id="get_approved_supervisor_date" required value={get_approved_supervisor_date} onChange={e => set_approved_supervisor_date(e.target.value)} /> */}
                                </div>
                                </>
                            )
                        }
                    </div>
                    <div className="col-sm-3"></div>
                </div>
                </>
            )}
          </form>
        </Modal.Body>
        <Modal.Footer>
          <div className="row">
            <div className="col-sm-3"></div>
            <div className="col-sm-6">
              <div className="centeredBtns">
                {
                    getFormStep == 'first'?
                    (
                        <>
                            <Button
                            variant="btn btn-outline-primary"
                            onClick={handleEmployeeTerminationAddModalClose}
                            >
                                Close
                            </Button>
                            <button type="submit" className="btn btn-primary" disabled={btnLoading} onClick={() => {saveBtn()}}>
                            {
                                btnLoading ?
                                (
                                    <>Please Wait <span className="spinner-grow spinner-grow-sm ml-2" role="status" aria-hidden="true"></span></>
                                ) : (
                                    <>Next</>
                                )
                            }
                            </button>
                        </>
                    ):getFormStep == 'second'?
                    (
                        <>
                            <Button
                            variant="btn btn-outline-primary"
                            onClick={() => {handleFormStepBtn('first')}}
                            >
                                Back
                            </Button>
                            <button type="submit" className="btn btn-primary" disabled={btnLoading} onClick={() => {saveBtn()}}>
                            {
                                btnLoading ?
                                (
                                    <>Please Wait <span className="spinner-grow spinner-grow-sm ml-2" role="status" aria-hidden="true"></span></>
                                ) : (
                                    <>Next</>
                                )
                            }
                            </button>
                        </>
                    ):(
                        <>
                        <Button
                        variant="btn btn-outline-primary"
                        onClick={() => {handleFormStepBtn('second')}}
                        >
                            Back
                        </Button>
                        <button type="submit" className="btn btn-primary" disabled={btnLoading} onClick={saveBtn}>
                        {
                            btnLoading ?
                            (
                                <>Please Wait <span className="spinner-grow spinner-grow-sm ml-2" role="status" aria-hidden="true"></span></>
                            ) : (
                                <>Save</>
                            )
                        }
                        </button>
                        </>
                    )
                }
              </div>
            </div>
            <div className="col-sm-3"></div>
          </div>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default EmployeeTerminationModal;
