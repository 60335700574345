import React, { useState, useEffect } from 'react';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import * as Icon from "react-feather";

function CompaniesOtherSitePhoneNumbers({ otherSitePhoneNumbers, onSelect }) {
  const [phoneNumbers, setPhoneNumbers] = useState([]);
  const [newPhoneNumber, setNewPhoneNumber] = useState('');

  useEffect(() => {
    console.log('otherSitePhoneNumbers=>>>',otherSitePhoneNumbers)
    setPhoneNumbers(otherSitePhoneNumbers);
  }, [otherSitePhoneNumbers]);

  const handleInputChange = (e, index) => {
    const updatedPhoneNumbers = [...phoneNumbers];
    updatedPhoneNumbers[index] = e.target.value;
    setPhoneNumbers(updatedPhoneNumbers);
    onSelect(updatedPhoneNumbers);
  };

  const addItem = () => {
    if (newPhoneNumber.trim() !== '') {
      setPhoneNumbers([...phoneNumbers, newPhoneNumber]);
      setNewPhoneNumber('');
      onSelect([...phoneNumbers, newPhoneNumber]);
    }
  };

  const removeItem = (index) => {
    const updatedPhoneNumbers = phoneNumbers.filter((_, i) => i !== index);
    setPhoneNumbers(updatedPhoneNumbers);
    onSelect(updatedPhoneNumbers);
  };

  return (
    <>
    <div class="input-group mb-3">
        <input type="text" className='form-control' value={newPhoneNumber} onChange={(e) => setNewPhoneNumber(e.target.value)} placeholder="Enter phone number" />
        <button type='button' className='btn btn-primary' onClick={addItem}>Add</button>
    </div>
    <div className="card bgLight">
      <div className="card-body">

        {
          phoneNumbers.length > 0 ? (
            <>
              <ul>
              {
                phoneNumbers.map((item, index) => (
                  <li key={index}>
                      <div className='d-flex align-items-center'>
                          {item}
                          <OverlayTrigger
                              trigger={['hover', 'focus']}
                              placement="top"
                              overlay={<Tooltip id="example-tooltip">Remove</Tooltip>}
                          >
                              <span
                                  className="justIcon cursor-pointer ml-1"
                                  onClick={() => removeItem(index)}
                              >
                                  <Icon.Trash width={14} />
                              </span>
                          </OverlayTrigger>
                      </div>
                  </li>
                  ))
              }
              </ul>
            </>
          ):(
            <>
            <div className='text-muted text-center' style={{fontSize: '13px'}}>No record found</div>
            </>
          )
        }

        {/* <div class="input-group mb-3">
            <input type="text" className='form-control' value={newPhoneNumber} onChange={(e) => setNewPhoneNumber(e.target.value)} placeholder="Enter an phone number" />
            <button type='button' className='btn btn-primary' onClick={addItem}>Add</button>
        </div>

        {
            phoneNumbers.length > 0 && (
                <table className='table table-striped mb-0'>
                    <tr className='tableHeader'>
                        <th>Other Site Phone Numbers</th>
                    </tr>
                    <tbody>
                        {phoneNumbers.map((item, index) => (
                        <tr key={index}>
                            <td>
                                <div class="input-group">
                                    <input type="text" className='form-control' value={item} onChange={(e) => handleInputChange(e, index)} />
                                    <OverlayTrigger
                                        trigger={['hover', 'focus']}
                                        placement="top"
                                        overlay={<Tooltip id="example-tooltip">Delete</Tooltip>}
                                    >
                                        <button
                                            type='button'
                                            className="btn btn-danger"
                                            aria-expanded="false"
                                            onClick={() => removeItem(index)}
                                        >
                                            Remove
                                        </button>
                                    </OverlayTrigger>
                                </div>
                            </td>
                        </tr>
                        ))}
                    </tbody>
                </table>
            )
        } */}

      </div>
    </div>
    </>
  );

}

export default CompaniesOtherSitePhoneNumbers;
