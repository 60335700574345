import React, { useContext, useEffect, useRef, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import axiosClient from "../axios-client";
import Userimg from "../images/man-2.png";
import axios from "axios";
import {
  useTable,
  useFilters,
  useGlobalFilter,
  useSortBy,
  useAsyncDebounce,
  usePagination,
} from "react-table";
import * as Icon from "react-feather";
import { ComponentLoading } from "../Components/ComponentLoading";
import { ToastContainer, toast } from "react-toastify";
import Swal from "sweetalert2";
import { Modal, Button, Nav, Tab, Row, Col } from "react-bootstrap";
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import moment from 'moment';
import Select from 'react-select'
import { ActionMeta, OnChangeValue } from 'react-select';

import DatePicker from "react-datepicker";

import logo from '../images/cr-computer.png'

// ** Store Imports
import { useDispatch, useSelector } from 'react-redux'

//token
const accessToken = localStorage.getItem("ACCESS_TOKEN");

function CompanyEditModal({
    prop_refreshEditCompany,
    prop_companyProductsRequest,
    prop_ProductEditModal,
    prop_handleProductEditModalClose,
    prop_ProductId
}) {
    
    const stMainUser = useSelector((state) => state.stMainUser)

    // const [getEditComponentLoading, setEditComponentLoading] = useState(true);
    const [btnLoading, setBtnLoading] = useState(false)

    // Add Company Modal
    const [productEditModal, setProductEditModal] = useState(false);
    const [getComponentLoading, setComponentLoading] = useState(true);

    const handleProductEditModalClose = () => {
        setProductEditModal(false)
        prop_handleProductEditModalClose()
    };

    // Step first
    const [get_name, set_name] = useState("");
    const [get_vendor, set_vendor] = useState("");
    const [get_users, set_users] = useState("");
    const [get_location_hosted, set_location_hosted] = useState("");
    const [get_vendor_contact, set_vendor_contact] = useState("");
    const [get_vendor_phone, set_vendor_phone] = useState("");
    const [get_vendor_email, set_vendor_email] = useState("");
    const [get_vendor_website, set_vendor_website] = useState("");

    // const init = () => {
    //     if(stMainUser.stFetchCompanySites.company_sites){
    //         setCompanySites(stMainUser.stFetchCompanySites.company_sites);
    //     }
    // };

    const getCompanyProductData = (id) => {
        setComponentLoading(true)
        let config = {
          method: "get",
          url: process.env.REACT_APP_API_URL + "/company-products/"+id+"/get",
          headers: {
            Accept: "application/json",
            Authorization: `Bearer ${accessToken}`,
          },
        };
    
        axios(config)
            .then((response) => {
                const data = response.data.CompanyProduct
                
                if(data.name){ set_name(data.name) }
                if(data.vendor){ set_vendor(data.vendor) }
                if(data.users){ set_users(data.users) }
                if(data.location_hosted){ set_location_hosted(data.location_hosted) }
                if(data.vendor_contact){ set_vendor_contact(data.vendor_contact) }
                if(data.vendor_phone){ set_vendor_phone(data.vendor_phone) }
                if(data.vendor_email){ set_vendor_email(data.vendor_email) }
                if(data.vendor_website){ set_vendor_website(data.vendor_website) }

                setComponentLoading(false);

            })
            .catch((error) => {
                setComponentLoading(false);
            });
    };

    useEffect(() => {

        // init()

        if(prop_ProductId){
            // console.log('prop_ProductId=',prop_ProductId)
            // setEmployeeId(prop_ProductId)
            getCompanyProductData(prop_ProductId)
        }

        if(prop_ProductEditModal){
            setProductEditModal(prop_ProductEditModal)
        }

        // console.log('getFormStep=>>>',getFormStep)
        
    }, [prop_ProductEditModal, prop_ProductId, stMainUser]);

    const saveBtn = () => {
        setBtnLoading(true)
        let payload = {

            product_id: prop_ProductId,

            name: get_name,
            vendor: get_vendor,
            users: get_users,
            location_hosted: get_location_hosted,
            vendor_contact: get_vendor_contact,
            vendor_phone: get_vendor_phone,
            vendor_email: get_vendor_email,
            vendor_website: get_vendor_website,

        }
        axiosClient.put('/company-products/'+prop_ProductId, payload)
            .then((res) => {
                setBtnLoading(false)
                toast.success(res.data.message, {
                    position: "bottom-right",
                    autoClose: 1500,
                    theme: "colored",
                });
                prop_refreshEditCompany()
                prop_companyProductsRequest()
                handleProductEditModalClose()
            })
            .catch(err => {
                setBtnLoading(false)
                var firstErrorMessage = '';
                if (err.response.data.errors && Object.keys(err.response.data.errors).length != 0) {
                var errors = err.response.data.errors
                const errorMessages = Object.values(errors); // Get an array of all error messages
                firstErrorMessage = errorMessages.shift(); // Get the first error message
                toast.error(firstErrorMessage[0], {
                    position: "bottom-right",
                    autoClose: 1500,
                    theme: "colored",
                });
                } else {
                // console.log('firstErrorMessage',firstErrorMessage[0])
                toast.error(err.response.data.message, {
                    position: "bottom-right",
                    autoClose: 1500,
                    theme: "colored",
                });
                }
                // console.log(err);
            });

    }

  return (
    <>
    <Modal
        className="customModal" // hide-btn-close
        backdrop="static"
        centered
        size="xl"
        show={productEditModal}
        onHide={handleProductEditModalClose}
      >
        <Modal.Header closeButton>
          <Modal.Title>
            <img className="modalLogo" src={logo} />

            Product Edit

          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form>
          {getComponentLoading ? (
                <div className="card loadingCard">
                <div className="card-body">
                    <ComponentLoading></ComponentLoading>
                </div>
                </div>
            ) : (
                <>
                    <div className="row">
                        <div className="col-sm-3"></div>
                        <div className="col-sm-6">
                            <div className="mb-3">
                                <label for="get_name" className="form-label">
                                    Name<span className="text-danger">*</span>
                                </label>
                                <input type="text" className="form-control" id="get_name" required value={get_name} onChange={e => set_name(e.target.value)} />
                            </div>
                            <div className="mb-3">
                                <label for="get_vendor" className="form-label">
                                    Vendor
                                </label>
                                <input type="text" className="form-control" id="get_vendor" required value={get_vendor} onChange={e => set_vendor(e.target.value)} />
                            </div>
                            <div className="mb-3">
                                <label for="get_users" className="form-label">
                                    Users
                                </label>
                                <input type="text" className="form-control" id="get_users" required value={get_users} onChange={e => set_users(e.target.value)} />
                            </div>
                            <div className="mb-3">
                                <label for="get_location_hosted" className="form-label">
                                    Location Hosted
                                </label>
                                <input type="text" className="form-control" id="get_location_hosted" required value={get_location_hosted} onChange={e => set_location_hosted(e.target.value)} />
                            </div>
                            <div className="mb-3">
                                <label for="get_vendor_contact" className="form-label">
                                    Vendor Contact
                                </label>
                                <input type="text" className="form-control" id="get_vendor_contact" required value={get_vendor_contact} onChange={e => set_vendor_contact(e.target.value)} />
                            </div>
                            <div className="mb-3">
                                <label for="get_vendor_phone" className="form-label">
                                    Vendor Phone
                                </label>
                                <input type="text" className="form-control" id="get_vendor_phone" required value={get_vendor_phone} onChange={e => set_vendor_phone(e.target.value)} />
                            </div>
                            <div className="mb-3">
                                <label for="get_vendor_email" className="form-label">
                                    Vendor Email
                                </label>
                                <input type="text" className="form-control" id="get_vendor_email" required value={get_vendor_email} onChange={e => set_vendor_email(e.target.value)} />
                            </div>
                            <div className="mb-3">
                                <label for="get_vendor_website" className="form-label">
                                    Vendor Website
                                </label>
                                <input type="text" className="form-control" id="get_vendor_website" required value={get_vendor_website} onChange={e => set_vendor_website(e.target.value)} />
                            </div>
                        </div>
                        <div className="col-sm-3"></div>
                    </div>
                </>
            )}
          </form>
        </Modal.Body>
        <Modal.Footer>
          <div className="row">
            <div className="col-sm-3"></div>
            <div className="col-sm-6">
              <div className="centeredBtns">
                    <Button
                    variant="btn btn-outline-primary"
                    onClick={handleProductEditModalClose}
                    >
                        Close
                    </Button>
                    <button type="submit" className="btn btn-primary" disabled={btnLoading} onClick={saveBtn}>
                    {
                        btnLoading ?
                        (
                            <>Please Wait <span className="spinner-grow spinner-grow-sm ml-2" role="status" aria-hidden="true"></span></>
                        ) : (
                            <>Save</>
                        )
                    }
                    </button>
              </div>
            </div>
            <div className="col-sm-3"></div>
          </div>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default CompanyEditModal;
