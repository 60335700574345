import React, { useContext, createRef, useState, useEffect } from "react";
import axiosClient from "../axios-client";
import { useStateContext } from "../Context/ContextProvider";
import { Link, useNavigate, useLocation, useParams } from "react-router-dom";
import axios from "axios";

const accessToken = localStorage.getItem('ACCESS_TOKEN');

const Login = () => {

    let { userId, token } = useParams();

    const location = useLocation();
    const navigate = useNavigate();

    const inviteParam = new URLSearchParams(location.search);

    const [getMessage, setMessage] = useState('')
    const [btnLoading, setBtnLoading] = useState(false)

    const getVerification = () => {
        setBtnLoading(true)
        let config = {
            method: 'get',
            url: process.env.REACT_APP_API_URL + '/verification/' + userId + '/' + token,
            headers: {
                'Accept': 'application/json',
                'Authorization': `Bearer ${accessToken}`
            }
        };
        axios(config)
            .then((res) => {
                setBtnLoading(false)
                // setComponentLoading(false)
                console.log('res=', res);
                setMessage(res.data.message)
            })
            .catch(err => {
                setBtnLoading(false)
                console.log(err.response.data)
                if (err.response.data.errors.length > 0) {
                    var errors = err.response.data.errors
                    const errorMessages = Object.values(errors); // Get an array of all error messages
                    setMessage(errorMessages.shift()); // Get the first error message
                } else {
                    setMessage(err.response.data.message)
                }
            });
    }

    useEffect(() => {

        console.log('process.env.REACT_APP_API_URL=', process.env.REACT_APP_API_URL)

        getVerification()

        // if(inviteParam.has("invite") && (inviteParam.get("invite") || inviteParam.get("invite") != '')){
        //     if(inviteParam.get("invite") == 'success'){
        //         setMessage('Invitation has been accepted, now you can login')
        //     } else {
        //         setMessage('Something went wrong')
        //     }
        // } else {
        //     navigate("/login");
        // }

    }, []);

    return (
        <div className="container darkMode">
            <div className="centerForm">
                <div className="authForm box-shadow-1 mb-4">


                    {/* <h4 className="mt-1">Invitation</h4> */}
                    <br />
                    <p className="text-white" style={{minHeight:'24px'}}>{getMessage}</p>
                    <br />

                    <div className="d-grid">
                        {/* <Link to="/login" className="btn btn-primary btn-block">Login</Link> */}
                        <Link to="/login" className="btn btn-primary btn-block" disabled={btnLoading}>
                            {
                                btnLoading ?
                                    (
                                        <>Please Wait <span className="spinner-grow spinner-grow-sm ml-2" role="status" aria-hidden="true"></span></>
                                    ) : (
                                        <>Login</>
                                    )
                            }
                        </Link>
                    </div>


                </div>
                <div className="text-center text-white">
                    <p>
                        <span>Not a Member yet? </span>
                        <Link to="/register" className="ml-2">Register</Link>
                    </p>
                </div>
            </div>
        </div>
    )
}

export default Login

