import React, { useContext, useEffect, useRef, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import axiosClient from "../axios-client";
import Userimg from "../images/man-2.png";
import axios from "axios";
import {
  useTable,
  useFilters,
  useGlobalFilter,
  useSortBy,
  useAsyncDebounce,
  usePagination,
} from "react-table";
import * as Icon from "react-feather";
import { ComponentLoading } from "../Components/ComponentLoading";
import { ToastContainer, toast } from "react-toastify";
import Swal from "sweetalert2";
import { Modal, Button, Nav, Tab, Row, Col } from "react-bootstrap";
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import moment from 'moment';
import Select from 'react-select'
import { ActionMeta, OnChangeValue } from 'react-select';

import logo from '../images/cr-computer.png'

import CompaniesOtherSiteAddresses from '../PageComponents/CompaniesOtherSiteAddresses';
import CompaniesOtherSitePhoneNumbers from '../PageComponents/CompaniesOtherSitePhoneNumbers';

//token
const accessToken = localStorage.getItem("ACCESS_TOKEN");

function CompanyEditModal({ propgetCompanyData, propshowEditCompanyModal, prophandleEditCompanyModalClose, propgetEditComponentLoading, companiesRequest }) {
    const [getIndustries, setIndustries] = useState([]);

    const [getEditComponentLoading, setEditComponentLoading] = useState(true);

    // Edit Company Modal
    const [showEditCompanyModal, setShowEditCompanyModal] = useState(false);
    const handleEditCompanyModalClose = () => setShowEditCompanyModal(false);
    const handleEditCompanyModalShow = () => setShowEditCompanyModal(true);

    const [getCompanyEmail, setCompanyEmail] = useState("");
    const [getCompanyId, setCompanyId] = useState(null);
    const [getCompanyName, setCompanyName] = useState("");
    const [getCompanyMainAddress, setCompanyMainAddress] = useState("");
    const [getCompanyMainPhone, setCompanyMainPhone] = useState("");
    const [getCompanyIndustry, setCompanyIndustry] = useState(null);
    const [getCompanyOtherSiteAddresses, setCompanyOtherSiteAddresses] = useState([]);
    const [getCompanyOtherSitePhoneNumbers, setCompanyOtherSitePhoneNumbers] = useState([]);

  const [btnLoading, setBtnLoading] = useState(false)

  const handleChangeCompanyIndustry = (
    newValue: any,
    actionMeta: ActionMeta<any>
  ) => {
    setCompanyIndustry({id:newValue.id, name: newValue.name})
  };

    const editCompanyModalBtn = async (data) => {
    setEditComponentLoading(propgetEditComponentLoading)
    setShowEditCompanyModal(propshowEditCompanyModal)
    let config = {
        method: "get",
        url: process.env.REACT_APP_API_URL + "/companies/"+data.id,
        headers: {
          Accept: "application/json",
          Authorization: `Bearer ${accessToken}`,
        },
      };
  
      axios(config)
      .then((response) => {
        setIndustries(response.data.industries);
        setCompanyId(response.data.company.id);
        setCompanyName(response.data.company.name)
        setCompanyMainAddress(response.data.company.main_address)
        setCompanyMainPhone(response.data.company.main_phone)
        if(response.data.company.industry){
            setCompanyIndustry(response.data.company.industry)
        }
        if(response.data.company.company_other_site_addresses_data){
            setCompanyOtherSiteAddresses(response.data.company.company_other_site_addresses_data)
        } else {
            setCompanyOtherSiteAddresses([])
        }
        if(response.data.company.company_other_site_phone_numbers_data){
            setCompanyOtherSitePhoneNumbers(response.data.company.company_other_site_phone_numbers_data)
        } else {
            setCompanyOtherSitePhoneNumbers([])
        }
        setEditComponentLoading(false)
      })
      .catch((error) => {
        setEditComponentLoading(false);
      });
  };

  const handleOtherSiteAddressesChange = (addresses) => {
      console.log('addresses=',addresses)
      setCompanyOtherSiteAddresses(addresses);
  };
  
  const handleOtherSitePhoneNumbersChange = (phoneNumbers) => {
      console.log('phoneNumbers=',phoneNumbers)
      setCompanyOtherSitePhoneNumbers(phoneNumbers);
  };

  useEffect(() => {
    
    if(propgetCompanyData){
        editCompanyModalBtn(propgetCompanyData)
    }
    console.log('propgetCompanyData=>>>',propgetCompanyData)
    
  }, [propgetCompanyData]);

  const updateBtn = () => {
    setBtnLoading(true)
    let payload = {
      name: getCompanyName,
      main_address: getCompanyMainAddress,
      main_phone: getCompanyMainPhone,
      industry_id: getCompanyIndustry,
      other_site_addresses: getCompanyOtherSiteAddresses,
      other_site_phone_numbers: getCompanyOtherSitePhoneNumbers,
    }
    axiosClient.put('/companies/'+getCompanyId, payload)
      .then((res) => {
        toast.success(res.data.message, {
          position: "bottom-right",
          autoClose: 1500,
          theme: "colored",
        });
        setBtnLoading(false)
        companiesRequest()
        handleEditCompanyModalClose() // close company model
      })
      .catch(err => {
        setBtnLoading(false)
        var firstErrorMessage = '';
        if (err.response.data.errors && Object.keys(err.response.data.errors).length != 0) {
          var errors = err.response.data.errors
          const errorMessages = Object.values(errors); // Get an array of all error messages
          firstErrorMessage = errorMessages.shift(); // Get the first error message
          toast.error(firstErrorMessage[0], {
            position: "bottom-right",
            autoClose: 1500,
            theme: "colored",
          });
        } else {
          // console.log('firstErrorMessage',firstErrorMessage[0])
          toast.error(err.response.data.message, {
            position: "bottom-right",
            autoClose: 1500,
            theme: "colored",
          });
        }
        // console.log(err);
      });

  };

  return (
    <>
    <Modal
        className="customModal" // hide-btn-close
        backdrop="static"
        centered
        size="xl"
        show={showEditCompanyModal}
        onHide={handleEditCompanyModalClose}
      >
        <Modal.Header closeButton>
          <Modal.Title><img className="modalLogo" src={logo} /> Edit Company</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form>
            {getEditComponentLoading ? (
              <div className="card loadingCard">
                <div className="card-body">
                  <ComponentLoading></ComponentLoading>
                </div>
              </div>
            ) : (
              <>
                <div className="row">
                  <div className="col-sm-3"></div>
                  <div className="col-sm-6">
                    <div className="row">
                        <div className="col-sm-12">
                            <div className="mb-3">
                                <label for="email" className="form-label">
                                    Company Name
                                </label>
                                <input type="email" className="form-control" id="email" required value={getCompanyName} onChange={e => setCompanyName(e.target.value)} />
                            </div>
                        </div>
                        <div className="col-sm-12">
                            <div className="mb-3">
                                <label for="email" className="form-label">
                                    Main Phone Number
                                </label>
                                <input type="email" className="form-control" id="email" required value={getCompanyMainPhone} onChange={e => setCompanyMainPhone(e.target.value)} />
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-sm-12">
                            <div className="mb-3">
                                <label for="email" className="form-label">
                                    Main Address
                                </label>
                                <input type="email" className="form-control" id="email" required value={getCompanyMainAddress} onChange={e => setCompanyMainAddress(e.target.value)} />
                            </div>
                        </div>
                        <div className="col-sm-12">
                            <div className="mb-3">
                                <label for="industry" className="form-label">
                                    Industry
                                </label>
                                <Select
                                    className='customSelectDropdown'
                                    isClearable={false}
                                    onChange={handleChangeCompanyIndustry}
                                    value={getCompanyIndustry}
                                    options={getIndustries}
                                    getOptionValue={(option) => option.id}
                                    getOptionLabel={(option) => option.name}
                                    name='industry_id'
                                    placeholder='Select an industry...'
                                />
                            </div>
                        </div>
                    </div>
                  </div>
                  <div className="col-sm-3"></div>
                </div>
                {/* <div className="row mb-3">
                  <div className="col-sm-7">
                    <label className="form-label">
                        Other site addresses
                    </label>
                    <CompaniesOtherSiteAddresses 
                      otherSiteAddresses={getCompanyOtherSiteAddresses} 
                      onSelect={handleOtherSiteAddressesChange} 
                    />
                  </div>
                  <div className="col-sm-5">
                    <label className="form-label">
                        Other site phone numbers
                    </label>
                    <CompaniesOtherSitePhoneNumbers 
                        otherSitePhoneNumbers={getCompanyOtherSitePhoneNumbers} 
                        onSelect={handleOtherSitePhoneNumbersChange} 
                    />
                  </div>
                </div> */}
              </>
            )}
          </form>
        </Modal.Body>
        <Modal.Footer>
          <div className="row">
            <div className="col-sm-3"></div>
            <div className="col-sm-6">
              <div className="centeredBtns">
                <Button
                  variant="btn btn-outline-primary closeBtn"
                  onClick={handleEditCompanyModalClose}
                >
                  Close
                </Button>
                <button type="submit" className="btn btn-primary saveBtn" disabled={btnLoading} onClick={updateBtn}>
                  {
                    btnLoading ?
                      (
                        <>Please Wait <span className="spinner-grow spinner-grow-sm ml-2" role="status" aria-hidden="true"></span></>
                      ) : (
                        <>Save</>
                      )
                  }
                </button>
              </div>
            </div>
            <div className="col-sm-3"></div>
          </div>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default CompanyEditModal;
