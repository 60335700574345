import React, { useContext, useEffect, useRef, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import axiosClient from "../axios-client";
import Userimg from "../images/man-2.png";
import axios from "axios";
import {
  useTable,
  useFilters,
  useGlobalFilter,
  useSortBy,
  useAsyncDebounce,
  usePagination,
} from "react-table";
import * as Icon from "react-feather";
import { ComponentLoading } from "../Components/ComponentLoading";
import { ToastContainer, toast } from "react-toastify";
import Swal from "sweetalert2";
import { Modal, Button, Nav, Tab, Row, Col } from "react-bootstrap";
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import moment from 'moment';
import Select from 'react-select'
import { ActionMeta, OnChangeValue } from 'react-select';

import DatePicker from "react-datepicker";

import logo from '../images/cr-computer.png'

//token
const accessToken = localStorage.getItem("ACCESS_TOKEN");

function CompanyEditModal({
    prop_companyEmployeesRequest,
    prop_employeeUserAddModal,
    prop_handleEmployeeUserAddModalClose,
    prop_companyId,
    prop_employeeId,
}) {

    const [btnLoading, setBtnLoading] = useState(false)

    // Add Company Modal
    const [employeeUserAddModal, setEmployeeUserAddModal] = useState(false);
    const handleEmployeeUserAddModalClose = () => {
        setEmployeeUserAddModal(false)
        prop_handleEmployeeUserAddModalClose()
    };

    const [getCompanyId, setCompanyId] = useState(null);
    const [getEmployeeId, setEmployeeId] = useState(null);

    const [newPasswordType, setNewPasswordType] = useState(false);
    const [confirmPasswordType, setConfirmPasswordType] = useState(false)

    const [get_email, set_email] = useState("");
    const [get_password, set_password] = useState("");
    const [get_confirm_password, set_confirm_password] = useState("");

    useEffect(() => {

        if(prop_companyId){
            setCompanyId(prop_companyId)
        }

        if(prop_employeeId){
            setEmployeeId(prop_employeeId)
        }

        if(prop_employeeUserAddModal){
            setEmployeeUserAddModal(prop_employeeUserAddModal)
        }

        // console.log('getFormStep=>>>',getFormStep)
        
    }, [prop_employeeUserAddModal, prop_companyId, prop_employeeId]);

    const saveBtn = () => {
        setBtnLoading(true)
        let payload = {

            company_id: getCompanyId,
            employee_id: getEmployeeId,

            email: get_email,
            password: get_password,
            confirm_password: get_confirm_password,

        }
        axiosClient.post('/employees-users', payload)
            .then((res) => {
                setBtnLoading(false)
                console.log('payload=',payload.start_date)
                
                setTimeout(() => {
                    toast.success(res.data.message, {
                        position: "bottom-right",
                        autoClose: 1500,
                        theme: "colored",
                    });
                    prop_companyEmployeesRequest()
                    handleEmployeeUserAddModalClose()
                }, 500);

            })
            .catch(err => {
                setBtnLoading(false)
                var firstErrorMessage = '';
                if (err.response.data.errors && Object.keys(err.response.data.errors).length != 0) {
                var errors = err.response.data.errors
                const errorMessages = Object.values(errors); // Get an array of all error messages
                firstErrorMessage = errorMessages.shift(); // Get the first error message
                toast.error(firstErrorMessage[0], {
                    position: "bottom-right",
                    autoClose: 1500,
                    theme: "colored",
                });
                } else {
                // console.log('firstErrorMessage',firstErrorMessage[0])
                toast.error(err.response.data.message, {
                    position: "bottom-right",
                    autoClose: 1500,
                    theme: "colored",
                });
                }
                // console.log(err);
            });

    }

  return (
    <>
    <Modal
        className="customModal" // hide-btn-close
        backdrop="static"
        centered
        size="xl"
        show={employeeUserAddModal}
        onHide={handleEmployeeUserAddModalClose}
      >
        <Modal.Header closeButton>
          <Modal.Title>
            <img className="modalLogo" src={logo} />

            Set Password

          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form>
            {/* {prop_companyId} */}
            {/* {prop_employeeId} */}
            <div className="row">
                <div className="col-sm-3"></div>
                <div className="col-sm-6">
                    <div className="mb-3">
                        <label for="email" className="form-label">
                            Email
                        </label>
                        <input type="email" className="form-control" id="email" required value={get_email} onChange={e => set_email(e.target.value)} />
                    </div>
                    <div className="mb-3">
                        <label for="password" className="form-label">
                            Password
                        </label>
                        <div className="innerInputIcon">
                            <input
                                type={newPasswordType?("text"):("password")}
                                className="form-control"
                                id="password"
                                name="password"
                                placeholder=''
                                onChange={e => set_password(e.target.value)}
                            />
                            <div className="innerInputIcons">
                            {
                                newPasswordType?
                                (
                                    <span onClick={() => {setNewPasswordType(false)}} className="eyeOff"><Icon.EyeOff /></span>
                                ):(
                                    <span onClick={() => {setNewPasswordType(true)}} className="eye"><Icon.Eye /></span>
                                )
                            }
                            </div>
                        </div>
                    </div>
                    <div className="mb-3">
                        <label for="confirm_password" className="form-label">
                            Confirm Password
                        </label>
                        <div className="innerInputIcon">
                            <input
                                type={confirmPasswordType?("text"):("password")}
                                class="form-control"
                                id="email_confirmation"
                                name="email_confirmation"
                                placeholder=''
                                onChange={e => set_confirm_password(e.target.value)}
                            />
                            <div className="innerInputIcons">
                            {
                                confirmPasswordType?
                                (
                                    <span onClick={() => {setConfirmPasswordType(false)}} className="eyeOff"><Icon.EyeOff /></span>
                                ):(
                                    <span onClick={() => {setConfirmPasswordType(true)}} className="eye"><Icon.Eye /></span>
                                )
                            }
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-sm-3"></div>
            </div>
          </form>
        </Modal.Body>
        <Modal.Footer>
          <div className="row">
            <div className="col-sm-3"></div>
            <div className="col-sm-6">
              <div className="centeredBtns">
                    <Button
                    variant="btn btn-outline-primary"
                    onClick={handleEmployeeUserAddModalClose}
                    >
                        Close
                    </Button>
                    <button type="submit" className="btn btn-primary" disabled={btnLoading} onClick={saveBtn}>
                    {
                        btnLoading ?
                        (
                            <>Please Wait <span className="spinner-grow spinner-grow-sm ml-2" role="status" aria-hidden="true"></span></>
                        ) : (
                            <>Save</>
                        )
                    }
                    </button>
              </div>
            </div>
            <div className="col-sm-3"></div>
          </div>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default CompanyEditModal;
