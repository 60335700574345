import React, { useContext, useEffect, useRef, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import axiosClient from "../axios-client";
import Userimg from "../images/man-2.png";
import axios from "axios";
import {
  useTable,
  useFilters,
  useGlobalFilter,
  useSortBy,
  useAsyncDebounce,
  usePagination,
} from "react-table";
import * as Icon from "react-feather";
import { ComponentLoading } from "../Components/ComponentLoading";
import { ToastContainer, toast } from "react-toastify";
import Swal from "sweetalert2";
import { Modal, Button, Nav, Tab, Row, Col } from "react-bootstrap";
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import moment from 'moment';
import Select from 'react-select'
import { ActionMeta, OnChangeValue } from 'react-select';

import DatePicker from "react-datepicker";

import logo from '../images/cr-computer.png'

// ** Store Imports
import { useDispatch, useSelector } from 'react-redux'

//token
const accessToken = localStorage.getItem("ACCESS_TOKEN");

function CompanyChangeOptionModal({
    prop_refreshEditCompany,
    prop_companyEmployeesRequest,
    prop_employeeChangeOptionModal,
    prop_handleEmployeeModalChangeOptionClose,
    prop_employeeId
}) {
    
    const stMainUser = useSelector((state) => state.stMainUser)

    const [getComponentLoading, setComponentLoading] = useState(true);
    const [btnLoading, setBtnLoading] = useState(false)

    // Add Option Company Modal
    const [employeeChangeOptionModal, setEmployeeChangeOptionModal] = useState(false);
    const handleEmployeeChangeOptionModalClose = () => {
        setEmployeeChangeOptionModal(false)
        prop_companyEmployeesRequest()
        prop_handleEmployeeModalChangeOptionClose()
    };

    const [getLocations, setCompanySites] = useState([])

    const [getSharedDriveAccess, setSharedDriveAccess] = useState(["renu","commercial","doors","accounting","accounting2","compliance","ea","hr","marketing","payroll","photography","quickbooks","w_9","warehouse"])

    const [getSoftwares, setSoftwares] = useState(["office_360","rfms","timberline","timberscan","measure_it","pro_tech","quickbooks","quicken","nitropdf","plan_swift","xactimate","ms_project","adobe_acrobat_pro","rms_operations"])

    const [getCameraSystem, setCameraSystem] = useState(["main","warehouse","port_chester","sunrise_warehouse","blendex","all","none"])

    const [getLaptop, setLaptop] = useState(["standard","new_desk_setup","other"])

    const [getPrimaryEmailDomain, setPrimaryEmailDomain] = useState(["no_email","crcomputers.com","crcomputers2.com","crcomputers3.com"])

    const [getGroupMembership, setGroupMembership] = useState(["remove_web","admin","other"])

    const [getFormStep, setFormStep] = useState("first");
    const [getEmployeeId, setEmployeeId] = useState(null);

    // Step first
    const [get_requesting_user, set_requesting_user] = useState("");
    const [get_new_employee_name, set_new_employee_name] = useState("");
    const [get_job_title, set_job_title] = useState("");
    const [get_phone_number, set_phone_number] = useState("");
    const [get_start_date, set_start_date] = useState("");
    const [get_location, set_location] = useState("");
    const [get_other_information, set_other_information] = useState("");
    
    // Step second
    const [get_group_membership, set_group_membership] = useState("");
    const [get_shared_drive_access, set_shared_drive_access] = useState([]);
    const [get_software, set_software] = useState([]);
    const [get_camera_system, set_camera_system] = useState("");
    const [get_other_things_note, set_other_things_note] = useState("");
    
    // Step third
    const [get_laptop, set_laptop] = useState("");
    const [get_laptop_other, set_laptop_other] = useState("");
    const [get_personal_printer, set_personal_printer] = useState(null);
    const [get_desk_scanner, set_desk_scanner] = useState(null);
    const [get_desk_phone, set_desk_phone] = useState(null);
    const [get_ipad, set_ipad] = useState(null);
    const [get_other_equipment, set_other_equipment] = useState("");

    // Step fourth
    const [get_setup_email_on_cellphone, set_setup_email_on_cellphone] = useState("");
    const [get_primary_email_domain, set_primary_email_domain] = useState("");
    const [get_special_requests, set_special_requests] = useState("");

    const [get_approved_supervisor_name, set_approved_supervisor_name] = useState("");
    const [get_on_date, set_on_date] = useState("");
    const [get_approval_other_information, set_approval_other_information] = useState("");
    
    const [get_shared_drive_access_for_highlight, set_shared_drive_access_for_highlight] = useState("");
    const [get_software_for_highlight, set_software_for_highlight] = useState("");
    
    const [getStepsData, setStepsData] = useState({
        step_first: 'on',
        step_second: 'remain',
        step_third: 'remain',
        step_fourth: 'remain',
    });

    const handleFormStepBtn = (step) => {
        setFormStep(step)
    }

    const init = () => {
        if(stMainUser.stFetchCompanySites.company_sites){
            setCompanySites(stMainUser.stFetchCompanySites.company_sites);
        }
    };

    const getEmployeeData = (empId) => {
        setComponentLoading(true)
        let config = {
          method: "get",
          url: process.env.REACT_APP_API_URL + "/employees/"+empId+"/get",
          headers: {
            Accept: "application/json",
            Authorization: `Bearer ${accessToken}`,
          },
        };
    
        axios(config)
            .then((response) => {

                const emp = response.data.employee
                
                // Step first
                // if(emp.requesting_user){ set_requesting_user(emp.requesting_user) }
                // if(emp.new_employee_name){ set_new_employee_name(emp.new_employee_name) }
                // if(emp.job_title){ set_job_title(emp.job_title) }
                // if(emp.phone_number){ set_phone_number(emp.phone_number) }
                // if(emp.start_date){
                //     const d = moment(emp.start_date).format('MM/D/YYYY');
                //     set_start_date(new Date(d))
                // }
                // if(emp.location){ set_location(emp.location) }
                // if(emp.other_information){ set_other_information(emp.other_information) }

                // // Step second
                // if(emp.group_membership){ set_group_membership(JSON.parse(emp.group_membership)) }



                // if(emp.shared_drive_access){ set_shared_drive_access(JSON.parse(emp.shared_drive_access)) }
                // if(emp.software){ set_software(JSON.parse(emp.software)) }
                if(emp.shared_drive_access){ set_shared_drive_access_for_highlight(JSON.parse(emp.shared_drive_access)) }
                if(emp.software){ set_software_for_highlight(JSON.parse(emp.software)) }



                // if(emp.camera_system){ set_camera_system(JSON.parse(emp.camera_system)) }
                // if(emp.other_things_note){ set_other_things_note(emp.other_things_note) }
                
                // // Step third
                // if(emp.laptop){ set_laptop(JSON.parse(emp.laptop)) }
                // if(emp.laptop_other){ set_laptop_other(emp.laptop_other) }
                // if(emp.personal_printer){ set_personal_printer(emp.personal_printer) }
                // if(emp.desk_scanner){ set_desk_scanner(emp.desk_scanner) }
                // if(emp.desk_phone){ set_desk_phone(emp.desk_phone) }
                // if(emp.ipad){ set_ipad(emp.ipad) }
                // if(emp.other_equipment){ set_other_equipment(emp.other_equipment) }

                // // // Step fourth
                // if(emp.setup_email_on_cellphone){ set_setup_email_on_cellphone(emp.setup_email_on_cellphone) }
                // if(emp.primary_email_domain){ set_primary_email_domain(emp.primary_email_domain) }
                // if(emp.special_requests){ set_special_requests(emp.special_requests) }

                // if(emp.approved_supervisor_name){ set_approved_supervisor_name(emp.approved_supervisor_name) }
                // if(emp.on_date){
                //     const d = moment(emp.on_date).format('MM/D/YYYY');
                //     set_on_date(new Date(d))
                // }
                // if(emp.approval_other_information){ set_approval_other_information(emp.approval_other_information) }

                setComponentLoading(false);

            })
            .catch((error) => {
                setComponentLoading(false);
            });
    };

    useEffect(() => {

        init()

        if(prop_employeeId){
            // console.log('prop_employeeId=',prop_employeeId)
            setEmployeeId(prop_employeeId)
            getEmployeeData(prop_employeeId)
        }

        if(prop_employeeChangeOptionModal){
            setEmployeeChangeOptionModal(prop_employeeChangeOptionModal)
        }

        // console.log('getFormStep=>>>',getFormStep)
        
    }, [prop_employeeChangeOptionModal, prop_employeeId, stMainUser]);

    const handleGroupMembershipChange = (value) => {
        if (get_group_membership.includes(value)) {
            set_group_membership(get_group_membership.filter(item => item !== value));
        } else {
            set_group_membership([...get_group_membership, value]);
        }
    }

    const handleSharedDriveAccessChange = (value) => {
        if (get_shared_drive_access.includes(value)) {
            set_shared_drive_access(get_shared_drive_access.filter(item => item !== value));
        } else {
            set_shared_drive_access([...get_shared_drive_access, value]);
        }
    }

    const handleSoftwareChange = (value) => {
        if (get_software.includes(value)) {
            set_software(get_software.filter(item => item !== value));
        } else {
            set_software([...get_software, value]);
        }
    }

    const handleCameraSystemChange = (value) => {
        if (get_camera_system.includes(value)) {
            set_camera_system(get_camera_system.filter(item => item !== value));
        } else {
            set_camera_system([...get_camera_system, value]);
        }
    }

    const handleLaptopChange = (value) => {
        if (get_laptop.includes(value)) {
            set_laptop(get_laptop.filter(item => item !== value));
        } else {
            set_laptop([...get_laptop, value]);
        }
    }

    const handleDateSelectStartDate = (date) => {
        console.log('handleDateSelectStartDate=',date)
        set_start_date(date)
    }

    const handleDateSelectOnDate = (date) => {
        // console.log('handleDateSelectOnDate=',date)
        set_on_date(date)
    }

    const saveBtn = () => {
        setBtnLoading(true)
        let payload = {

            // step: getFormStep,
            employee_id: getEmployeeId,

            // Step first
            // requesting_user: get_requesting_user,
            // new_employee_name: get_new_employee_name,
            // job_title: get_job_title,
            // phone_number: get_phone_number,
            // start_date: get_start_date,
            // location: get_location,
            // other_information: get_other_information,
            
            // // Step second
            // group_membership: get_group_membership,
            shared_drive_access: get_shared_drive_access,
            software: get_software,
            // camera_system: get_camera_system,
            // other_things_note: get_other_things_note,

            // // Step third
            // laptop: get_laptop,
            // laptop_other: get_laptop_other,
            // personal_printer: get_personal_printer,
            // desk_scanner: get_desk_scanner,
            // desk_phone: get_desk_phone,
            // ipad: get_ipad,
            // other_equipment: get_other_equipment,

            // // Step fourth
            // setup_email_on_cellphone: get_setup_email_on_cellphone,
            // primary_email_domain: get_primary_email_domain,
            // special_requests: get_special_requests,
            // approved_supervisor_name: get_approved_supervisor_name,
            // on_date: get_on_date,
            // approval_other_information: get_approval_other_information,

        }
        axiosClient.put('/employees/'+getEmployeeId+'/remove', payload)
            .then((res) => {
                setBtnLoading(false)
                setTimeout(() => {
                    toast.success(res.data.message, {
                        position: "bottom-right",
                        autoClose: 1500,
                        theme: "colored",
                    });
                    prop_refreshEditCompany()
                    prop_companyEmployeesRequest()
                    handleEmployeeChangeOptionModalClose()
                }, 500);
                console.log('getFormStep2=',getFormStep)
            })
            .catch(err => {
                setBtnLoading(false)
                var firstErrorMessage = '';
                if (err.response.data.errors && Object.keys(err.response.data.errors).length != 0) {
                var errors = err.response.data.errors
                const errorMessages = Object.values(errors); // Get an array of all error messages
                firstErrorMessage = errorMessages.shift(); // Get the first error message
                toast.error(firstErrorMessage[0], {
                    position: "bottom-right",
                    autoClose: 1500,
                    theme: "colored",
                });
                } else {
                // console.log('firstErrorMessage',firstErrorMessage[0])
                toast.error(err.response.data.message, {
                    position: "bottom-right",
                    autoClose: 1500,
                    theme: "colored",
                });
                }
                // console.log(err);
            });

    }

    useEffect(() => {
        console.log('getFormStep=>>>',getFormStep)
    }, [getFormStep]);

  return (
    <>
    <Modal
        className="customModal" // hide-btn-close
        backdrop="static"
        centered
        size="xl"
        show={employeeChangeOptionModal}
        onHide={handleEmployeeChangeOptionModalClose}
      >
        <Modal.Header closeButton>
          <Modal.Title>
            <img className="modalLogo" src={logo} />

            <div>
                <ul className="stepsHeading">
                    <li>
                        <span className="heading">Remove devices and software</span>
                    </li>
                </ul>
            </div>

          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form>
            {getComponentLoading ? (
                <div className="card loadingCard">
                <div className="card-body">
                    <ComponentLoading></ComponentLoading>
                </div>
                </div>
            ) : (
                <>
                {/* {getEmployeeId} */}
                <div className="row">
                    <div className="col-sm-3"></div>
                    <div className="col-sm-6">
                        <p><strong>Note:</strong> Please check the devices/softwares that you want to remove.</p>
                        {
                            <>
                            <div className="mb-3">
                                <label for="get_shared_drive_access" className="form-label">
                                Shared Drive Access: Please check only those that apply<span className="text-danger">*</span>
                                </label>
                                {getSharedDriveAccess.map(item => (
                                <div className="form-check" key={item}>
                                    <input
                                        className="form-check-input"
                                        type="checkbox"
                                        id={'get_shared_drive_access-'+item}
                                        name="item"
                                        value={item}
                                        checked={get_shared_drive_access.includes(item)}
                                        onChange={e => handleSharedDriveAccessChange(e.target.value)}
                                    />
                                    <label className="form-check-label" htmlFor={'get_shared_drive_access-'+item}>
                                        <span className={`${get_shared_drive_access_for_highlight.includes(item)?'text-bold-700':''}`}>
                                            {item}
                                        </span>
                                    </label>
                                </div>
                                ))}
                            </div>
                            <div className="mb-3">
                                <label for="get_software" className="form-label">
                                Software
                                </label>
                                {getSoftwares.map(item => (
                                <div className="form-check" key={item}>
                                    <input
                                        className="form-check-input"
                                        type="checkbox"
                                        id={'get_software-'+item}
                                        name="item"
                                        value={item}
                                        checked={get_software.includes(item)}
                                        onChange={e => handleSoftwareChange(e.target.value)}
                                    />
                                    <label className="form-check-label" htmlFor={'get_software-'+item}>
                                        <span className={`${get_software_for_highlight.includes(item)?'text-bold-700':''}`}>
                                            {item}
                                        </span>     
                                    </label>
                                </div>
                                ))}
                            </div>
                            </>
                        }
                    </div>
                    <div className="col-sm-3"></div>
                </div>
                </>
            )}
          </form>
        </Modal.Body>
        <Modal.Footer>
          <div className="row">
            <div className="col-sm-3"></div>
            <div className="col-sm-6">
              <div className="centeredBtns">
                {
                    <>
                    <Button
                    variant="btn btn-outline-primary"
                    onClick={() => {handleEmployeeChangeOptionModalClose()}}
                    >
                        Close
                    </Button>
                    <button type="submit" className="btn btn-primary" disabled={btnLoading} onClick={saveBtn}>
                    {
                        btnLoading ?
                        (
                            <>Please Wait <span className="spinner-grow spinner-grow-sm ml-2" role="status" aria-hidden="true"></span></>
                        ) : (
                            <>Save</>
                        )
                    }
                    </button>
                    </>
                }
              </div>
            </div>
            <div className="col-sm-3"></div>
          </div>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default CompanyChangeOptionModal;
