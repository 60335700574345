import React, { useContext, useEffect, useRef, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import axiosClient from "../axios-client";
import Userimg from "../images/man-2.png";
import axios from "axios";
import {
  useTable,
  useFilters,
  useGlobalFilter,
  useSortBy,
  useAsyncDebounce,
  usePagination,
} from "react-table";
import * as Icon from "react-feather";
import { ComponentLoading } from "../Components/ComponentLoading";
import { ToastContainer, toast } from "react-toastify";
import Swal from "sweetalert2";
import { Modal, Button, Nav, Tab, Row, Col } from "react-bootstrap";
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import moment from 'moment';
import Select from 'react-select'
import { ActionMeta, OnChangeValue } from 'react-select';
import { useStateContext } from "../Context/ContextProvider";

// Import Components
import CompanyBasicDetails from './CompanyBasicDetails';
import CompanyPowerUsers from './CompanyPowerUsers';
import CompanyEmployeesUsers from './CompanyEmployeesUsers';
import CompanyProducts from './CompanyProducts';
import CompanySites from './CompanySites';

// ** Store Imports
import { useDispatch, useSelector } from 'react-redux'

// ** Actions Imports
import {
    stGetIndustriesData, 
    stActionMode, 
    stGetResponse, 
    stGetCompanyData, 
    stFetchCompanySites,
    stCompanyId,
    stFetchCompanyEmployees,
    stFetchCompanyProducts
} from '../store/apps/mainUser'

//token
const accessToken = localStorage.getItem("ACCESS_TOKEN");

const CompanyDetailsPage = (props) => {

  // ** Hooks
  const dispatch = useDispatch();
    
  const stMainUser = useSelector((state) => state.stMainUser)

  const { user, role, notification } = useStateContext();

  let { companyId, userType, companySiteId } = useParams();

  const [getComponentLoading, setComponentLoading] = useState(true);

  const [btnLoading, setBtnLoading] = useState(false)
  const [getCompanySites, setCompanySites] = useState([]);

  // const [getCompanyId, setCompanyId] = useState(null);
  // const [getCompanyName, setCompanyName] = useState("");
  // const [getCompanyMainAddress, setCompanyMainAddress] = useState("");
  // const [getCompanyMainPhone, setCompanyMainPhone] = useState("");
  // const [getCompanyIndustry, setCompanyIndustry] = useState(null);
  // const [getCompanyOtherSiteAddresses, setCompanyOtherSiteAddresses] = useState([]);
  // const [getCompanyOtherSitePhoneNumbers, setCompanyOtherSitePhoneNumbers] = useState([]);

  const [getCompanyDetails, setCompanyDetails] = useState({});
  const [getActiveTab, setActiveTab] = useState('');
    
  const store = useSelector((state) => state.internalOrganizations)

  // const editCompany = async () => {
  //   setComponentLoading(true);
  //   let config = {
  //       method: "get",
  //       url: process.env.REACT_APP_API_URL + "/companies/"+companyId,
  //       headers: {
  //         Accept: "application/json",
  //         Authorization: `Bearer ${accessToken}`,
  //       },
  //     };
  
  //     axios(config)
  //     .then((response) => {
  //       const companyDetails = {}
  //       // setCompanyId(response.data.company.id);
  //       // setCompanyName(response.data.company.name)
  //       // setCompanyMainAddress(response.data.company.main_address)
  //       // setCompanyMainPhone(response.data.company.main_phone)
  //       companyDetails.getCompanyId = response.data.company.id
  //       companyDetails.getCompanyName = response.data.company.name
  //       companyDetails.getCompanyMainAddress = response.data.company.main_address
  //       companyDetails.getCompanyMainPhone = response.data.company.main_phone
  //       if(response.data.company.industry){
  //           // setCompanyIndustry(response.data.company.industry)
  //           companyDetails.getCompanyIndustry = response.data.company.industry
  //       }
  //       if(response.data.company.company_other_site_addresses_data){
  //           // setCompanyOtherSiteAddresses(response.data.company.company_other_site_addresses_data)
  //           companyDetails.getCompanyOtherSiteAddresses = response.data.company.company_other_site_addresses_data
  //         } else {
  //           // setCompanyOtherSiteAddresses([])
  //           companyDetails.getCompanyOtherSiteAddresses = []
  //       }
  //       if(response.data.company.company_other_site_phone_numbers_data){
  //           // setCompanyOtherSitePhoneNumbers(response.data.company.company_other_site_phone_numbers_data)
  //           companyDetails.getCompanyOtherSitePhoneNumbers = response.data.company.company_other_site_phone_numbers_data
  //         } else {
  //           // setCompanyOtherSitePhoneNumbers([])
  //           companyDetails.getCompanyOtherSitePhoneNumbers = []
  //       }
  //       companyDetails.getPowerUsersCount = response.data.company.power_users_count
  //       companyDetails.getEmployeeUsersCount = response.data.company.employee_users_count
  //       companyDetails.getCompanySitesCount = response.data.company.company_sites_count
  //       setCompanyDetails(companyDetails)
  //       setComponentLoading(false);
  //     })
  //     .catch((error) => {
  //       setComponentLoading(false);
  //     });
  // };

  const editCompany = async () => {
    // setComponentLoading(true);
    const companyDetails = {}
    companyDetails.getCompanyId = stMainUser.stGetCompanyData.company.id
    companyDetails.getCompanyName = stMainUser.stGetCompanyData.company.name
    companyDetails.getCompanyMainAddress = stMainUser.stGetCompanyData.company.main_address
    companyDetails.getCompanyMainPhone = stMainUser.stGetCompanyData.company.main_phone
    if(stMainUser.stGetCompanyData.company.industry){
        companyDetails.getCompanyIndustry = stMainUser.stGetCompanyData.company.industry
    }
    if(stMainUser.stGetCompanyData.company.company_other_site_addresses_data){
        companyDetails.getCompanyOtherSiteAddresses = stMainUser.stGetCompanyData.company.company_other_site_addresses_data
    } else {
        companyDetails.getCompanyOtherSiteAddresses = []
    }
    if(stMainUser.stGetCompanyData.company.company_other_site_phone_numbers_data){
        companyDetails.getCompanyOtherSitePhoneNumbers = stMainUser.stGetCompanyData.company.company_other_site_phone_numbers_data
    } else {
        companyDetails.getCompanyOtherSitePhoneNumbers = []
    }
    // console.log('stMainUser.stGetCompanyData=',stMainUser.stGetCompanyData)
    companyDetails.getPowerUsersCount = stMainUser.stGetCompanyData.company.power_users_count
    companyDetails.getEmployeeUsersCount = stMainUser.stGetCompanyData.company.employee_users_count
    companyDetails.getCompanySitesCount = stMainUser.stGetCompanyData.company.company_sites_count
    companyDetails.getCompanyProductsCount = stMainUser.stGetCompanyData.company.company_products_count
    setCompanyDetails(companyDetails)
    setComponentLoading(false);
  }

  const refreshEditCompanyHandle = () => {
    if(companyId){
      // dispatch(stGetIndustriesData({companyId}));
      dispatch(stGetCompanyData({companyId}));
      dispatch(stFetchCompanySites({companyId}));
      dispatch(stFetchCompanyEmployees({companyId}));
      dispatch(stFetchCompanyProducts({companyId}));
      dispatch(stCompanyId({companyId}));
    }
  }

  const companySitesRequest = () => {
    setComponentLoading(true);
    if(stMainUser.stFetchCompanySites.company_sites){
      setCompanySites(stMainUser.stFetchCompanySites.company_sites);
      setComponentLoading(false);
    }
  };

  useEffect(() => {
    if(companyId){
      editCompany()
      companySitesRequest()
    }
    if(companyId && userType){
      setActiveTab(userType)
    } else {
      setActiveTab('basic-details')
    }
  }, [companyId, userType, stMainUser]);

  return (
    <>
      {
        userType != 'employees'?
        (
            <div className="pageTitle">
                <div className="">
                    <h1 className="page-heading">{getCompanyDetails.getCompanyName}</h1>
                    <nav aria-label="breadcrumb">
                        <ol className="breadcrumb">
                        <li className="breadcrumb-item"><Link to={"/"}>Home</Link></li>
                        <li className="breadcrumb-item active" aria-current="page"><Link to={"/companies"}>Companies</Link></li>
                        <li className="breadcrumb-item active" aria-current="page">{getCompanyDetails.getCompanyName}</li>
                        </ol>
                    </nav>
                </div>
                <div></div>
            </div>
        ):(
            <div className="pageTitle">
                <div className="">
                    <nav aria-label="breadcrumb">
                        <ol className="breadcrumb">
                        <li className="breadcrumb-item"><Link to={"/"}>Home</Link></li>
                        <li className="breadcrumb-item active" aria-current="page"><Link to={"/companies"}>Companies</Link></li>
                        <li className="breadcrumb-item active" aria-current="page">{getCompanyDetails.getCompanyName}</li>
                        </ol>
                    </nav>
                </div>
                <div></div>
            </div>
        )
      }

      {/* Page Content */}
      {getComponentLoading ? (
        <div className="card loadingCard">
          <div className="card-body">
            <ComponentLoading></ComponentLoading>
          </div>
        </div>
      ) : (
        <>
        {/* <div className="card mb-3">
          <div className="card-body">
            <div className="detailCard">
              <div>
                <h4 className="mb-2">{getCompanyDetails.getCompanyName}</h4>
                <h6 className="mb-0"><Icon.Phone width={16} /> {getCompanyDetails.getCompanyMainPhone}</h6>
                <p className="mb-0"><Icon.MapPin width={16} /> {getCompanyDetails.getCompanyMainAddress}</p>
              </div>
              <div className="countBoxes">
                {
                  role == 'admin' && (
                    <div className="countBox">
                      <span className="num">{getCompanyDetails.getPowerUsersCount}</span>
                      <span className="txt">Power Users</span>
                    </div>
                  )
                }
                <div className="countBox">
                  <span className="num">{getCompanyDetails.getEmployeeUsersCount}</span>
                  <span className="txt">Employees</span>
                </div>
              </div>
            </div>
          </div>
          <div className="customTabNav">
              <Link className={`customBtn ${getActiveTab === 'basic-details' ? 'active' : ''}`} to={'/companies/'+companyId}>Basic Details</Link>
              {
                role == 'admin' && (
                  <Link className={`customBtn ${getActiveTab === 'power-users' ? 'active' : ''}`} to={'/companies/'+companyId+'/power-users'}>Power Users</Link>
                )
              }
              <Link className={`customBtn ${getActiveTab === 'sites' ? 'active' : ''}`} to={'/companies/'+companyId+'/sites'}>Company Sites</Link>
              <Link className={`customBtn ${getActiveTab === 'employees' ? 'active' : ''}`} to={'/companies/'+companyId+'/employees'}>Employees</Link>
          </div>
        </div> */}

        {/* {JSON.stringify(store.stGetIndustriesData)} */}

        {/* <div className="countBoxes mb-3">
          {
            role == 'admin' && (
              <div className="countBox">
                <span className="num">{getCompanyDetails.getPowerUsersCount}</span>
                <span className="txt">Power Users</span>
              </div>
            )
          }
          <div className="countBox">
            <span className="num">{getCompanyDetails.getEmployeeUsersCount}</span>
            <span className="txt">Employees</span>
          </div>
          <div className="countBox">
            <span className="num">{getCompanyDetails.getCompanySitesCount}</span>
            <span className="txt">Sites</span>
          </div>
        </div> */}

{
            userType == 'employees'?
            (
                <>
                <div className="card mb-3">
                    <div className="sitesTabNav">
                        <span>Sites:</span>
                        <Link className={`customBtn ${userType == 'employees' && !companySiteId ? "active" : ""}`} to={"/companies/"+companyId+"/employees/"}>
                            All
                        </Link>
                        {
                            getCompanySites.length > 0 && (
                                getCompanySites.map((item) => (
                                    <>
                                        {
                                            <Link className={`customBtn ${item.id == companySiteId ? "active" : ""}`} to={"/companies/"+companyId+"/employees/"+item.id}>
                                                {item.name}
                                            </Link>
                                        }
                                    </>
                                ))
                            )
                        }
                    </div>
                </div>
                <div className="card mb-3">
                    <div className="card-body">
                        <div className="detailCard">
                            <div>
                                <h4 className="mb-2">{getCompanyDetails.getCompanyName}</h4>
                                <h6 className="mb-0"><Icon.Phone width={16} /> {getCompanyDetails.getCompanyMainPhone}</h6>
                                <p className="mb-0"><Icon.MapPin width={16} /> {getCompanyDetails.getCompanyMainAddress}</p>
                            </div>
                            <div className="countBoxes">
                                {
                                    role == 'admin' && (
                                        <div className="countBox">
                                        <span className="num">{getCompanyDetails.getPowerUsersCount}</span>
                                        <span className="txt">Power Users</span>
                                        </div>
                                    )
                                }
                                <div className="countBox">
                                    <span className="num">{getCompanyDetails.getEmployeeUsersCount}</span>
                                    <span className="txt">Employees</span>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* <div className="customTabNav">
                        <Link className={`customBtn ${getActiveTab === 'basic-details' ? 'active' : ''}`} to={'/companies/'+companyId}>Basic Details</Link>
                        {
                            role == 'admin' && (
                            <Link className={`customBtn ${getActiveTab === 'power-users' ? 'active' : ''}`} to={'/companies/'+companyId+'/power-users'}>Power Users</Link>
                            )
                        }
                        <Link className={`customBtn ${getActiveTab === 'sites' ? 'active' : ''}`} to={'/companies/'+companyId+'/sites'}>Company Sites</Link>
                        <Link className={`customBtn ${getActiveTab === 'employees' ? 'active' : ''}`} to={'/companies/'+companyId+'/employees'}>Employees</Link>
                    </div> */}
                </div>
                </>
            ):(
              <>
              <div className="countBoxes mb-3">
                <div className="countBox">
                  <span className="num">{getCompanyDetails.getEmployeeUsersCount}</span>
                  <span className="txt">Employees</span>
                </div>
                <div className="countBox">
                  <span className="num">{getCompanyDetails.getCompanySitesCount}</span>
                  <span className="txt">Sites</span>
                </div>
                <div className="countBox">
                  <span className="num">{getCompanyDetails.getCompanyProductsCount}</span>
                  <span className="txt">Products \ Services</span>
                </div>
                {
                  role == 'admin' && (
                    <div className="countBox">
                        <span className="num">{getCompanyDetails.getPowerUsersCount}</span>
                        <span className="txt">Power Users</span>
                    </div>
                  )
                }
              </div>
              </>
            )
        }

        {
          companyId && userType == 'power-users' ? (
            <div className="card customCard">
              <CompanyPowerUsers refreshEditCompany={refreshEditCompanyHandle}></CompanyPowerUsers>
            </div>
          ):companyId && userType == 'sites' ?(
            <div className="card customCard">
            <CompanySites refreshEditCompany={refreshEditCompanyHandle}></CompanySites>
            </div>
          ):companyId && userType == 'employees' ?(
            <div className="card customCard">
              <CompanyEmployeesUsers refreshEditCompany={refreshEditCompanyHandle}></CompanyEmployeesUsers>
            </div>
          ):companyId && userType == 'products' ?(
            <div className="card customCard">
              <CompanyProducts refreshEditCompany={refreshEditCompanyHandle}></CompanyProducts>
            </div>
          ):(
            <div className="card customCard">
            <CompanySites refreshEditCompany={refreshEditCompanyHandle}></CompanySites>
              {/* <CompanyBasicDetails companyDetails={getCompanyDetails}></CompanyBasicDetails> */}
            </div>
          )
        }

        </>
      )}
    </>
  );
};

export default CompanyDetailsPage;
