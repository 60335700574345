import React, { useState, useEffect } from 'react';

import { NavLink, Link, useNavigate, useParams } from 'react-router-dom';
import { Navigate } from "react-router-dom";

import axiosClient from '../axios-client';
import { useStateContext } from "../Context/ContextProvider";
import * as Icon from 'react-feather';
import axios from "axios";
import { ComponentLoading } from "../Components/ComponentLoading";

import PalentiCarLogo from '../images/cr-computer.png'

// Import Components
import CompaniesEmploymentEditModal from '../PageComponents/CompaniesEmploymentEditModal';

// ** Store Imports
import { useDispatch, useSelector } from 'react-redux'

// ** Types Imports
import { RootState, AppDispatch } from "../store"

// ** Actions Imports
import {
    stGetIndustriesData, 
    stActionMode, 
    stGetResponse, 
    stGetCompanyData, 
    stFetchCompanySites,
    stCompanyId
} from '../store/apps/powerUser'

//token
const accessToken = localStorage.getItem("ACCESS_TOKEN");

const SidebarMenu = (props) => {

    // ** Hooks
    const dispatch = useDispatch();
    
    const storePowerUser = useSelector((state) => state.stPowerUser)

    let { companyId, userType } = useParams();

    // Access the getUserRole function from props
    const { getUserRole, getIsLoading } = props;

    //up state in the default function it is also added
    const { user, token, role, setUser, setToken, setRole, notification, company } = useStateContext();

    const [isMenuFixed, setIsMenuFixed] = useState(true);
    const [get_user_id, set_user_id] = useState(null);
    const [get_company_id, set_company_id] = useState(null);
    const [getComponentLoading, setComponentLoading] = useState(true);
    const [getCompanySites, setCompanySites] = useState([]);

    // Employee Modal
    const [employeeEditModal, setEmployeeEditModal] = useState(false);
    const handleEmployeeModalEditClose = () => setEmployeeEditModal(false);

    const handleResize = () => {
        const bodyElement = document.body;
        if (window.innerWidth < 1024) {
            setIsMenuFixed(false);
            bodyElement.classList.remove('layout-menu-fixed');
            bodyElement.classList.add('device');
        } else {
            setIsMenuFixed(true);
            bodyElement.classList.add('layout-menu-fixed');
            bodyElement.classList.remove('device');
        }
    }
    const resize = () => {
    
        window.addEventListener('resize', handleResize);
    
        // Remove event listener on component unmount
        return () => window.removeEventListener('resize', handleResize);
    }

    const toggleMenu = () => {
        setIsMenuFixed(!isMenuFixed);
        // document.body.classList.remove('layout-menu-fixed');
        const bodyElement = document.body;
        if (isMenuFixed) {
            bodyElement.classList.remove('layout-menu-fixed');
        } else {
            bodyElement.classList.add('layout-menu-fixed');
        }
    }

    const closeMenu = () => {
        setIsMenuFixed(false);
    }

    const onLogout = (e) => {
        e.preventDefault()

        axiosClient.post('/logout')
            .then(() => {
                setUser({})
                setToken(null)
            })
    }

    const companySitesRequest = (dataId) => {
        // setComponentLoading(true);
        // let config = {
        //     method: "get",
        //     url: process.env.REACT_APP_API_URL + "/company-sites/"+dataId,
        //     headers: {
        //         Accept: "application/json",
        //         Authorization: `Bearer ${accessToken}`,
        //     },
        // }
  
        // axios(config)
        //     .then((response) => {
        //         setCompanySites(response.data.company_sites);
        //         setComponentLoading(false);
        //     })
        //     .catch((error) => {
        //         setComponentLoading(false);
        //     });
        
        if(storePowerUser.stFetchCompanySites.company_sites){
            setCompanySites(storePowerUser.stFetchCompanySites.company_sites);
            setComponentLoading(false);
        }

    };

    const clickCompanySiteBtn = () => {
        // Navigate('company')
        // alert('clickCompanySiteBtn')
        if(userType == 'employees'){
            const link = document.querySelector('.dropdown-toggle');
            link.classList.remove('active');
        } else {
            // Get the innerDropdown element
            const innerDropdown = document.querySelector('.innerDropdown');
    
            if(innerDropdown && innerDropdown.classList.contains('show')){
                const link = document.querySelector('.dropdown-toggle');
                // Add a custom class to the link element
                if (link) {
                    link.classList.add('active');
                } else {
                    link.classList.remove('active');
                }
            }
        }
        
        // document.addEventListener('DOMContentLoaded', function() {
          
        //     // Check if the innerDropdown has the "show" class
        //     if (innerDropdown && innerDropdown.classList.contains('show')) {
        //         // Get the link element
        //         const link = document.querySelector('.dropdown-toggle');
            
        //         // Add a custom class to the link element
        //         if (link) {
        //             link.classList.add('custom-bg-color');
        //         }
        //     }
        // });
          
    }


    useEffect(() => {
        clickCompanySiteBtn()
    },[clickCompanySiteBtn])


    // useEffect(() => {
    //     if(companyId){
    //         set_company_id(companyId)
    //         companySitesRequest(companyId)
    //     } else {
    //         set_company_id(null)
    //         setCompanySites([])
    //     }
    // },[companyId])

    useEffect(() => {
        if(role == 'power_user'){
            companySitesRequest()
        } else {
            set_company_id(companyId)
        }
      }, [role,storePowerUser,companyId]);

    useEffect(() => {
        console.log('getUserRole====111getRequestLoading',getUserRole,getIsLoading)
        window.addEventListener('load', handleResize);
        resize()
    }, [getUserRole,getIsLoading])

    useEffect(() => {
        console.log('user=',user)
        if(user){
            set_user_id(user.id)
        }
    }, [get_user_id])

    useEffect(() => {
        if(Object.keys(user).length > 0){
            // dispatch(stGetIndustriesData({user}));
            dispatch(stGetCompanyData({user}));
            dispatch(stFetchCompanySites({user}));
            dispatch(stCompanyId({user}));
        }
    }, [dispatch, user])


    const companyEmployeesRequest = () => {}
    const handleEmployeeEditModalShow = () => {
      setEmployeeEditModal(true)
    //   setUserId(null);
    };

    return (
        <>
        <div className={`layout-menu ${isMenuFixed ? 'open' : ''}`}>
            <NavLink to="/" className="sidebarLogo">
                    <img src={PalentiCarLogo} alt="Logo" className="logo" />
            </NavLink>
            <button className="menu-toggle" onClick={toggleMenu}>
                <i className={`fa ${isMenuFixed ? 'fa-arrow-left' : 'fa-arrow-right'}`}></i>
            </button>
            {/* {JSON.stringify(getCompanySites)} */}

            {/* {getComponentLoading ? (
                <div className="card loadingCard">
                    <div className="card-body">
                        <ComponentLoading></ComponentLoading>
                    </div>
                </div>
            ) : ( */}
            {/* {JSON.stringify(store.stGetIndustriesData)} */}
            {/* <>
            {
                getCompanySites.map((item) => (
                    <>
                        {item.name}
                    </>
                ))
            }
            </> */}
            {/* )} */}

            {/* https://dev.to/codeply/bootstrap-5-sidebar-examples-38pb */}
            {/* 
            <div class="col-auto col-md-3 col-xl-2 px-sm-2 px-0 bg-dark">
                <div class="d-flex flex-column align-items-center align-items-sm-start px-3 pt-2 text-white min-vh-100">
                    <ul class="nav nav-pills flex-column mb-sm-auto mb-0 align-items-center align-items-sm-start" id="menu">
                        <li>
                            <a href="#submenu2" data-bs-toggle="collapse" class="nav-link px-0 align-middle ">
                                <i class="fs-4 bi-bootstrap"></i> <span class="ms-1 d-none d-sm-inline">Bootstrap</span></a>
                            <ul class="collapse nav flex-column ms-1" id="submenu2" data-bs-parent="#menu">
                                <li class="w-100">
                                    <a href="#" class="nav-link px-0"> <span class="d-none d-sm-inline">Item</span> 1</a>
                                </li>
                                <li>
                                    <a href="#" class="nav-link px-0"> <span class="d-none d-sm-inline">Item</span> 2</a>
                                </li>
                            </ul>
                        </li>
                        <li>
                            <a href="#" class="nav-link px-0 align-middle">
                                <i class="fs-4 bi-people"></i> <span class="ms-1 d-none d-sm-inline">Customers</span> </a>
                        </li>
                    </ul>
                </div>
            </div>
            */}

            <ul class="cSidebarMenu menu-items" id="menu">

                {
                    getIsLoading != true?
                    (
                        <>

                        {role == 'admin' ?
                            (
                                <>
                                {/* <ul className="menu-items"> */}
                                    <li className="menu-item">
                                        <NavLink to="/">
                                            <Icon.Home /> Dashboard
                                        </NavLink>
                                    </li>
                                {/* </ul> */}
                                {
                                    companyId ? (
                                        <>
                                        <li className="menu-item">
                                            <a onClick={() => {clickCompanySiteBtn()}} href="#submenu1" data-bs-toggle="collapse" className={`dropdown-toggle ${userType != 'employees' ? "" : ""}`}>
                                                <Icon.Users />
                                                <span class="ms-1 d-none d-sm-inline">Company Sites</span>
                                            </a>
                                            <ul className={`innerDropdown collapse ${userType != 'employees' ? "show" : ""}`} id="submenu1" data-bs-parent="#menu">
                                            {
                                                getCompanySites.length > 0 && (
                                                    getCompanySites.map((item) => (
                                                        <>
                                                            <li className="menu-item">
                                                                {
                                                                    item.name == 'Main' && userType != 'employees'?
                                                                    (
                                                                        <NavLink to="/company" className={'active'}>
                                                                            <Icon.Minus /> {item.name}
                                                                        </NavLink>
                                                                    ):(
                                                                        <NavLink to="/">
                                                                            <Icon.Minus /> {item.name}
                                                                        </NavLink>
                                                                    )
                                                                }
                                                            </li>
                                                        </>
                                                    ))
                                                )
                                            }
                                            </ul>
                                        </li>
                                        <li className="menu-item">
                                            <NavLink to={"/companies/"+get_company_id+'/employees'}>
                                                <Icon.Users /> Employees
                                            </NavLink>
                                        </li>
                                        </>
                                    ):(
                                        <li className="menu-item">
                                            <NavLink to="/companies">
                                                <Icon.Users /> Companies
                                            </NavLink>
                                        </li>
                                    )
                                }
                                </>
                            ):('')
                        }

                            
                        {role == 'power_user' && (
                            // {
                                // companyId && (

                                    <>
                                        <li className="menu-item">
                                            <a onClick={() => {clickCompanySiteBtn()}} href="#submenu1" data-bs-toggle="collapse" className={`dropdown-toggle ${userType != 'employees' ? "" : ""}`}>
                                                <Icon.Users />
                                                <span class="ms-1 d-none d-sm-inline">Company Sites</span>
                                            </a>
                                            <ul className={`innerDropdown collapse ${userType != 'employees' ? "show" : ""}`} id="submenu1" data-bs-parent="#menu">
                                            {
                                                getCompanySites.length > 0 && (
                                                    getCompanySites.map((item) => (
                                                        <>
                                                            <li className="menu-item">
                                                                {
                                                                    item.name == 'Main' && userType != 'employees'?
                                                                    (
                                                                        <NavLink to="/company" className={'active'}>
                                                                            <Icon.Minus /> {item.name}
                                                                        </NavLink>
                                                                    ):(
                                                                        <NavLink to="/">
                                                                            <Icon.Minus /> {item.name}
                                                                        </NavLink>
                                                                    )
                                                                }
                                                            </li>
                                                        </>
                                                    ))
                                                )
                                            }
                                            </ul>
                                        </li>
                                        <li className="menu-item">
                                            <NavLink to={"/company/employees"}>
                                                <Icon.Users /> Employees
                                            </NavLink>
                                        </li>

                                        {/* <ul className="menu-items">
                                            <li className="menu-item">
                                                <NavLink to="/companies">
                                                    <Icon.Users /> Companies
                                                </NavLink>
                                            </li>
                                            <li className="menu-item mt-5" onClick={onLogout} >
                                                <NavLink to="/login">
                                                    <Icon.Power /> Logout
                                                </NavLink>
                                            </li>
                                        </ul> */}
                                    </>
                                // )
                            // }
                        )}

                        {role == 'employee' ?
                            (
                                <>
                                {/* <ul className="menu-items"> */}
                                    <li className="menu-item">
                                        <NavLink to="/">
                                            <Icon.Home /> {user.company_name}
                                        </NavLink>
                                    </li>
                                    {/* <li className="menu-item">
                                        <NavLink onClick={handleEmployeeEditModalShow}>
                                            <Icon.User /> Employment
                                        </NavLink>
                                    </li> */}
                                {/* </ul> */}
                                </>
                            ):('')
                        }

                        </>
                    ):('')
                }
                
                <li className="menu-item mt-5" onClick={onLogout} >
                    <NavLink to="/login">
                        <Icon.Power /> Logout
                    </NavLink>
                </li>

            </ul>

        </div>
        
        {/* Edit Employee Modal */}
        {/* <CompaniesEmploymentEditModal
            prop_companyEmployeesRequest={companyEmployeesRequest}
            prop_employeeEditModal={employeeEditModal}
            prop_handleEmployeeModalEditClose={handleEmployeeModalEditClose}
            prop_user_id={get_user_id}
        ></CompaniesEmploymentEditModal> */}
        
        </>
    );
}

export default SidebarMenu;