import React, { useContext, useEffect, useRef, useState } from "react";
import { NavLink, Link, useNavigate, useParams } from "react-router-dom";
import axiosClient from "../axios-client";
import Userimg from "../images/man-2.png";
import axios from "axios";
import {
  useTable,
  useFilters,
  useGlobalFilter,
  useSortBy,
  useAsyncDebounce,
  usePagination,
} from "react-table";
import * as Icon from "react-feather";
import { ComponentLoading } from "../Components/ComponentLoading";
import { ToastContainer, toast } from "react-toastify";
import Swal from "sweetalert2";
import { Modal, Button, Nav, Tab, Row, Col } from "react-bootstrap";
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import moment from 'moment';
import Select from 'react-select'
import { ActionMeta, OnChangeValue } from 'react-select';
import { useStateContext } from "../Context/ContextProvider";

// Import Components
import CompanyBasicDetails from './CompanyBasicDetails';
import CompanyPowerUsers from './CompanyPowerUsers';
import PowerUserCompanyEmployeesUsers from './PowerUserCompanyEmployeesUsers';
import PowerUserCompanySites from './PowerUserCompanySites';

// ** Store Imports
import { useDispatch, useSelector } from 'react-redux'

// ** Actions Imports
import {
    stGetIndustriesData, 
    stActionMode, 
    stGetResponse, 
    stGetCompanyData, 
    stFetchCompanySites,
    stCompanyId,
    stFetchCompanyEmployees
} from '../store/apps/powerUser'

//token
const accessToken = localStorage.getItem("ACCESS_TOKEN");

const CompanyDetailsPage = (props) => {

    // ** Hooks
    const dispatch = useDispatch();
    
    const storePowerUser = useSelector((state) => state.stPowerUser)

  const { user, role, notification } = useStateContext();

  let { companyId, userType, companySiteId } = useParams();

  const [getComponentLoading, setComponentLoading] = useState(true);

  const [btnLoading, setBtnLoading] = useState(false)
  const [getCompanySites, setCompanySites] = useState([]);

  // const [getCompanyId, setCompanyId] = useState(null);
  // const [getCompanyName, setCompanyName] = useState("");
  // const [getCompanyMainAddress, setCompanyMainAddress] = useState("");
  // const [getCompanyMainPhone, setCompanyMainPhone] = useState("");
  // const [getCompanyIndustry, setCompanyIndustry] = useState(null);
  // const [getCompanyOtherSiteAddresses, setCompanyOtherSiteAddresses] = useState([]);
  // const [getCompanyOtherSitePhoneNumbers, setCompanyOtherSitePhoneNumbers] = useState([]);

  const [getCompanyDetails, setCompanyDetails] = useState({});
  const [getActiveTab, setActiveTab] = useState('');
    
  const store = useSelector((state) => state.stPowerUser)

  const editCompany = async () => {
    // setComponentLoading(true);
    const companyDetails = {}
    companyDetails.getCompanyId = store.stGetCompanyData.company.id
    companyDetails.getCompanyName = store.stGetCompanyData.company.name
    companyDetails.getCompanyMainAddress = store.stGetCompanyData.company.main_address
    companyDetails.getCompanyMainPhone = store.stGetCompanyData.company.main_phone
    if(store.stGetCompanyData.company.industry){
        companyDetails.getCompanyIndustry = store.stGetCompanyData.company.industry
    }
    if(store.stGetCompanyData.company.company_other_site_addresses_data){
        companyDetails.getCompanyOtherSiteAddresses = store.stGetCompanyData.company.company_other_site_addresses_data
    } else {
        companyDetails.getCompanyOtherSiteAddresses = []
    }
    if(store.stGetCompanyData.company.company_other_site_phone_numbers_data){
        companyDetails.getCompanyOtherSitePhoneNumbers = store.stGetCompanyData.company.company_other_site_phone_numbers_data
    } else {
        companyDetails.getCompanyOtherSitePhoneNumbers = []
    }
    // console.log('store.stGetCompanyData=',store.stGetCompanyData)
    companyDetails.getPowerUsersCount = store.stGetCompanyData.company.power_users_count
    companyDetails.getEmployeeUsersCount = store.stGetCompanyData.company.employee_users_count
    companyDetails.getCompanySitesCount = store.stGetCompanyData.company.company_sites_count
    setCompanyDetails(companyDetails)
    setComponentLoading(false);
  }

  const refreshEditCompanyHandle = () => {
    if(Object.keys(user).length > 0){
        // dispatch(stGetIndustriesData({user}));
        dispatch(stGetCompanyData({user}));
        dispatch(stFetchCompanySites({user}));
        dispatch(stFetchCompanyEmployees({user}));
        dispatch(stCompanyId({user}));
    }
  }

  const companySitesRequest = () => {
      if(storePowerUser.stFetchCompanySites.company_sites){
          setCompanySites(storePowerUser.stFetchCompanySites.company_sites);
          setComponentLoading(false);
      }
  };

  useEffect(() => {
      if(role == 'power_user'){
          companySitesRequest()
      }
    }, [role,storePowerUser]);

//   useEffect(() => {
//     if(companyId){
//       editCompany()
//     }
//     if(companyId && userType){
//       setActiveTab(userType)
//     } else {
//       setActiveTab('basic-details')
//     }
//   }, [companyId, userType]);

  useEffect(() => {
      if(role == 'power_user'){
      editCompany()
    }
    // if(companyId && userType && user.role == 'admin'){
    //   setActiveTab(userType)
    // } else {
    //   setActiveTab('basic-details')
    // }
  }, [role,store]);

  return (
    <>
    {
        userType != 'employees'?
        (
            <div className="pageTitle">
                <div className="">
                    <h1 className="page-heading">{getCompanyDetails.getCompanyName}</h1>
                    <nav aria-label="breadcrumb">
                        <ol className="breadcrumb">
                        <li className="breadcrumb-item"><Link to={"/"}>Home</Link></li>
                        <li className="breadcrumb-item active" aria-current="page"><Link to={"/companies"}>Companies</Link></li>
                        <li className="breadcrumb-item active" aria-current="page">{getCompanyDetails.getCompanyName}</li>
                        </ol>
                    </nav>
                </div>
                <div></div>
            </div>
        ):(
            <div className="pageTitle">
                <div className="">
                    <nav aria-label="breadcrumb">
                        <ol className="breadcrumb">
                        <li className="breadcrumb-item"><Link to={"/"}>Home</Link></li>
                        <li className="breadcrumb-item active" aria-current="page"><Link to={"/companies"}>Companies</Link></li>
                        <li className="breadcrumb-item active" aria-current="page">{getCompanyDetails.getCompanyName}</li>
                        </ol>
                    </nav>
                </div>
                <div></div>
            </div>
        )
    }

    {getComponentLoading ? (
        <div className="card loadingCard">
        <div className="card-body">
            <ComponentLoading></ComponentLoading>
        </div>
        </div>
    ) : (
        <>

        {
            userType == 'employees'?
            (
                <>
                <div className="card mb-3">
                    <div className="sitesTabNav">
                        <span>Sites:</span>
                        <Link className={`customBtn ${userType == 'employees' && !companySiteId ? "active" : ""}`} to={"/company/employees"}>
                            All
                        </Link>
                        {
                            getCompanySites.length > 0 && (
                                getCompanySites.map((item) => (
                                    <>
                                        {
                                            <Link className={`customBtn ${item.id == companySiteId ? "active" : ""}`} to={"/company/employees/"+item.id}>
                                                {item.name}
                                            </Link>
                                        }
                                    </>
                                ))
                            )
                        }
                    </div>
                </div>
                <div className="card mb-3">
                    <div className="card-body">
                        <div className="detailCard">
                            <div>
                                <h4 className="mb-2">{getCompanyDetails.getCompanyName}</h4>
                                <h6 className="mb-0"><Icon.Phone width={16} /> {getCompanyDetails.getCompanyMainPhone}</h6>
                                <p className="mb-0"><Icon.MapPin width={16} /> {getCompanyDetails.getCompanyMainAddress}</p>
                            </div>
                            <div className="countBoxes">
                                {
                                    role == 'admin' && (
                                        <div className="countBox">
                                        <span className="num">{getCompanyDetails.getPowerUsersCount}</span>
                                        <span className="txt">Power Users</span>
                                        </div>
                                    )
                                }
                                <div className="countBox">
                                    <span className="num">{getCompanyDetails.getEmployeeUsersCount}</span>
                                    <span className="txt">Employees</span>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* <div className="customTabNav">
                        <Link className={`customBtn ${getActiveTab === 'basic-details' ? 'active' : ''}`} to={'/companies/'+companyId}>Basic Details</Link>
                        {
                            role == 'admin' && (
                            <Link className={`customBtn ${getActiveTab === 'power-users' ? 'active' : ''}`} to={'/companies/'+companyId+'/power-users'}>Power Users</Link>
                            )
                        }
                        <Link className={`customBtn ${getActiveTab === 'sites' ? 'active' : ''}`} to={'/companies/'+companyId+'/sites'}>Company Sites</Link>
                        <Link className={`customBtn ${getActiveTab === 'employees' ? 'active' : ''}`} to={'/companies/'+companyId+'/employees'}>Employees</Link>
                    </div> */}
                </div>
                </>
            ):(
                <>
                <div className="countBoxes mb-3">
                    {
                        role == 'admin' && (
                            <div className="countBox">
                                <span className="num">{getCompanyDetails.getPowerUsersCount}</span>
                                <span className="txt">Power Users</span>
                            </div>
                        )
                    }
                    <div className="countBox">
                        <span className="num">{getCompanyDetails.getEmployeeUsersCount}</span>
                        <span className="txt">Employees</span>
                    </div>
                    <div className="countBox">
                        <span className="num">{getCompanyDetails.getCompanySitesCount}</span>
                        <span className="txt">Sites</span>
                    </div>
                </div>
                </>
            )
        }

        {
            userType == 'power-users' ? (
                <div className="card customCard">
                <CompanyPowerUsers refreshEditCompany={refreshEditCompanyHandle}></CompanyPowerUsers>
                </div>
            ):userType == 'sites' ?(
                <div className="card customCard">
                <PowerUserCompanySites refreshEditCompany={refreshEditCompanyHandle}></PowerUserCompanySites>
                </div>
            ):userType == 'employees' ?(
                <div className="card customCard">
                <PowerUserCompanyEmployeesUsers refreshEditCompany={refreshEditCompanyHandle}></PowerUserCompanyEmployeesUsers>
                </div>
            ):(
                <div className="card customCard">
                <PowerUserCompanySites refreshEditCompany={refreshEditCompanyHandle}></PowerUserCompanySites>
                </div>
            )
        }

        </>
    )}
    </>
  );
};

export default CompanyDetailsPage;
