import React, { useContext, useEffect, useRef, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import axiosClient from "../axios-client";
import Userimg from "../images/man-2.png";
import axios from "axios";
import {
  useTable,
  useFilters,
  useGlobalFilter,
  useSortBy,
  useAsyncDebounce,
  usePagination,
} from "react-table";
import * as Icon from "react-feather";
import { ComponentLoading } from "../Components/ComponentLoading";
import { ToastContainer, toast } from "react-toastify";
import Swal from "sweetalert2";
import { Modal, Button, Nav, Tab, Row, Col } from "react-bootstrap";
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import moment from 'moment';
import Select from 'react-select'
import { ActionMeta, OnChangeValue } from 'react-select';

import DatePicker from "react-datepicker";

import logo from '../images/cr-computer.png'

// ** Store Imports
import { useDispatch, useSelector } from 'react-redux'

//token
const accessToken = localStorage.getItem("ACCESS_TOKEN");

function CompanyEditModal({
    prop_refreshEditCompany,
    prop_companyEmployeesRequest,
    prop_employeeEditModal,
    prop_handleEmployeeModalEditClose,
    prop_employeeId
}) {

    const modalBodyRef = useRef(null);
    
    const stMainUser = useSelector((state) => state.stMainUser)

    // const [getEditComponentLoading, setEditComponentLoading] = useState(true);
  const [getComponentLoading, setComponentLoading] = useState(true);
    const [btnLoading, setBtnLoading] = useState(false)

    // Edit Company Modal
    const [employeeEditModal, setEmployeeEditModal] = useState(false);
    const handleEmployeeEditModalClose = () => {
        setEmployeeEditModal(false)
        prop_companyEmployeesRequest()
        prop_handleEmployeeModalEditClose()
        scrollToTop()
    };

    const [getLocations, setCompanySites] = useState([])

    const [getSharedDriveAccess, setSharedDriveAccess] = useState(["renu","commercial","doors","accounting","accounting2","compliance","ea","hr","marketing","payroll","photography","quickbooks","w_9","warehouse"])

    const [getSoftwares, setSoftwares] = useState(["office_360","rfms","timberline","timberscan","measure_it","pro_tech","quickbooks","quicken","nitropdf","plan_swift","xactimate","ms_project","adobe_acrobat_pro","rms_operations"])

    const [getCameraSystem, setCameraSystem] = useState(["main","warehouse","port_chester","sunrise_warehouse","blendex","all","none"])

    const [getLaptop, setLaptop] = useState(["standard","new_desk_setup","other"])

    const [getPrimaryEmailDomain, setPrimaryEmailDomain] = useState(["no_email","crcomputers.com","crcomputers2.com","crcomputers3.com"])

    const [getGroupMembership, setGroupMembership] = useState(["remove_web","admin","other"])

    const [getFormStep, setFormStep] = useState("first");
    const [getEmployeeId, setEmployeeId] = useState(null);

    // Step first
    const [get_requesting_user, set_requesting_user] = useState("");
    const [get_new_employee_name, set_new_employee_name] = useState("");
    const [get_job_title, set_job_title] = useState("");
    const [get_phone_number, set_phone_number] = useState("");
    const [get_start_date, set_start_date] = useState("");
    const [get_location, set_location] = useState("");
    const [get_other_information, set_other_information] = useState("");
    
    // Step second
    const [get_group_membership, set_group_membership] = useState("");
    const [get_shared_drive_access, set_shared_drive_access] = useState("");
    const [get_software, set_software] = useState("");
    const [get_camera_system, set_camera_system] = useState("");
    const [get_other_things_note, set_other_things_note] = useState("");
    
    // Step third
    const [get_laptop, set_laptop] = useState("");
    const [get_laptop_other, set_laptop_other] = useState("");
    const [get_personal_printer, set_personal_printer] = useState(null);
    const [get_desk_scanner, set_desk_scanner] = useState(null);
    const [get_desk_phone, set_desk_phone] = useState(null);
    const [get_ipad, set_ipad] = useState(null);
    const [get_other_equipment, set_other_equipment] = useState("");

    // Step fourth
    const [get_setup_email_on_cellphone, set_setup_email_on_cellphone] = useState("");
    const [get_primary_email_domain, set_primary_email_domain] = useState("");
    const [get_special_requests, set_special_requests] = useState("");

    const [get_approved_supervisor_name, set_approved_supervisor_name] = useState("");
    const [get_on_date, set_on_date] = useState("");
    const [get_approval_other_information, set_approval_other_information] = useState("");
    
    const [getStepsData, setStepsData] = useState({
        step_first: 'on',
        step_second: 'remain',
        step_third: 'remain',
        step_fourth: 'remain',
    });

    const handleFormStepBtn = (step) => {
        setFormStep(step)
        scrollToTop()
    }

    const init = () => {
        if(stMainUser.stFetchCompanySites.company_sites){
            setCompanySites(stMainUser.stFetchCompanySites.company_sites);
        }
    };

    const getEmployeeData = (empId) => {
        setComponentLoading(true)
        let config = {
          method: "get",
          url: process.env.REACT_APP_API_URL + "/employees/"+empId+"/get",
          headers: {
            Accept: "application/json",
            Authorization: `Bearer ${accessToken}`,
          },
        };
    
        axios(config)
            .then((response) => {

                const emp = response.data.employee
                
                // Step first
                if(emp.requesting_user){ set_requesting_user(emp.requesting_user) }
                if(emp.new_employee_name){ set_new_employee_name(emp.new_employee_name) }
                if(emp.job_title){ set_job_title(emp.job_title) }
                if(emp.phone_number){ set_phone_number(emp.phone_number) }
                if(emp.start_date){
                    const d = moment(emp.start_date).format('MM/D/YYYY');
                    set_start_date(new Date(d))
                }
                if(emp.location){ set_location(emp.location) }
                if(emp.other_information){ set_other_information(emp.other_information) }

                // Step second
                if(emp.group_membership){ set_group_membership(JSON.parse(emp.group_membership)) }
                if(emp.shared_drive_access){ set_shared_drive_access(JSON.parse(emp.shared_drive_access)) }
                if(emp.software){ set_software(JSON.parse(emp.software)) }
                if(emp.camera_system){ set_camera_system(JSON.parse(emp.camera_system)) }
                if(emp.other_things_note){ set_other_things_note(emp.other_things_note) }
                
                // Step third
                if(emp.laptop){ set_laptop(JSON.parse(emp.laptop)) }
                if(emp.laptop_other){ set_laptop_other(emp.laptop_other) }
                if(emp.personal_printer){ set_personal_printer(emp.personal_printer) }
                if(emp.desk_scanner){ set_desk_scanner(emp.desk_scanner) }
                if(emp.desk_phone){ set_desk_phone(emp.desk_phone) }
                if(emp.ipad){ set_ipad(emp.ipad) }
                if(emp.other_equipment){ set_other_equipment(emp.other_equipment) }

                // // Step fourth
                if(emp.setup_email_on_cellphone){ set_setup_email_on_cellphone(emp.setup_email_on_cellphone) }
                if(emp.primary_email_domain){ set_primary_email_domain(emp.primary_email_domain) }
                if(emp.special_requests){ set_special_requests(emp.special_requests) }

                if(emp.approved_supervisor_name){ set_approved_supervisor_name(emp.approved_supervisor_name) }
                if(emp.on_date){
                    const d = moment(emp.on_date).format('MM/D/YYYY');
                    set_on_date(new Date(d))
                }
                if(emp.approval_other_information){ set_approval_other_information(emp.approval_other_information) }

                setComponentLoading(false);

            })
            .catch((error) => {
                setComponentLoading(false);
            });
    };

    useEffect(() => {

        init()
        
        if(prop_employeeId){
            setEmployeeId(prop_employeeId)
            getEmployeeData(prop_employeeId)
        }

        if(prop_employeeEditModal){
            setEmployeeEditModal(prop_employeeEditModal)
        }

        // console.log('getFormStep=>>>',getFormStep)
        
    }, [prop_employeeEditModal, prop_employeeId, stMainUser]);

    const handleGroupMembershipChange = (value) => {
        if (get_group_membership.includes(value)) {
            set_group_membership(get_group_membership.filter(item => item !== value));
        } else {
            set_group_membership([...get_group_membership, value]);
        }
    }

    const handleSharedDriveAccessChange = (value) => {
        if (get_shared_drive_access.includes(value)) {
            set_shared_drive_access(get_shared_drive_access.filter(item => item !== value));
        } else {
            set_shared_drive_access([...get_shared_drive_access, value]);
        }
    }

    const handleSoftwareChange = (value) => {
        if (get_software.includes(value)) {
            set_software(get_software.filter(item => item !== value));
        } else {
            set_software([...get_software, value]);
        }
    }

    const handleCameraSystemChange = (value) => {
        if (get_camera_system.includes(value)) {
            set_camera_system(get_camera_system.filter(item => item !== value));
        } else {
            set_camera_system([...get_camera_system, value]);
        }
    }

    const handleLaptopChange = (value) => {
        if (get_laptop.includes(value)) {
            set_laptop(get_laptop.filter(item => item !== value));
        } else {
            set_laptop([...get_laptop, value]);
        }
    }

    const handleDateSelectStartDate = (date) => {
        console.log('handleDateSelectStartDate=',date)
        set_start_date(date)
    }

    const handleDateSelectOnDate = (date) => {
        // console.log('handleDateSelectOnDate=',date)
        set_on_date(date)
    }

    const scrollToTop = () =>{
        if (modalBodyRef.current) {
            modalBodyRef.current.scrollTop = 0;
        }
    }

    const saveBtn = () => {
        setBtnLoading(true)
        let payload = {

            step: getFormStep,
            employee_id: getEmployeeId,

            // Step first
            requesting_user: get_requesting_user,
            new_employee_name: get_new_employee_name,
            job_title: get_job_title,
            phone_number: get_phone_number,
            start_date: get_start_date,
            location: get_location,
            other_information: get_other_information,
            
            // Step second
            group_membership: get_group_membership,
            shared_drive_access: get_shared_drive_access,
            software: get_software,
            camera_system: get_camera_system,
            other_things_note: get_other_things_note,

            // Step third
            laptop: get_laptop,
            laptop_other: get_laptop_other,
            personal_printer: get_personal_printer,
            desk_scanner: get_desk_scanner,
            desk_phone: get_desk_phone,
            ipad: get_ipad,
            other_equipment: get_other_equipment,

            // Step fourth
            setup_email_on_cellphone: get_setup_email_on_cellphone,
            primary_email_domain: get_primary_email_domain,
            special_requests: get_special_requests,
            approved_supervisor_name: get_approved_supervisor_name,
            on_date: get_on_date,
            approval_other_information: get_approval_other_information,

        }
        axiosClient.put('/employees/'+getEmployeeId, payload)
            .then((res) => {
                setBtnLoading(false)
                console.log('payload=',payload.start_date)
                if(res.data.step == 'second'){
                    setStepsData(prevState => {
                        return {
                            ...prevState,
                            step_first: 'completed',
                            step_second: 'on'
                        };
                    });
                }
                if(res.data.step == 'third'){
                    setStepsData(prevState => {
                        return {
                            ...prevState,
                            step_second: 'completed',
                            step_third: 'on'
                        };
                    });
                }
                if(res.data.step == 'fourth'){
                    setStepsData(prevState => {
                        return {
                            ...prevState,
                            step_third: 'completed',
                            step_fourth: 'on'
                        };
                    });
                }
                if(res.data.step == 'fifth'){
                    setStepsData(prevState => {
                        return {
                            ...prevState,
                            step_fourth: 'completed'
                        };
                    });
                }
                if(res.data.step == 'first' || res.data.step == 'second' || res.data.step == 'third' || res.data.step == 'fourth'){
                    setFormStep(res.data.step)
                    scrollToTop()
                } else {
                    setTimeout(() => {
                        toast.success(res.data.message, {
                            position: "bottom-right",
                            autoClose: 1500,
                            theme: "colored",
                        });
                        prop_refreshEditCompany()
                        prop_companyEmployeesRequest()
                        handleEmployeeEditModalClose()
                    }, 500);
                }
                console.log('getFormStep2=',getFormStep)
            })
            .catch(err => {
                setBtnLoading(false)
                var firstErrorMessage = '';
                if (err.response.data.errors && Object.keys(err.response.data.errors).length != 0) {
                var errors = err.response.data.errors
                const errorMessages = Object.values(errors); // Get an array of all error messages
                firstErrorMessage = errorMessages.shift(); // Get the first error message
                toast.error(firstErrorMessage[0], {
                    position: "bottom-right",
                    autoClose: 1500,
                    theme: "colored",
                });
                } else {
                // console.log('firstErrorMessage',firstErrorMessage[0])
                toast.error(err.response.data.message, {
                    position: "bottom-right",
                    autoClose: 1500,
                    theme: "colored",
                });
                }
                // console.log(err);
            });

    }

    useEffect(() => {
        console.log('getFormStep=>>>',getFormStep)
    }, [getFormStep]);

  return (
    <>
    <Modal
        className="customModal" // hide-btn-close
        backdrop="static"
        centered
        size="xl"
        show={employeeEditModal}
        onHide={handleEmployeeEditModalClose}
      >
        <Modal.Header closeButton>
          <Modal.Title>
            <img className="modalLogo" src={logo} />

            <div>
                <ul className="stepsHeading">
                    <li>
                        <span className={"step step_first "+getStepsData.step_first}>
                            {getStepsData.step_first!='remain'?(<Icon.CheckCircle />):(<Icon.Circle />)}
                        </span>
                        <span className="heading">Employee Information</span>
                    </li>
                    <li>
                        <span className={"step step_second "+getStepsData.step_second}>
                            {getStepsData.step_second!='remain'?(<Icon.CheckCircle />):(<Icon.Circle />)}
                        </span>
                        <span className="heading">Network Access & Software</span>
                    </li>
                    <li>
                        <span className={"step step_third "+getStepsData.step_third}>
                            {getStepsData.step_third!='remain'?(<Icon.CheckCircle />):(<Icon.Circle />)}
                        </span>
                        <span className="heading">Equipment</span>
                    </li>
                    <li>
                        <span className={"step step_fourth "+getStepsData.step_fourth}>
                            {getStepsData.step_fourth!='remain'?(<Icon.CheckCircle />):(<Icon.Circle />)}
                        </span>
                        <span className="heading">Configurations</span>
                    </li>
                </ul>
            </div>

          </Modal.Title>
        </Modal.Header>
        <Modal.Body ref={modalBodyRef}>
          <form>
            {getComponentLoading ? (
                <div className="card loadingCard">
                <div className="card-body">
                    <ComponentLoading></ComponentLoading>
                </div>
                </div>
            ) : (
                <>
                {/* {getEmployeeId} */}
                <div className="row">
                    <div className="col-sm-3"></div>
                    <div className="col-sm-6">
                        {
                            getFormStep == 'first'?
                            (
                                <>
                                <h4>Employee Information</h4>
                                <div className="mb-3">
                                    <label for="get_requesting_user" className="form-label">
                                        Requesting User: (Your name, first and last)<span className="text-danger">*</span>
                                    </label>
                                    <input type="text" className="form-control" id="get_requesting_user" required value={get_requesting_user} onChange={e => set_requesting_user(e.target.value)} />
                                </div>
                                <div className="mb-3">
                                    <label for="get_new_employee_name" className="form-label">
                                        New Employee Name (Their name, first and last)<span className="text-danger">*</span>
                                    </label>
                                    <input type="text" className="form-control" id="get_new_employee_name" required value={get_new_employee_name} onChange={e => set_new_employee_name(e.target.value)} />
                                </div>
                                <div className="mb-3">
                                    <label for="get_job_title" className="form-label">
                                        Job Title<span className="text-danger">*</span>
                                    </label>
                                    <input type="text" className="form-control" id="get_job_title" required value={get_job_title} onChange={e => set_job_title(e.target.value)} />
                                </div>
                                <div className="mb-3">
                                    <label for="get_phone_number" className="form-label">
                                        Phone Number
                                    </label>
                                    <input type="text" className="form-control" id="get_phone_number" required value={get_phone_number} onChange={e => set_phone_number(e.target.value)} />
                                </div>
                                <div className="mb-3">
                                    <label for="get_start_date" className="form-label">
                                        Start Date<span className="text-danger">*</span>
                                    </label>
                                    <div>
                                        <DatePicker
                                            className="form-control"
                                            id="get_start_date"
                                            selected={get_start_date}
                                            onSelect={handleDateSelectStartDate}
                                            dateFormat="M/d/yyyy"
                                        />
                                    </div>
                                </div>
                                <div className="mb-3">
                                    <label for="get_location" className="form-label">
                                        Location<span className="text-danger">*</span>
                                    </label>
                                    {
                                        getLocations.length > 0 && (
                                            getLocations.map((item,index) => (
                                                <>
                                                    <div className="form-check" key={index}>
                                                        <input
                                                            className="form-check-input"
                                                            type="radio"
                                                            id={'location-'+index}
                                                            name="location"
                                                            value={item.id}
                                                            checked={get_location == item.id}
                                                            onChange={e => set_location(e.target.value)}
                                                        />
                                                        <label className="form-check-label" htmlFor={'location-'+index}>{item.name}</label>
                                                    </div>
                                                </>
                                            ))
                                        )
                                    }
                                </div>
                                <div className="mb-3">
                                    <label for="get_other_information" className="form-label">
                                        Other Information (Desk location)
                                    </label>
                                    <textarea type="text" className="form-control" id="get_other_information" required value={get_other_information} onChange={e => set_other_information(e.target.value)}></textarea>
                                </div>
                                </>
                            ):getFormStep == 'second'?
                            (
                                <>
                                <h4>Network Access and Software</h4>
                                <p>Each full-time employee will be issued a network login which includes Domain access and access to file shares.</p>
                                <div className="mb-3">
                                    <label for="get_location" className="form-label">
                                    Group Membership<span className="text-danger">*</span>
                                    </label>
                                    {getGroupMembership.map(item => (
                                    <div className="form-check" key={item}>
                                        <input
                                        className="form-check-input"
                                        type="checkbox"
                                        id={'location-'+item}
                                        name="item"
                                        value={item}
                                        checked={get_group_membership.includes(item)}
                                        onChange={e => handleGroupMembershipChange(e.target.value)}
                                        />
                                        <label className="form-check-label" htmlFor={'location-'+item}>{item}</label>
                                    </div>
                                    ))}
                                </div>
                                <div className="mb-3">
                                    <label for="get_location" className="form-label">
                                    Shared Drive Access: Please check only those that apply<span className="text-danger">*</span>
                                    </label>
                                    {getSharedDriveAccess.map(item => (
                                    <div className="form-check" key={item}>
                                        <input
                                        className="form-check-input"
                                        type="checkbox"
                                        id={'location-'+item}
                                        name="item"
                                        value={item}
                                        checked={get_shared_drive_access.includes(item)}
                                        onChange={e => handleSharedDriveAccessChange(e.target.value)}
                                        />
                                        <label className="form-check-label" htmlFor={'location-'+item}>{item}</label>
                                    </div>
                                    ))}
                                </div>
                                <div className="mb-3">
                                    <label className="form-label">
                                    Software
                                    </label>
                                    {getSoftwares.map(item => (
                                    <div className="form-check" key={item}>
                                        <input
                                        className="form-check-input"
                                        type="checkbox"
                                        id={'software-'+item}
                                        name="item"
                                        value={item}
                                        checked={get_software.includes(item)}
                                        onChange={e => handleSoftwareChange(e.target.value)}
                                        />
                                        <label className="form-check-label" htmlFor={'software-'+item}>{item}</label>
                                    </div>
                                    ))}
                                </div>
                                <div className="mb-3">
                                    <label className="form-label">
                                    Camera System
                                    </label>
                                    {getCameraSystem.map(item => (
                                    <div className="form-check" key={item}>
                                        <input
                                        className="form-check-input"
                                        type="checkbox"
                                        id={'camera_system-'+item}
                                        name="item"
                                        value={item}
                                        checked={get_camera_system.includes(item)}
                                        onChange={e => handleCameraSystemChange(e.target.value)}
                                        />
                                        <label className="form-check-label" htmlFor={'camera_system-'+item}>{item}</label>
                                    </div>
                                    ))}
                                </div>
                                <div className="mb-3">
                                    <label for="get_other_things_note" className="form-label">
                                        Other things to note
                                    </label>
                                    <textarea type="text" className="form-control" id="get_other_things_note" required value={get_other_things_note} onChange={e => set_other_things_note(e.target.value)}></textarea>
                                </div>
                                </>
                            ):getFormStep == 'third'?
                            (
                                <>
                                <h4>Equipment</h4>
                                <p>Existing equipment will be used first.</p>
                                <div className="mb-3">
                                    <label for="get_location" className="form-label">
                                    Laptop<span className="text-danger">*</span>
                                    </label>
                                    {getLaptop.map(item => (
                                    <div className="form-check" key={item}>
                                        <input
                                            className="form-check-input"
                                            type="checkbox"
                                            id={item}
                                            name="item"
                                            value={item}
                                            checked={get_laptop.includes(item)}
                                            onChange={e => handleLaptopChange(e.target.value)}
                                        />
                                        {
                                            item == 'other' ? (
                                                <input type="text" className="form-control" id="get_laptop_other" placeholder={item} required value={get_laptop_other} onChange={e => set_laptop_other(e.target.value)} />
                                            ):(
                                                <label className="form-check-label" htmlFor={item}>{item}</label>
                                            )
                                        }
                                    </div>
                                    ))}
                                </div>
                                <div className="mb-3">
                                    <label className="form-label">
                                        Personal Printer<span className="text-danger">*</span>
                                    </label>
                                    <div class="form-check">
                                        <input
                                            className="form-check-input"
                                            type="radio"
                                            id="get_personal_printer_yes"
                                            name="get_personal_printer"
                                            value={'yes'}
                                            checked={get_personal_printer === 'yes'}
                                            onChange={e => set_personal_printer(e.target.value)}
                                        />
                                        <label className="form-check-label" htmlFor="get_personal_printer_yes">Yes</label>
                                    </div>
                                    <div class="form-check">
                                        <input
                                            className="form-check-input"
                                            type="radio"
                                            id="get_personal_printer_no"
                                            name="get_personal_printer"
                                            value={'no'}
                                            checked={get_personal_printer === 'no'}
                                            onChange={e => set_personal_printer(e.target.value)}
                                        />
                                        <label className="form-check-label" htmlFor="get_personal_printer_no">No</label>
                                    </div>
                                </div>
                                <div className="mb-3">
                                    <label className="form-label">
                                        Desk Scanner<span className="text-danger">*</span>
                                    </label>
                                    <div class="form-check">
                                        <input
                                            className="form-check-input"
                                            type="radio"
                                            id="get_desk_scanner_yes"
                                            name="get_desk_scanner"
                                            value={'yes'}
                                            checked={get_desk_scanner === 'yes'}
                                            onChange={e => set_desk_scanner(e.target.value)}
                                        />
                                        <label className="form-check-label" htmlFor="get_desk_scanner_yes">Yes</label>
                                    </div>
                                    <div class="form-check">
                                        <input
                                            className="form-check-input"
                                            type="radio"
                                            id="get_desk_scanner_no"
                                            name="get_desk_scanner"
                                            value={'no'}
                                            checked={get_desk_scanner === 'no'}
                                            onChange={e => set_desk_scanner(e.target.value)}
                                        />
                                        <label className="form-check-label" htmlFor="get_desk_scanner_no">No</label>
                                    </div>
                                </div>
                                <div className="mb-3">
                                    <label className="form-label">
                                        Desk Phone<span className="text-danger">*</span>
                                    </label>
                                    <div class="form-check">
                                        <input
                                            className="form-check-input"
                                            type="radio"
                                            id="get_desk_phone_yes"
                                            name="get_desk_phone"
                                            value={'yes'}
                                            checked={get_desk_phone === 'yes'}
                                            onChange={e => set_desk_phone(e.target.value)}
                                        />
                                        <label className="form-check-label" htmlFor="get_desk_phone_yes">Yes</label>
                                    </div>
                                    <div class="form-check">
                                        <input
                                            className="form-check-input"
                                            type="radio"
                                            id="get_desk_phone_no"
                                            name="get_desk_phone"
                                            value={'no'}
                                            checked={get_desk_phone === 'no'}
                                            onChange={e => set_desk_phone(e.target.value)}
                                        />
                                        <label className="form-check-label" htmlFor="get_desk_phone_no">No</label>
                                    </div>
                                </div>
                                <div className="mb-3">
                                    <label className="form-label">
                                        iPad (Generally PM's, Supers & Sales)<span className="text-danger">*</span>
                                    </label>
                                    <div class="form-check">
                                        <input
                                            className="form-check-input"
                                            type="radio"
                                            id="get_ipad_yes"
                                            name="get_ipad"
                                            value={'yes'}
                                            checked={get_ipad === 'yes'}
                                            onChange={e => set_ipad(e.target.value)}
                                        />
                                        <label className="form-check-label" htmlFor="get_ipad_yes">Yes</label>
                                    </div>
                                    <div class="form-check">
                                        <input
                                            className="form-check-input"
                                            type="radio"
                                            id="get_ipad_no"
                                            name="get_ipad"
                                            value={'no'}
                                            checked={get_ipad === 'no'}
                                            onChange={e => set_ipad(e.target.value)}
                                        />
                                        <label className="form-check-label" htmlFor="get_ipad_no">No</label>
                                    </div>
                                </div>
                                <div className="mb-3">
                                    <label for="get_other_equipment" className="form-label">
                                        Other Equipment and Special Requests
                                    </label>
                                    <textarea type="text" className="form-control" id="get_other_equipment" required value={get_other_equipment} onChange={e => set_other_equipment(e.target.value)}></textarea>
                                </div>
                                </>
                            ):(
                                <>
                                <h4>Configurations</h4>
                                <div className="mb-3">
                                    <label className="form-label">
                                        Set up email on cellphone<span className="text-danger">*</span>
                                    </label>
                                    <div class="form-check">
                                        <input
                                            className="form-check-input"
                                            type="radio"
                                            id="get_setup_email_on_cellphone_yes"
                                            name="get_setup_email_on_cellphone"
                                            value={'yes'}
                                            checked={get_setup_email_on_cellphone === 'yes'}
                                            onChange={e => set_setup_email_on_cellphone(e.target.value)}
                                        />
                                        <label className="form-check-label" htmlFor="get_setup_email_on_cellphone_yes">Yes</label>
                                    </div>
                                    <div class="form-check">
                                        <input
                                            className="form-check-input"
                                            type="radio"
                                            id="get_setup_email_on_cellphone_no"
                                            name="get_setup_email_on_cellphone"
                                            value={'no'}
                                            checked={get_setup_email_on_cellphone === 'no'}
                                            onChange={e => set_setup_email_on_cellphone(e.target.value)}
                                        />
                                        <label className="form-check-label" htmlFor="get_setup_email_on_cellphone_no">No</label>
                                    </div>
                                </div>
                                <div className="mb-3">
                                    <label for="get_location" className="form-label">
                                        Primary Email Domain (crcomputers.com is used for all users)<span className="text-danger">*</span>
                                    </label>
                                    {getPrimaryEmailDomain.map(item => (
                                        <div className="form-check" key={item}>
                                            <input
                                                className="form-check-input"
                                                type="radio"
                                                id={item}
                                                name="get_primary_email_domain"
                                                value={item}
                                                checked={get_primary_email_domain === item}
                                                onChange={e => set_primary_email_domain(e.target.value)}
                                            />
                                            <label className="form-check-label" htmlFor={item}>{item}</label>
                                        </div>
                                    ))}
                                </div>
                                <div className="mb-3">
                                    <label for="get_special_requests" className="form-label">
                                        Special Requests
                                    </label>
                                    <textarea type="text" className="form-control" id="get_special_requests" required value={get_special_requests} onChange={e => set_special_requests(e.target.value)}></textarea>
                                </div>
                                <h4>Approval</h4>
                                <div className="mb-3">
                                    <label for="get_approved_supervisor_name" className="form-label">
                                        Approved by Supervisor: (Name)<span className="text-danger">*</span>
                                    </label>
                                    <input type="text" className="form-control" id="get_approved_supervisor_name" required value={get_approved_supervisor_name} onChange={e => set_approved_supervisor_name(e.target.value)} />
                                </div>
                                <div className="mb-3">
                                    <label for="get_on_date" className="form-label">
                                        On date<span className="text-danger">*</span>
                                    </label>
                                    <div>
                                        <DatePicker
                                            className="form-control"
                                            id="get_start_date"
                                            selected={get_on_date}
                                            onSelect={handleDateSelectOnDate}
                                            dateFormat="MM/dd/yyyy"
                                        />
                                    </div>
                                    {/* <input type="text" className="form-control" id="get_on_date" required value={get_on_date} onChange={e => set_on_date(e.target.value)} /> */}
                                </div>
                                <div className="mb-3">
                                    <label for="get_approval_other_information" className="form-label">
                                        Other information
                                    </label>
                                    <textarea type="text" className="form-control" id="get_approval_other_information" required value={get_approval_other_information} onChange={e => set_approval_other_information(e.target.value)}></textarea>
                                </div>
                                </>
                            )
                        }
                    </div>
                    <div className="col-sm-3"></div>
                </div>
                </>
            )}
          </form>
        </Modal.Body>
        <Modal.Footer>
          <div className="row">
            <div className="col-sm-3"></div>
            <div className="col-sm-6">
              <div className="centeredBtns">
                {
                    getFormStep == 'first'?
                    (
                        <>
                            <Button
                            variant="btn btn-outline-primary"
                            onClick={handleEmployeeEditModalClose}
                            >
                                Close
                            </Button>
                            <button type="submit" className="btn btn-primary" disabled={btnLoading} onClick={() => {saveBtn()}}>
                            {
                                btnLoading ?
                                (
                                    <>Please Wait <span className="spinner-grow spinner-grow-sm ml-2" role="status" aria-hidden="true"></span></>
                                ) : (
                                    <>Next</>
                                )
                            }
                            </button>
                        </>
                    ):getFormStep == 'second'?
                    (
                        <>
                            <Button
                            variant="btn btn-outline-primary"
                            onClick={() => {handleFormStepBtn('first')}}
                            >
                                Back
                            </Button>
                            <button type="submit" className="btn btn-primary" disabled={btnLoading} onClick={() => {saveBtn()}}>
                            {
                                btnLoading ?
                                (
                                    <>Please Wait <span className="spinner-grow spinner-grow-sm ml-2" role="status" aria-hidden="true"></span></>
                                ) : (
                                    <>Next</>
                                )
                            }
                            </button>
                        </>
                    ):getFormStep == 'third'?
                    (
                        <>
                            <Button
                            variant="btn btn-outline-primary"
                            onClick={() => {handleFormStepBtn('second')}}
                            >
                                Back
                            </Button>
                            <button type="submit" className="btn btn-primary" disabled={btnLoading} onClick={() => {saveBtn()}}>
                            {
                                btnLoading ?
                                (
                                    <>Please Wait <span className="spinner-grow spinner-grow-sm ml-2" role="status" aria-hidden="true"></span></>
                                ) : (
                                    <>Next</>
                                )
                            }
                            </button>
                        </>
                    ):(
                        <>
                        <Button
                        variant="btn btn-outline-primary"
                        onClick={() => {handleFormStepBtn('third')}}
                        >
                            Back
                        </Button>
                        <button type="submit" className="btn btn-primary" disabled={btnLoading} onClick={saveBtn}>
                        {
                            btnLoading ?
                            (
                                <>Please Wait <span className="spinner-grow spinner-grow-sm ml-2" role="status" aria-hidden="true"></span></>
                            ) : (
                                <>Save</>
                            )
                        }
                        </button>
                        </>
                    )
                }
              </div>
            </div>
            <div className="col-sm-3"></div>
          </div>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default CompanyEditModal;
