import React, { useContext, useEffect, useRef, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import axiosClient from "../axios-client";
import Userimg from "../images/man-2.png";
import axios from "axios";
import {
  useTable,
  useFilters,
  useGlobalFilter,
  useSortBy,
  useAsyncDebounce,
  usePagination,
} from "react-table";
import * as Icon from "react-feather";
import { ComponentLoading } from "../Components/ComponentLoading";
import { ToastContainer, toast } from "react-toastify";
import Swal from "sweetalert2";
import { Modal, Button, Nav, Tab, Row, Col } from "react-bootstrap";
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import moment from 'moment';
import Select from 'react-select'
import { ActionMeta, OnChangeValue } from 'react-select';

// Import Components
import CompaniesOtherSiteAddresses from '../PageComponents/CompaniesOtherSiteAddresses';
import CompaniesOtherSitePhoneNumbers from '../PageComponents/CompaniesOtherSitePhoneNumbers';

//token
const accessToken = localStorage.getItem("ACCESS_TOKEN");

const CompanyDetailsPage = ({companyDetails}) => {
  let { companyId, userType } = useParams();

  const [getComponentLoading, setComponentLoading] = useState(false);
  
  const [getCompanyDetails, setCompanyDetails] = useState({});

  useEffect(() => {
    console.log('companyDetails=', companyDetails);
    setCompanyDetails(companyDetails)
  }, [companyDetails]);

  return (
    <>
      {/* Page Content */}
      {getComponentLoading ? (
        <div className="card loadingCard">
          <div className="card-body">
            <ComponentLoading></ComponentLoading>
          </div>
        </div>
      ) : (
        <div className="card customCard">
          <div className="card-body">
            <div className="row mb-3">
              <div className="col-sm-3">Name </div>
              <div className="col-sm-9">
                { getCompanyDetails.getCompanyName }
              </div>
            </div>
            <div className="row mb-3">
              <div className="col-sm-3">Phone </div>
              <div className="col-sm-9">
                { getCompanyDetails.getCompanyMainPhone }
              </div>
            </div>
            <div className="row mb-3">
              <div className="col-sm-3">Address </div>
              <div className="col-sm-9">
                { getCompanyDetails.getCompanyMainAddress }
              </div>
            </div>
            <div className="row mb-3">
              <div className="col-sm-3">Other site addresses </div>
              <div className="col-sm-9">
                {
                  getCompanyDetails.getCompanyOtherSiteAddresses && getCompanyDetails.getCompanyOtherSiteAddresses.length > 0 ? (
                    <>
                    <ul>
                      {
                        getCompanyDetails.getCompanyOtherSiteAddresses.map((item, index) => (
                          <li>
                            {item}
                          </li>
                        ))
                      }
                    </ul>
                    </>
                  ):'N/A'
                }
              </div>
            </div>
            <div className="row mb-3">
              <div className="col-sm-3">Other site phone numbers </div>
              <div className="col-sm-9">
                {
                  getCompanyDetails.getCompanyOtherSitePhoneNumbers && getCompanyDetails.getCompanyOtherSitePhoneNumbers.length > 0 ? (
                    <>
                    <ul>
                      {
                        getCompanyDetails.getCompanyOtherSitePhoneNumbers.map((item, index) => (
                          <li>
                            {item}
                          </li>
                        ))
                      }
                    </ul>
                    </>
                  ):"N/A"
                }
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default CompanyDetailsPage;
