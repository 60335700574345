import React, { useState, useEffect } from 'react';

function CompaniesOtherSiteAddresses({ otherSiteAddresses, onSelect }) {
  const [addresses, setAddresses] = useState([]);

  useEffect(() => {
    setAddresses(otherSiteAddresses);
  }, [otherSiteAddresses]);

  const handleInputChange = (e) => {
    const newAddress = e.target.value;
    setAddresses([...addresses, newAddress]);
  };

  const deleteAddress = (index) => {
    const updatedAddresses = addresses.filter((_, i) => i !== index);
    setAddresses(updatedAddresses);
  };

  useEffect(() => {
    onSelect(addresses);
  }, [addresses, onSelect]);

  return (
    <div>
      <h2>Other Site Addresses</h2>
      <input type="text" onChange={handleInputChange} placeholder="Enter an address" />

      <ul>
        {addresses.map((address, index) => (
          <li key={index}>
            {address}
            <button onClick={() => deleteAddress(index)}>Delete</button>
          </li>
        ))}
      </ul>
    </div>
  );
}

export default CompaniesOtherSiteAddresses;
