import { Outlet, Navigate, NavLink , Link } from 'react-router-dom';
import axiosClient from "../axios-client.js";
import { useStateContext } from "../Context/ContextProvider";
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom'

// import Navbar from './Navbar.jsx';
import Sidebar from './Sidebar.jsx';
import AdminSidebar from './AdminSidebar.jsx';
import '../CSS/styles.css';
// import '../CSS/styles-dark.css';
import * as Icon from 'react-feather';
import MapLocation from './MapLocation.jsx';
import { Modal, Button } from "react-bootstrap";
import { ToastContainer, toast } from "react-toastify";

import LocationSearchInput from './LocationSearchInput.jsx';
import LocationAutoComplete from './LocationAutoComplete.jsx';

import PlacesAutoComplete from '../Components/PlacesAutocomplete';
import { ComponentLoading } from "../Components/ComponentLoading";

import "react-datepicker/dist/react-datepicker.css";

export default function DefaultLayout() {

    const navigate = useNavigate();

    const { user, token, role, setUser, setToken, setRole, notification } = useStateContext();

    const [getComponentLoading, setComponentLoading] = useState(true);

    // Subscription Modal
    const [showLocationModal, setLocationModal] = useState(false);
    const handleLocationModalClose = () => setLocationModal(false);
    const handleSubscriptionModalShow = () => setLocationModal(true);
    
    const [btnLoading, setBtnLoading] = useState(false)
    const [address, setAddress] = useState("");
    const [latitude, setLatitude] = useState("");
    const [longitude, setLongitude] = useState("");
    
  const [getRequestLoading, setRequestLoading] = useState(true)
  const [getIsLoading, setIsLoading] = useState(true)
  
  const [getUserRole, setUserRole] = useState("");

    const getUser = () => {
        setComponentLoading(true)
        axiosClient.get('/profile')
            .then(({ data }) => {
                setComponentLoading(false)
                setRequestLoading(false)
                setUser(data.user)
                setRole(data.role)
                setAddress(data.user.address)
                setUserRole(data.role)
                setIsLoading(false)
                if(data.role == 'dealer'){
                    navigate('cars')
                }
                console.log('role ----',data.role)
                // console.log('user data ---',data.user)
                return data
            })
            .catch(err => {
                setComponentLoading(false)
                setIsLoading(false)
                setRequestLoading(false)
                navigate('/login')
            });
    }

    useEffect(() => {

        document.body.classList.remove('bg-auth');
        document.body.classList.add('bg-app');
        document.body.classList.add('layout-menu-fixed');

        getUser();

        window.addEventListener('refreshProfile', (event) => {
            if (event['detail'].value) {
                getUser();
            }
        })
        
    }, [getUserRole, getIsLoading])

    if (!token) {
        return <Navigate to="/login" />
    }
    
    const GetMapAddress =(e)=>{
        e.preventDefault()
        getUser()
        setLocationModal(true)
    }
    const onLogout = (e) => {
        e.preventDefault()

        axiosClient.post('/logout')
            .then(() => {
                setUser({})
                setRole(null)
                setToken(null)
            })
    }
  
    const handlePlacesAutocompleteSelect = (latitude, longitude, address) => {
        setLatitude(latitude);
        setLongitude(longitude);
        setAddress(address);
    };

    const handleSubmitSaveAddress = (e) => {
        setBtnLoading(true)
        e.preventDefault();
        const payload = {
            address: address,
            latitude: latitude,
            longitude: longitude,
        }

        axiosClient.put('/user/address', payload)
            .then((response) => {
                setBtnLoading(false)
                console.log('response', response)
                // response.json({ message: 'Request received!', response })
                toast.success(response.data.message, {
                    position: "bottom-right",
                    autoClose: 1500,
                    theme: "colored",
                });
                getUser();
                handleLocationModalClose()
                window.dispatchEvent(new CustomEvent(
                    'addressUpdated', 
                    {
                        detail: {
                            value:true
                        }
                    }
                ))
            }).catch(err => {
                setBtnLoading(false)
                var firstErrorMessage = '';
                if (err.response.data.errors && Object.keys(err.response.data.errors).length != 0) {
                    var errors = err.response.data.errors
                    const errorMessages = Object.values(errors); // Get an array of all error messages
                    firstErrorMessage = errorMessages.shift(); // Get the first error message
                    toast.error(firstErrorMessage[0], {
                        position: "bottom-right",
                        autoClose: 1500,
                        theme: "colored",
                    });
                } else {
                    // console.log('firstErrorMessage',firstErrorMessage[0])
                    toast.error(err.response.data.message, {
                        position: "bottom-right",
                        autoClose: 1500,
                        theme: "colored",
                    });
                }
                //   console.log(err);
            });

    }

    // const toggleNavbar = () => {
    //     document.body.classList.add('layout-menu-fixed');
    // }

    return (
        <>
            {getComponentLoading ? (
                <div className="card loadingCard" style={{height: '100vh'}}>
                    <div className="card-body">
                        <ComponentLoading></ComponentLoading>
                    </div>
                </div>
            ) : (
                <>
                {/* <Navbar/> */}
                <AdminSidebar getUserRole={getUserRole} getIsLoading={getIsLoading} />
                {
                    // role == 'admin'?
                    // (
                    //     <AdminSidebar getUserRole={getUserRole} getIsLoading={getIsLoading} />
                    // ):(
                    //     <Sidebar getUserRole={getUserRole} getIsLoading={getIsLoading} />
                    // )
                }
                <div className="layout-page">
                    <div className="layout-navbar">
                        <div className="layout-navbar-wrapper">
                            {/* <button className="navbar-toggle" onClick={toggleNavbar}>
                                <i className={`fa fa-bars`}></i>
                            </button> */}

                            {/* <nav className="navbar navbar-expand-lg bg-body-tertiary">
                                <div className="container-fluid">
                                    <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                                        <span className="navbar-toggler-icon"></span>
                                    </button>
                                    <div className="collapse navbar-collapse" id="navbarSupportedContent">
                                        <ul className="navbar-nav me-auto mb-2 mb-lg-0">
                                            <li className="nav-item">
                                                <a className="nav-link active" aria-current="page" href="#">Home</a>
                                            </li>
                                        </ul>
                                        <ul className="navbar-nav ms-auto mb-2 mb-lg-0">
                                            <li className="nav-item dropdown">
                                                <a className="nav-link dropdown-toggle" href="#" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                                                    Dropdown
                                                </a>
                                                <ul className="dropdown-menu">
                                                    <li><a className="dropdown-item" href="#">Action</a></li>
                                                    <li><a className="dropdown-item" href="#">Another action</a></li>
                                                    <li><hr className="dropdown-divider" /></li>
                                                    <li><a className="dropdown-item" href="#">Something else here</a></li>
                                                </ul>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </nav> */}
                            <div className='ml-3'>
                                {/* {user.address==null && <a className="d-flex ml-1 mt-2 gap-2 text-danger cursor-pointer"  onClick={GetMapAddress}><Icon.PlusCircle className=''/><p>Please enter your address to view cars in your area.</p></a>} */}
                                {/* {
                                    role != 'admin' && getRequestLoading == false?
                                    (
                                        <>
                                            {
                                                user.address?
                                                (
                                                    <>
                                                    <a className="d-flex ml-1 gap-2 text-dark cursor-pointer updateAddress" onClick={GetMapAddress}>
                                                        <Icon.MapPin className='mapPinIcon'/>
                                                        {user.address}
                                                        <Icon.Edit2 className='editIcon'/>
                                                    </a>
                                                    </>
                                                ):(
                                                    <>        
                                                    <a className="d-flex ml-1 gap-2 text-danger cursor-pointer updateAddress" onClick={GetMapAddress}>
                                                        <Icon.MapPin className='mapPinIcon'/>
                                                        <p className='mb-0'>Please enter your address to view cars in your area.</p>
                                                        <Icon.Edit2 className='editIcon'/>
                                                    </a>
                                                    </>
                                                )
                                            }
                                        </>
                                    ):('')
                                } */}
                            </div>
                            <div className="dropdown userDropdown">
                                <div className="dropdown-toggle" data-bs-toggle="dropdown" aria-expanded="false">
                                    <div>
                                        <span>{user.first_name} {user.last_name}</span>
                                    </div>
                                    <div>
                                        {/* {user.img ? 
                                        <> */}
                                        <div className="avatarSm d-flex">

                                            {
                                                user.first_name?
                                                (
                                                    <img className={"symbol-circle bg-" + user.first_name.slice(0, 1).toUpperCase() + " img-alt"} src={user.avatar} alt={user.first_name.slice(0, 1).toUpperCase()} />
                                                ):(
                                                    user.email?
                                                    (
                                                        <img className={"symbol-circle bg-" + user.email.slice(0, 1).toUpperCase() + " img-alt"} src={user.avatar} alt={user.email.slice(0, 1).toUpperCase()} />
                                                    ):(
                                                        <img className={"symbol-circle bg-" + "N/A" + " img-alt"} src={user.avatar} alt={"N/A"} />
                                                    )
                                                )
                                            }

                                        </div>
                                    </div>
                                </div>
                                <ul className="dropdown-menu">
                                    <li>
                                        <div className='p-3 userInfo'>
                                            {/* <div className='avatar'>
                                                {
                                                    user.avatar ?
                                                    (
                                                    <img
                                                        src={user.avatar}
                                                        alt={user.first_name ? user.first_name : 'avatar'}
                                                    />
                                                    ) : (
                                                    <img
                                                        src="https://bootdey.com/img/Content/avatar/avatar7.png"
                                                        alt="Maxwell Admin"
                                                    />
                                                    )
                                                }
                                            </div> */}
                                            <div className="avatarSm d-flex">

                                                {
                                                    user.first_name?
                                                    (
                                                        <img className={"symbol-circle bg-" + user.first_name.slice(0, 1).toUpperCase() + " img-alt"} src={user.avatar} alt={user.first_name.slice(0, 1).toUpperCase()} />
                                                    ):(
                                                        user.email?
                                                        (
                                                            <img className={"symbol-circle bg-" + user.email.slice(0, 1).toUpperCase() + " img-alt"} src={user.avatar} alt={user.email.slice(0, 1).toUpperCase()} />
                                                        ):(
                                                            <img className={"symbol-circle bg-" + "N/A" + " img-alt"} src={user.avatar} alt={"N/A"} />
                                                        )
                                                    )
                                                }

                                            </div>
                                            <div>
                                                <h6 className='mb-0'>{user.first_name} {user.last_name}</h6>
                                                <span>{user.email}</span>
                                            </div>
                                        </div>
                                    </li>
                                    <li><Link className="dropdown-item" to="/profile"><Icon.User /> <span>Profile</span></Link></li>
                                    <li><Link className="dropdown-item" to="/profile-password"><Icon.Lock /> <span>Change Password</span></Link></li>
                                    <li><a className="dropdown-item" onClick={onLogout} href="/login"><Icon.Power /> <span>Logout</span></a></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div className="content-wrapper">
                        <Outlet />
                    </div>
                </div>
                </>
            )}

            {/* Subscription Modal */}
            <Modal
                backdrop="static"
                centered
                show={showLocationModal}
                onHide={handleLocationModalClose}
                className="modal-md"
            >
                <Modal.Body>
                    <button
                        type="button"
                        className="btnClose"
                        onClick={handleLocationModalClose}
                    >
                        <i className="fa fa-close"></i>
                    </button>
                    <form onSubmit={handleSubmitSaveAddress}>
                        <div className="planA">
                            <h1 className="name text-center">Address Required!</h1>
                            <div className="shortDescription text-center">
                                <p>Enter Your address to find cars in your range.</p>
                            </div>
                            <div className="text-center">
                                {/* <LocationSearchInput/> */}
                                <PlacesAutoComplete address={address} onSelect={handlePlacesAutocompleteSelect} />
                            </div>
                            <div className='text-center mt-3'>
                                <button type="submit" className="btn btn-primary btn-block" disabled={btnLoading || address == ''}>
                                    {
                                        btnLoading ?
                                        (
                                            <>Please Wait <span className="spinner-grow spinner-grow-sm ml-2" role="status" aria-hidden="true"></span></>
                                        ) : (
                                            <>Save Location</>
                                        )
                                    }
                                </button>
                            </div>
                        </div>
                    </form>
                </Modal.Body>
            </Modal>

        </>
    )
}