import React, { useContext, useEffect, useRef, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import axiosClient from "../axios-client";
import Userimg from "../images/man-2.png";
import axios from "axios";
import {
  useTable,
  useFilters,
  useGlobalFilter,
  useSortBy,
  useAsyncDebounce,
  usePagination,
} from "react-table";
import * as Icon from "react-feather";
import { ComponentLoading } from "../Components/ComponentLoading";
import { ToastContainer, toast } from "react-toastify";
import Swal from "sweetalert2";
import { Modal, Button, Nav, Tab, Row, Col } from "react-bootstrap";
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import moment from 'moment';
import Select from 'react-select'
import { ActionMeta, OnChangeValue } from 'react-select';

import logo from '../images/cr-computer.png'

// ** Store Imports
import { useDispatch, useSelector } from 'react-redux'

import {
  stGetIndustriesData, 
  stActionMode, 
  stGetResponse, 
  stGetCompanyData, 
  stFetchCompanySites,
  stFetchCompanyProducts
} from '../store/apps/mainUser'

// Import Components
import CompaniesProductsAddModal from '../PageComponents/CompaniesProductsAddModal';
import CompaniesProductEditModal from '../PageComponents/CompaniesProductEditModal';

//token
const accessToken = localStorage.getItem("ACCESS_TOKEN");

const CompanyDetailsPage = ({refreshEditCompany}) => {
  let { companyId, userType } = useParams();

  // ** Hooks
  const dispatch = useDispatch();
  
  const stMainUser = useSelector((state) => state.stMainUser)

  const [getUsers, setUsers] = useState([]);

  const [getComponentLoading, setComponentLoading] = useState(true);
  const [getEditComponentLoading, setEditComponentLoading] = useState(true);

  // Edit Company Modal
  const [showEditUserModal, setShowEditUserModal] = useState(false);
  const handleEditUserModalClose = () => setShowEditUserModal(false);
  const handleEditUserModalShow = () => setShowEditUserModal(true);

  // Password Modal
  const [showPasswordModal, setShowPasswordModal] = useState(false);
  const handlePasswordModalClose = () => setShowPasswordModal(false);
  const handlePasswordModalShow = () => setShowPasswordModal(true);

  // Product Add Modal
  const [ProductAddModal, setProductAddModal] = useState(false);
  const handleProductAddModalClose = () => setProductAddModal(false);

  // Product Edit Modal
  const [productEditModal, setproductEditModal] = useState(false);
  const handleProductEditModalClose = () => setproductEditModal(false);

  const handleProductAddModal = () => {
    setProductAddModal(true)
    setUserId(null);
  };

  const handleProductEditModalShow = (data) => {
    setproductEditModal(true)
    setProductId(data.id);
  };

  const [newPasswordType, setNewPasswordType] = useState(false);
  const [confirmPasswordType, setConfirmPasswordType] = useState(false)

  const [btnLoading, setBtnLoading] = useState(false)
  
  const [getCompanyId, setCompanyId] = useState(null);
  const [getProductId, setProductId] = useState(null);
  const [getUserId, setUserId] = useState(null);
  const [getUserData, setUserData] = useState({});
  
  const [getUserEmail, setUserEmail] = useState("");
  const [getUserFirstName, setUserFirstName] = useState("");
  const [getUserLastName, setUserLastName] = useState("");
  const [getUserPassword, setUserPassword] = useState("");
  const [getUserConfirmPassword, setUserConfirmPassword] = useState("");

  const companyProductsRequest = () => {
    if(stMainUser.stFetchCompanyProducts.company_products){
        setUsers(stMainUser.stFetchCompanyProducts.company_products);
        setComponentLoading(false);
    }

    if(stMainUser.stCompanyId){
        setCompanyId(stMainUser.stCompanyId)
    }
  }

  const deleteBtn = async (data) => {
    Swal.fire({
      title: "Are you sure want to delete?",
      icon: "question",
      confirmButtonColor: "#f32013",
      cancelButtonColor: "#d33",
      confirmButtonText: "Delete",
      showCancelButton: true,
    }).then((result) => {
      if (result.isConfirmed) {
        setComponentLoading(true)
        let payload = {
          id: data.id,
          status: "deleted",
        };
        // console.log('data=',data)
        axiosClient.delete("/company-products/"+companyId+"/"+data.id, payload).then((res) => {
            companyProductsRequest()
            toast.success(res.data.message, {
                position: "bottom-right",
                autoClose: 1500,
                theme: "colored",
            });
            refreshEditCompany()
            setComponentLoading(false)
      });
      }
    });
  };

  function GlobalFilter(props) {
    const { setGlobalFilter } = props;
    const count = props.preGlobalFilteredRows.length;
    const [value, setValue] = React.useState(props.globalFilter);
    const onChange = useAsyncDebounce((value) => {
      setGlobalFilter(value || undefined);
    }, 200);
    return (
      <div className="d-flex justify-content-between">
      <div className="pt-3 pl-3 pr-3 pb-3">
        <div className="card-toolbar">
          <div
            className="d-flex justify-content-end"
            data-kt-customer-table-toolbar="base"
          >
            <div className="customTableSearch d-flex align-items-center position-relative">
              <span className="icon">
                <Icon.Search />
              </span>
              <input
                type="text"
                data-kt-user-table-filter="search"
                className="form-control"
                placeholder="Search"
                value={value || ""}
                onChange={(e) => {
                  setValue(e.target.value);
                  onChange(e.target.value);
                }}
              />
            </div>
            <Button variant="btn btn-primary ml-3 pl-10 pr-10" onClick={handleProductAddModal}>
              Add New Product
            </Button>
          </div>
        </div>
      </div>
        <div className="pt-3 pl-3 pr-3 pb-3">
          <div className="card-toolbar">
            <div
              className="d-flex justify-content-center m-auto"
              data-kt-customer-table-toolbar="base"
            >
              {/* <div className="form-control d-flex justify-content-center align-items-center position-relative">
                <select
                  className="form-select form-select-sm form-select-solid px-3 m-auto dropDownRemove"
                  onChange={(e) => {
                    setGlobalFilter(e.target.value || undefined);
                  }}
                >
                  <option className="icon" value="">
                    All
                  </option>
                  <option value="active">Active</option>
                  <option value="pending">Pending</option>
                  <option value="disabled">Disable</option>
                </select>
              </div> */}
            </div>
          </div>
        </div>
      </div>
    );
  }

  function DefaultColumnFilter({
    column: { filterValue, preFilteredRows, setFilter },
  }) {
    const count = preFilteredRows.length;
    return (
      <input
        type="text"
        data-kt-user-table-filter="search"
        className="customTableSearch form-control form-control-solid w-250px ps-14"
        value={filterValue || ""}
        onChange={(e) => {
          setFilter(e.target.value || undefined);
        }}
        placeholder={`Search ${count} records...`}
      />
    );
  }

  function Table(props) {
    const { columns, data } = props;
    const defaultColumn = React.useMemo(
      () => ({
        // Default Filter UI
        Filter: DefaultColumnFilter,
      }),
      []
    );
    const setLocalStorage = (pageSize) => {
      // console.log('pageSize',pageSize)
      localStorage.setItem("pageSize", pageSize);
    };

    const {
      getTableProps,
      getTableBodyProps,
      headerGroups,
      rows,
      prepareRow,
      // state,
      preGlobalFilteredRows,
      setGlobalFilter,
      page,
      // canPreviousPage,
      // canNextPage,
      pageOptions,
      pageCount,
      gotoPage,
      nextPage,
      previousPage,
      setPageSize,
      state: { pageIndex, pageSize, globalFilter },
    } = useTable(
      {
        columns,
        data,
        defaultColumn,
        initialState: {
          pageIndex: 0,
          pageSize: localStorage.getItem("pageSize")
            ? Number(localStorage.getItem("pageSize"))
            : 10,
        },
      },
      useFilters,
      useGlobalFilter,
      useSortBy,
      usePagination
    );
    let currentPageStartIndex = 2;
    let previousIndexPage = 0;
    return (
      <div>
        <GlobalFilter
          preGlobalFilteredRows={preGlobalFilteredRows}
          globalFilter={globalFilter}
          setGlobalFilter={setGlobalFilter}
        />
        <div className="table-responsive">
          <table
            className="table table-striped dataTable mb-0"
            {...getTableProps()}
          >
            {headerGroups.map((headerGroup) => (
              <tr
                className="tableHeader"
                {...headerGroup.getHeaderGroupProps()}
              >
                {headerGroup.headers.map((column) => (
                  // Add the sorting props to control sorting. For this example
                  // we can add them into the header props
                  <th
                    className=""
                    {...column.getHeaderProps(column.getSortByToggleProps())}
                  >
                    {column.render("Header")}
                    {/* Add a sort direction indicator */}
                    <span>
                      {column.isSorted ? (
                        column.isSortedDesc ? (
                          <span className="table-sort-asc"></span>
                        ) : (
                          <span className="table-sort-desc"></span>
                        )
                      ) : (
                        ""
                      )}
                    </span>
                  </th>
                ))}
              </tr>
            ))}
            <tbody className="" {...getTableBodyProps()}>
              {page.length > 0 ? (
                <>
                  {page.map((row, i) => {
                    prepareRow(row);
                    return (
                      <tr {...row.getRowProps()}>
                        {row.cells.map((cell) => {
                          return (
                            <td {...cell.getCellProps()}>
                              {cell.render("Cell")}
                            </td>
                          );
                        })}
                      </tr>
                    );
                  })}
                </>
              ) : (
                <tr>
                  <td colSpan={7}>
                    <div className="card-body text-center bg-light tableDataNotFound">
                      No data found
                    </div>
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
        <div className="pl-3 pr-3">
          <div className="row">
            <div className="mt-3 mb-3 col-sm-5 d-flex align-items-center justify-content-center justify-content-sm-start">
              <div className="dataTables_length" id="kt_customers_table_length">
                <select
                  name="kt_customers_table_length"
                  className="form-select form-select-sm form-select-solid"
                  value={pageSize}
                  onChange={(e) => {
                    {
                      setPageSize(Number(e.target.value));
                      setLocalStorage(e.target.value);
                    }
                  }}
                  style={{ width: "120px", height: "38px" }}
                >
                  {[10, 20, 30, 40, 50].map((pageSize) => (
                    <option key={pageSize} value={pageSize}>
                      {pageSize}
                    </option>
                  ))}
                </select>
              </div>
            </div>
            <div className="mt-3 mb-3 col-sm-7 d-flex align-items-center justify-content-center justify-content-sm-end">
              <div id="kt_table_users_paginate">
                <ul className="pagination mb-0">
                  <li className="page-item" onClick={() => previousPage()}>
                    <a className="page-link prev-angle">
                      <i className="fa fa-chevron-left"></i>
                    </a>
                  </li>
                  {pageOptions.map((pageSize, rowIndex) => {
                    if (currentPageStartIndex <= rowIndex) {
                      currentPageStartIndex = currentPageStartIndex + 1;
                      previousIndexPage = rowIndex - 1;
                    }
                    return previousIndexPage <= currentPageStartIndex ? (
                      <li
                        className={`page-item ${pageIndex == 0 && rowIndex == 0
                          ? "active"
                          : pageIndex + 1 == rowIndex + 1
                            ? "active"
                            : ""
                          }`}
                        onClick={() => gotoPage(rowIndex)}
                      >
                        <a className="page-link">{pageSize + 1} </a>
                        {/* {pageIndex + 1} */}
                      </li>
                    ) : null;
                  })}
                  <li className="page-item" onClick={() => nextPage()}>
                    <a className="page-link next-angle">
                      {/* {'Next »'} */}
                      <i className="fa fa-chevron-right"></i>
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }

  useEffect(() => {
    if(companyId){
      setCompanyId(companyId)
    }
  }, [companyId, userType]);

  useEffect(() => {
      if(companyId){
          dispatch(stFetchCompanyProducts({companyId}));
      }
  }, [dispatch, companyId])

  useEffect(() => {
    if(companyId){
      companyProductsRequest()
    }
  }, [companyId,stMainUser]);
  
  const columns = React.useMemo(
    () => [
      {
        Header: "Product / Service",
        accessor: "name",
        Cell: ({ row }) => {
          const data = row.original;
          return data.name;
        },
      },
      {
        Header: "Vendor",
        accessor: "vendor",
        Cell: ({ row }) => {
          const data = row.original;
          return data.vendor;
        },
      },
      {
        Header: "Users",
        accessor: "users",
        Cell: ({ row }) => {
          const data = row.original;
          return data.users;
        },
      },
      {
        Header: "Location Hosted",
        accessor: "location_hosted",
        Cell: ({ row }) => {
          const data = row.original;
          return data.location_hosted;
        },
      },
      {
        Header: "Vendor Contact",
        accessor: "vendor_contact",
        Cell: ({ row }) => {
          const data = row.original;
          return data.vendor_contact;
        },
      },
      {
        Header: "Vendor Phone",
        accessor: "vendor_phone",
        Cell: ({ row }) => {
          const data = row.original;
          return data.vendor_phone;
        },
      },
      {
        Header: "Action",
        accessor: "action",
        disableSortBy: true,
        Cell: ({ row }) => {
          const data = row.original;
          return (
            <>
              <div className="actionButtons">
                <div class="dropdown">
                  <button class="btn btn-secondary dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                    <Icon.MoreVertical />
                  </button>
                  <ul class="dropdown-menu">
                    <li>
                      <button
                        className="dropdown-item"
                        aria-expanded="false"
                        onClick={() => {
                            handleProductEditModalShow(data)
                        }}
                      >
                        <span className="btn btn-primary btnIcon">
                          <Icon.Edit />
                        </span>
                        Edit
                      </button>
                    </li>
                    <li>
                      <button
                        className="dropdown-item"
                        aria-expanded="false"
                        onClick={() => {
                            deleteBtn(data)
                        }}
                      >
                        <span className="btn btn-danger btnIcon">
                          <Icon.Trash />
                        </span>
                        Delete
                      </button>
                    </li>
                  </ul>
                </div>
              </div>
            </>
          );
        },
      },
    ],
    []
  );

  const data = getUsers;

  return (
    <>
      {/* Add Product Modal */}
      {
        ProductAddModal && (
          <CompaniesProductsAddModal
                prop_refreshEditCompany={refreshEditCompany}
                prop_companyProductsRequest={companyProductsRequest}
                prop_ProductAddModal={ProductAddModal}
                prop_handleProductAddModalClose={handleProductAddModalClose}
                prop_companyId={companyId}
          ></CompaniesProductsAddModal>
        )
      }
      
      {/* Edit Product Modal */}
      {
        productEditModal && (
          <CompaniesProductEditModal
                prop_refreshEditCompany={refreshEditCompany}
                prop_companyProductsRequest={companyProductsRequest}
                prop_ProductEditModal={productEditModal}
                prop_handleProductEditModalClose={handleProductEditModalClose}
                prop_ProductId={getProductId}
          ></CompaniesProductEditModal>
        )
      }

      {/* Page Content */}
      {getComponentLoading ? (
        <div className="card loadingCard">
          <div className="card-body">
            <ComponentLoading></ComponentLoading>
          </div>
        </div>
      ) : (
        <div className="card customCard">
          <Table columns={columns} data={data} />
        </div>
      )}
    </>
  );
};

export default CompanyDetailsPage;
