import { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { useLocation, useNavigate } from "react-router-dom";
import axios from "axios";
import axiosClient from "../axios-client";
import { Icons, ToastContainer, toast } from "react-toastify";
import * as Icon from "react-feather";

import PlacesAutoComplete from '../Components/PlacesAutocomplete';

const Signup = () => {

  
  //regex to make sure the employee's name is above 3 letters
  const USER_REGEX = /^\[A-z\][A-z0-9-_]{3,23}$/;

  //to check if the employees password meets certain criterias
  const PWD_REGEX = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%]).{8,24}$/;

  const navigate = useNavigate();
  const location = useLocation();
  const from = location.state?.from?.pathname || "/signin";
  const [name, setName] = useState("");
  const [validName, setValidName] = useState(false);
  //state to store our password 
  const [password, setPassword] = useState("");
  const [confirm_password, setConfirm_password] = useState("")
  
  const [passwordType, setPasswordType] = useState(false);
  const [confirmPasswordType, setConfirmPasswordType] = useState(false)

  //state to store the outcome of our regex test
  const [validPass, setValidPass] = useState(false);

  //state to store the employee's email
  const [email, setEmail] = useState("");
  //state to store the employee's roles
  const [roles, setRoles] = useState("");
  const [error, setError] = useState("");

  //state to store the employee's email
  const [address, setAddress] = useState("");
  const [latitude, setLatitude] = useState("");
  const [longitude, setLongitude] = useState("");
  const [btnLoading, setBtnLoading] = useState(false)

  const handlePlacesAutocompleteSelect = (latitude, longitude, address) => {
    // set the state of the Signup component with the latLng and address values
    setLatitude(latitude);
    setLongitude(longitude);
    setAddress(address);
  };

  let match;
  const REGISTER_URL = `/register`;

  useEffect(() => {
    //check if the employees name is valid
    setValidName(USER_REGEX.test(name));
  }, [name]);

  useEffect(() => {
    // check if the password is valid
    setValidPass(PWD_REGEX.test(password));
  }, [password]);

  const handleSubmit = (e) => {
    setBtnLoading(true)
    e.preventDefault();
    const payload = {
      role: 'dealer',
      name: name,
      email: email,
      password: password,
      confirm_password: confirm_password,
      address: address,
      latitude: latitude,
      longitude: longitude,
    }
    if ({ handleSubmit })
      try {
        axiosClient.post('/register', payload)
          .then((response) => {
            setBtnLoading(false)
            // response.json({ message: 'Request received!', response })
            toast.success(response.data.message, {
              position: "bottom-right",
              autoClose: 1500,
              theme: "colored",
            });
            setName("");
            setEmail("");
            setPassword("");
            setConfirm_password("");
            navigate("/verify/"+response.data.verification.token)
          })
          .catch(err => {
            setBtnLoading(false)
            var firstErrorMessage = '';
            if (err.response.data.errors && Object.keys(err.response.data.errors).length != 0) {
              var errors = err.response.data.errors
              const errorMessages = Object.values(errors); // Get an array of all error messages
              firstErrorMessage = errorMessages.shift(); // Get the first error message
              toast.error(firstErrorMessage[0], {
                position: "bottom-right",
                autoClose: 1500,
                theme: "colored",
              });
            } else {
              // console.log('firstErrorMessage',firstErrorMessage[0])
              toast.error(err.response.data.message, {
                position: "bottom-right",
                autoClose: 1500,
                theme: "colored",
              });
            }
            // console.log(err);
          });

        // to empty the input fields following code is written

      } catch (err) {
        setBtnLoading(false)
        if (!err?.response) {
          alert("no server response");
        } else if (err?.response?.status === 422) {
          alert("employee already exist");
          setError = err?.response?.status;
        } else {
          alert("registration failed");
        }
      }
  }


  return (
    <div>

      <div className="container darkMode">
        <div className="centerForm">
          <div className="authForm box-shadow-1 mb-4">
            <form onSubmit={handleSubmit} autoComplete="off">

              <h4 className="mt-1 text-white">Register</h4>
              <p className="text-white">Welcome to CR Computers</p>

              {error && <span>error accoured</span>}

              <div className="form-outline mb-3">
                <input type="text" className="form-control w-100" placeholder="Full Name" aria-label="Username" aria-describedby="addon-wrapping" value={name} onChange={e => setName(e.target.value)} />
              </div>

              <div className="form-outline mb-3">
                <input type="text" className="form-control" placeholder="Email" aria-label="" aria-describedby="addon-wrapping" value={email} onChange={e => setEmail(e.target.value)} />
              </div>

              {/* <div className="form-outline mb-3">
                <input type="text" className="form-control" placeholder="Address" aria-label="Username" aria-describedby="addon-wrapping" value={address} onChange={e => setAddress(e.target.value)} />
              </div> */}

              <div className="form-outline mb-3">
                <div className="inputIcon">
                  <input type={passwordType?("text"):("password")} className="form-control" placeholder="Password" aria-label="Username" aria-describedby="addon-wrapping" value={password} onChange={e => setPassword(e.target.value)} />
                  <div className="inputIcons">
                    {
                      passwordType?
                      (
                        <span onClick={() => {setPasswordType(false)}} className="eyeOff"><Icon.EyeOff /></span>
                      ):(
                        <span onClick={() => {setPasswordType(true)}} className="eye"><Icon.Eye /></span>
                      )
                    }
                  </div>
                </div>
              </div>

              <div className="form-outline mb-3">
                <div className="inputIcon">
                  <input type={confirmPasswordType?("text"):("password")} className="form-control" placeholder="Confirm Password" aria-label="Username" aria-describedby="addon-wrapping" value={confirm_password} onChange={e => setConfirm_password(e.target.value)} />
                  <div className="inputIcons">
                    {
                      confirmPasswordType?
                      (
                        <span onClick={() => {setConfirmPasswordType(false)}} className="eyeOff"><Icon.EyeOff /></span>
                      ):(
                        <span onClick={() => {setConfirmPasswordType(true)}} className="eye"><Icon.Eye /></span>
                      )
                    }
                  </div>
                </div>
              </div>

              <div className="form-outline mb-3">
                
                <PlacesAutoComplete onSelect={handlePlacesAutocompleteSelect} />

              </div>

              <div className="d-grid">
                <button type="submit" className="btn btn-primary btn-block" disabled={btnLoading}>
                  {
                    btnLoading ?
                      (
                        <>Please Wait <span className="spinner-grow spinner-grow-sm ml-2" role="status" aria-hidden="true"></span></>
                      ) : (
                        <>Register</>
                      )
                  }
                </button>
              </div>

            </form>
          </div>
          <div className="text-center">
            <p className="text-white">
              <span>Already registered? </span>
              <Link to="/login" className="ml-2">Login</Link>
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Signup;