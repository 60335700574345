// import '../CSS/dealer.css'

import React, { useContext, useEffect, useState } from "react";
import { useNavigate, Link } from 'react-router-dom'
import { useStateContext } from "../Context/ContextProvider";
import List from "./List";
import Companies from '../Pages/Companies'
import axiosClient from "../axios-client";
import * as Icon from "react-feather"
import axios from "axios";
import { ComponentLoading } from "../Components/ComponentLoading";

// import CountUp from 'react-countup';

import PalentiCarLogo from '../images/cr-computer.png'

// Import Components
import CompaniesEmploymentEditModal from '../PageComponents/CompaniesEmploymentEditModal';
import CompaniesEmploymentViewModal from '../PageComponents/CompaniesEmploymentViewModal';

//token
const accessToken = localStorage.getItem("ACCESS_TOKEN");

const Index = () => {

  const { user, role, notification } = useStateContext();

  const navigate = useNavigate();

  const [dashData, setDashData]= useState([]);
  const [isLoading, setIsLoading]=useState(true)
  const [getRole, setRole]=useState(null)

  // Edit Employee Modal
  const [employeeEditModal, setEmployeeEditModal] = useState(false);
  const handleEmployeeModalEditClose = () => setEmployeeEditModal(false);
  
  // View Employee Modal
  const [employeeViewModal, setEmployeeViewModal] = useState(false);
  const handleEmployeeModalViewClose = () => setEmployeeViewModal(false);

  const [get_user_id, set_user_id] = useState(null);

  const companyEmployeesRequest = () => {}
  const handleEmployeeEditModalShow = () => {
    setEmployeeEditModal(true)
  };

  const handleEmployeeViewModalShow = (data) => {
    setEmployeeViewModal(true)
  };

  // const getUser = () => {
  //   axiosClient.get('/profile')
  //     .then(({ data }) => {
  //       setRole(data.role)
  //     })
  //     .catch(err => {
  //     });
  // }
  
  // const getDashData =()=>{
  //   setIsLoading(true)
  //   axiosClient.post('dashData')
  //   .then(({ data })=>{
  //     setDashData(data)
  //     setIsLoading(false)
  //     console.log('role=', role)
  //     // if(role == 'dealer'){
  //     //   navigate('cars')
  //     // }
  //   }).catch(error=>{
  //     console.log('error occurd')
  //   })
  // }

  const getDashData = () => {
    setIsLoading(true)
    let config = {
      method: "post",
      url: process.env.REACT_APP_API_URL + "/dashData",
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${accessToken}`,
      },
    };

    axios(config)
    .then(({data}) => {
      setDashData(data)
      setIsLoading(false)
      console.log('user role=', role)
    })
    .catch((error) => {
      setIsLoading(false)
    });
  };

  useEffect(()=>{
    // getUser();
    if(role == 'power_user'){
      navigate('/companies/'+user.company_id)
      // navigate('/company')
    }
    if(role == 'admin'){
      getDashData();
    } else {
      setIsLoading(false)
    }
  },[role, user])

  return (
    <>
    {isLoading ?
      (
        <>
          <div className="card loadingCard">
            <div className="card-body">
              <ComponentLoading></ComponentLoading>
            </div>
          </div>
        </>
      ):(
        <>
        {
          role=='admin'&&(
            <>
              <div className="container-fluid mt-4">
                <section id="minimal-statistics">
                  <div className="row">
                      <div className="col-xl-3 col-sm-6 col-12">
                        <div className="card dashboardCard">
                          <Link to='/companies' className="card-body">
                            <div className="cardContent">
                              <div>
                                <span className="count">
                                  {dashData.companies ? (
                                    <>{dashData.companies}</>
                                  ) : (
                                    <>0</>
                                  )}
                                </span>
                                <p>Total Companies</p>
                              </div>
                              <div className="icon primaryIcon">
                                <Icon.Users />
                              </div>
                            </div>
                          </Link>
                        </div>
                      </div>
                      {/* <div className="col-xl-3 col-sm-6 col-12">
                        <div className="card dashCard">
                          <div className="card-content">
                            <div className="card-body">
                              <div className="d-flex justify-content-between">
                                <div className="align-self-center dashIcon bgcolorBlueDark">
                                  <Icon.Users />
                                </div>
                                <Icon.Users className="bgdashCard colorBlueDark" />

                                <div className="media-body text-right colorBlueDark">
                                  <h1 className="dashCardHeading">
                                    {dashData.companies ? (
                                      <>{dashData.companies}</>
                                    ) : (
                                      <>0</>
                                    )}
                                  </h1>
                                  <span className="dashCardSpan">Companies</span>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div> */}
                      {/* <div className="col-xl-3 col-sm-6 col-12">
                        <div className="card dashCard">
                          <div className="card-content">
                            <div className="card-body">
                              <div className="d-flex justify-content-between">
                                <div className="align-self-center dashIcon bgcolorBlueDark">
                                  <Icon.Users />
                                </div>
                                <Icon.Users className="bgdashCard colorBlueDark" />

                                <div className="media-body text-right colorBlueDark">
                                  <h1 className="dashCardHeading">
                                    {dashData.customers ? dashData.customers : <>0</>}
                                  </h1>
                                  <span className="dashCardSpan">
                                    Active Customers
                                  </span>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-xl-3 col-sm-6 col-12">
                        <div className="card dashCard">
                          <div className="card-content">
                            <div className="card-body">
                              <div className="d-flex justify-content-between">
                                <div className="align-self-center dashIcon bgcolorBlueDark">
                                  <Icon.BarChart2 />
                                </div>
                                <Icon.BarChart2 className="bgdashCard colorBlueDark" />

                                <div className="media-body text-right colorBlueDark">
                                  <h1 className="dashCardHeading">
                                    {dashData.cars ? dashData.cars : <>0</>}
                                  </h1>

                                  <span className="dashCardSpan">Active Cars</span>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-xl-3 col-sm-6 col-12">
                        <div className="card dashCard">
                          <div className="card-content">
                            <div className="card-body">
                              <div className="d-flex justify-content-between">
                                <div className="align-self-center dashIcon bgcolorBlueDark">
                                  <Icon.CheckCircle />
                                </div>
                                <Icon.CheckCircle className="bgdashCard colorBlueDark" />

                                <div className="media-body text-right colorBlueDark">
                                  <h1 className="dashCardHeading">
                                    {dashData.offers ? dashData.offers : <>0</>}
                                  </h1>
                                  <span className="dashCardSpan">Active Offers</span>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div> */}
                  </div>
                </section>
              </div>
            </>
          )
        }
        {
          role=='employee'&&(
            <>
            <div className="row mt-2">
              <div className="col-sm-5">
                <div className="card empDashboardCard">
                  <div className="card-body">
                    <h4>My Employment</h4>
                    <p>Easily view and update your employment information for accurate records and seamless communication with your employer.</p>
                    <div className="buttons">
                      <button className="btn btn-primary btn-sm" onClick={handleEmployeeViewModalShow}>
                        <Icon.Eye width={18} style={{marginRight: '5px'}} />
                        View
                      </button>
                      <button className="btn btn-primary btn-sm" onClick={handleEmployeeEditModalShow}>
                        <Icon.Edit width={18} style={{marginRight: '5px'}} />
                        Edit
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <CompaniesEmploymentEditModal
              prop_companyEmployeesRequest={companyEmployeesRequest}
              prop_employeeEditModal={employeeEditModal}
              prop_handleEmployeeModalEditClose={handleEmployeeModalEditClose}
              prop_user_id={get_user_id}
            ></CompaniesEmploymentEditModal>
      
            {/* View Employee Modal */}
            <CompaniesEmploymentViewModal
              prop_companyEmployeesRequest={companyEmployeesRequest}
              prop_employeeViewModal={employeeViewModal}
              prop_handleEmployeeModalViewClose={handleEmployeeModalViewClose}
              prop_user_id={get_user_id}
            ></CompaniesEmploymentViewModal>

            </>
          )
        }
        {/* {
          role=='power_user'&&(
            <>
              <Companies/>
            </>
          )
        } */}
        </>
      )
    }
    </>
  )
}

export default Index