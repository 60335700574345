import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css'
import {RouterProvider} from "react-router-dom";
import router from "./Assets/router.js";
import {ContextProvider} from "./Context/ContextProvider";
import 'react-toastify/dist/ReactToastify.css';
import { ToastContainer } from 'react-toastify';

import { Provider } from 'react-redux';
import { store } from './store/index';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <Provider store={store}>
      <ContextProvider>
        <ToastContainer />
        <RouterProvider router={router}/>
      </ContextProvider>
    </Provider>
  </React.StrictMode>
);
