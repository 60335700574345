import React, { useState, useEffect } from 'react';

import { NavLink, Link, useNavigate, useParams } from 'react-router-dom';
import { Navigate } from "react-router-dom";

import axiosClient from '../axios-client';
import { useStateContext } from "../Context/ContextProvider";
import * as Icon from 'react-feather';
import axios from "axios";
import { ComponentLoading } from "../Components/ComponentLoading";

import PalentiCarLogo from '../images/cr-computer.png'

// Import Components
import CompaniesEmploymentEditModal from '../PageComponents/CompaniesEmploymentEditModal';

// ** Store Imports
import { useDispatch, useSelector } from 'react-redux'

// ** Types Imports
import { RootState, AppDispatch } from "../store"

// ** Actions Imports
import {
    stGetIndustriesData, 
    stActionMode, 
    stGetResponse, 
    stGetCompanyData, 
    stFetchCompanySites,
    stCompanyId
} from '../store/apps/mainUser'

//token
const accessToken = localStorage.getItem("ACCESS_TOKEN");

const SidebarMenu = (props) => {

    // ** Hooks
    const dispatch = useDispatch();
    
    const stMainUser = useSelector((state) => state.stMainUser)

    let { companyId, userType } = useParams();

    // Access the getUserRole function from props
    const { getUserRole, getIsLoading } = props;

    //up state in the default function it is also added
    const { user, token, role, setUser, setToken, setRole, notification, company } = useStateContext();

    const [isMenuFixed, setIsMenuFixed] = useState(true);
    // const [get_user_id, set_user_id] = useState(null);
    const [get_company_id, set_company_id] = useState(null);
    const [getComponentLoading, setComponentLoading] = useState(true);
    const [getCompanySites, setCompanySites] = useState([]);

    // Employee Modal
    const [employeeEditModal, setEmployeeEditModal] = useState(false);
    const handleEmployeeModalEditClose = () => setEmployeeEditModal(false);

    const handleResize = () => {
        const bodyElement = document.body;
        if (window.innerWidth < 1024) {
            setIsMenuFixed(false);
            bodyElement.classList.remove('layout-menu-fixed');
            bodyElement.classList.add('device');
        } else {
            setIsMenuFixed(true);
            bodyElement.classList.add('layout-menu-fixed');
            bodyElement.classList.remove('device');
        }
    }
    const resize = () => {
    
        window.addEventListener('resize', handleResize);
    
        // Remove event listener on component unmount
        return () => window.removeEventListener('resize', handleResize);
    }

    const toggleMenu = () => {
        setIsMenuFixed(!isMenuFixed);
        // document.body.classList.remove('layout-menu-fixed');
        const bodyElement = document.body;
        if (isMenuFixed) {
            bodyElement.classList.remove('layout-menu-fixed');
        } else {
            bodyElement.classList.add('layout-menu-fixed');
        }
    }

    const closeMenu = () => {
        setIsMenuFixed(false);
    }

    const onLogout = (e) => {
        e.preventDefault()

        axiosClient.post('/logout')
            .then(() => {
                setUser({})
                setToken(null)
            })
    }

    const companySitesRequest = () => {
        // setComponentLoading(true);
        // let config = {
        //     method: "get",
        //     url: process.env.REACT_APP_API_URL + "/company-sites/"+dataId,
        //     headers: {
        //         Accept: "application/json",
        //         Authorization: `Bearer ${accessToken}`,
        //     },
        // }
  
        // axios(config)
        //     .then((response) => {
        //         setCompanySites(response.data.company_sites);
        //         setComponentLoading(false);
        //     })
        //     .catch((error) => {
        //         setComponentLoading(false);
        //     });
        
        // console.log('stMainUser.stFetchCompanySites=',stMainUser.stFetchCompanySites.company_sites)
        if(stMainUser.stFetchCompanySites.company_sites){
            setCompanySites(stMainUser.stFetchCompanySites.company_sites);
            setComponentLoading(false);
        }
    };

    const clickCompanySiteBtn = () => {
        // Navigate('company')
        // alert('clickCompanySiteBtn')
        if(userType == 'employees' || userType == 'power-users' || userType == 'products'){
            const link = document.querySelector('.dropdown-toggle');
            link.classList.remove('active');
        } else {
            // Get the innerDropdown element
            const innerDropdown = document.querySelector('.innerDropdown');
    
            if(innerDropdown && innerDropdown.classList.contains('show')){
                const link = document.querySelector('.dropdown-toggle');
                // Add a custom class to the link element
                if (link) {
                    link.classList.add('active');
                } else {
                    link.classList.remove('active');
                }
            }
        }
    }

    const handleLinkClick = () => {
        // Scroll to the top of the page
        window.scrollTo(0, 0);
    };


    useEffect(() => {
        clickCompanySiteBtn()
    },[clickCompanySiteBtn])


    // useEffect(() => {
    //     if(companyId){
    //         set_company_id(companyId)
    //         companySitesRequest()
    //     } else {
    //         set_company_id(null)
    //         setCompanySites([])
    //     }
    // },[companyId])

    useEffect(() => {
        if(companyId){
            set_company_id(companyId)
            companySitesRequest()
        } else {
            set_company_id(null)
            setCompanySites([])
        }
    }, [role,stMainUser]);

    useEffect(() => {
        // console.log('getUserRole====111getRequestLoading',getUserRole,getIsLoading)
        window.addEventListener('load', handleResize);
        resize()
    }, [getUserRole,getIsLoading])

    // useEffect(() => {
    //     // console.log('user=',user)
    //     if(user){
    //         set_user_id(user.id)
    //     }
    // }, [get_user_id])

    useEffect(() => {
        if(companyId){
            dispatch(stGetCompanyData({companyId}));
            dispatch(stFetchCompanySites({companyId}));
            dispatch(stCompanyId({companyId}));
        } 
        // else {
        //     set_company_id(null)
        //     setCompanySites([])
        // }
    }, [dispatch, companyId])


    const companyEmployeesRequest = () => {}
    const handleEmployeeEditModalShow = () => {
        setEmployeeEditModal(true)
    };

    return (
        <>
        <div className={`layout-menu ${isMenuFixed ? 'open' : ''}`}>
            <NavLink to="/" className="sidebarLogo" onClick={() => {handleLinkClick()}}>
                    <img src={PalentiCarLogo} alt="Logo" className="logo" />
            </NavLink>
            <button className="menu-toggle" onClick={toggleMenu}>
                <i className={`fa ${isMenuFixed ? 'fa-arrow-left' : 'fa-arrow-right'}`}></i>
            </button>

            <ul class="cSidebarMenu menu-items" id="menu">

                {
                    getIsLoading != true?
                    (
                        <>
                        {role == 'admin' && (
                            <>
                            <li className="menu-item">
                                <NavLink to="/" onClick={() => {handleLinkClick()}}>
                                    <Icon.Home /> Dashboard
                                </NavLink>
                            </li>
                            </>
                        )}
                        {role == 'admin' || role == 'power_user' ?
                            (
                                <>
                                {
                                    companyId ? (
                                        <>
                                        <li className="menu-item">
                                            <a onClick={() => {clickCompanySiteBtn(); handleLinkClick()}} href="#submenu1" data-bs-toggle="collapse" className={`dropdown-toggle ${userType != 'employees' && userType != 'power-users' && userType != 'products' ? "" : ""}`}>
                                                <Icon.Users />
                                                <span class="ms-1 d-none d-sm-inline">Company Sites</span>
                                            </a>
                                            <ul className={`innerDropdown collapse ${userType != 'employees' && userType != 'power-users' && userType != 'products' ? "show" : ""}`} id="submenu1" data-bs-parent="#menu">
                                            {
                                                getCompanySites.length > 0 && (
                                                    getCompanySites.map((item) => (
                                                        <>
                                                        <li className="menu-item">
                                                            {
                                                                item.name == 'Main' && (userType != 'employees' || userType != 'power-users')?
                                                                (
                                                                    <Link to={"/companies/"+companyId} className={'active'} onClick={() => {handleLinkClick()}}>
                                                                        <Icon.Minus /> {item.name}
                                                                    </Link>
                                                                ):(
                                                                    <Link to={"/companies/"+companyId} onClick={() => {handleLinkClick()}}>
                                                                        <Icon.Minus /> {item.name}
                                                                    </Link>
                                                                )
                                                            }
                                                        </li>
                                                        </>
                                                    ))
                                                )
                                            }
                                            </ul>
                                        </li>
                                        <li className="menu-item">
                                            <NavLink to={"/companies/"+get_company_id+'/employees'} onClick={() => {handleLinkClick()}}>
                                                <Icon.Users /> Employees
                                            </NavLink>
                                        </li>
                                        <li className="menu-item">
                                            <NavLink to={"/companies/"+get_company_id+'/products'} onClick={() => {handleLinkClick()}}>
                                                <Icon.Users /> Products
                                            </NavLink>
                                        </li>
                                        {
                                            role == 'admin' && (
                                                <li className="menu-item">
                                                    <NavLink to={"/companies/"+get_company_id+'/power-users'} onClick={() => {handleLinkClick()}}>
                                                        <Icon.Users /> Power Users
                                                    </NavLink>
                                                </li>
                                            )
                                        }
                                        </>
                                    ):(
                                        <li className="menu-item">
                                            <NavLink to="/companies" onClick={() => {handleLinkClick()}}>
                                                <Icon.Users /> Companies
                                            </NavLink>
                                        </li>
                                    )
                                }
                                </>
                            ):('')
                        }

                        {role == 'employee' ?
                            (
                                <>
                                {/* <ul className="menu-items"> */}
                                    <li className="menu-item">
                                        <NavLink to="/" onClick={() => {handleLinkClick()}}>
                                            <Icon.Home /> {user.company_name}
                                        </NavLink>
                                    </li>
                                    {/* <li className="menu-item">
                                        <NavLink onClick={handleEmployeeEditModalShow}>
                                            <Icon.User /> Employment
                                        </NavLink>
                                    </li> */}
                                {/* </ul> */}
                                </>
                            ):('')
                        }

                        </>
                    ):('')
                }
                
                <li className="menu-item mt-5" onClick={onLogout} >
                    <NavLink to="/login" onClick={() => {handleLinkClick()}}>
                        <Icon.Power /> Logout
                    </NavLink>
                </li>

            </ul>

        </div>
        
        {/* Edit Employee Modal */}
        {/* <CompaniesEmploymentEditModal
            prop_companyEmployeesRequest={companyEmployeesRequest}
            prop_employeeEditModal={employeeEditModal}
            prop_handleEmployeeModalEditClose={handleEmployeeModalEditClose}
            prop_user_id={get_user_id}
        ></CompaniesEmploymentEditModal> */}
        
        </>
    );
}

export default SidebarMenu;