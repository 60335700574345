import React, { useContext, useEffect, useRef, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import axiosClient from "../axios-client";
import Userimg from "../images/man-2.png";
import axios from "axios";
import {
  useTable,
  useFilters,
  useGlobalFilter,
  useSortBy,
  useAsyncDebounce,
  usePagination,
} from "react-table";
import * as Icon from "react-feather";
import { ComponentLoading } from "../Components/ComponentLoading";
import { ToastContainer, toast } from "react-toastify";
import Swal from "sweetalert2";
import { Modal, Button, Nav, Tab, Row, Col } from "react-bootstrap";
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import moment from 'moment';
import Select from 'react-select'
import { ActionMeta, OnChangeValue } from 'react-select';
import { useStateContext } from "../Context/ContextProvider";

import logo from '../images/cr-computer.png'

// ** Store Imports
import { useDispatch, useSelector } from 'react-redux'

import {
  stGetIndustriesData, 
  stActionMode, 
  stGetResponse, 
  stGetCompanyData, 
  stFetchCompanySites,
  stFetchCompanyEmployees,
  stFetchCompanyPowerUsers
} from '../store/apps/mainUser'

// Import Components
import CompaniesOtherSiteAddresses from '../PageComponents/CompaniesOtherSiteAddresses';
import CompaniesOtherSitePhoneNumbers from '../PageComponents/CompaniesOtherSitePhoneNumbers';

//token
const accessToken = localStorage.getItem("ACCESS_TOKEN");

const CompanyDetailsPage = ({refreshEditCompany}) => {

  const { user, role, notification } = useStateContext();

  // ** Hooks
  const dispatch = useDispatch();
  
  const stMainUser = useSelector((state) => state.stMainUser)

  const navigate = useNavigate();

  let { companyId, userType } = useParams();

  const [getUsers, setUsers] = useState([]);

  const [getComponentLoading, setComponentLoading] = useState(true);
  const [getEditComponentLoading, setEditComponentLoading] = useState(true);

  // Edit Company Modal
  const [showEditUserModal, setShowEditUserModal] = useState(false);
  const handleEditUserModalClose = () => setShowEditUserModal(false);
  const handleEditUserModalShow = () => setShowEditUserModal(true);

  // Password Modal
  const [showPasswordModal, setShowPasswordModal] = useState(false);
  const handlePasswordModalClose = () => setShowPasswordModal(false);
  const handlePasswordModalShow = () => setShowPasswordModal(true);

  // Company Modal
  const [showUserModal, setShowUserModal] = useState(false);
  const handleUserModalClose = () => setShowUserModal(false);
  const handleUserModalShow = () => {
    setShowUserModal(true)
    setUserId(null);
    setUserEmail('')
    setUserFirstName('')
    setUserLastName('')
    setUserPassword('')
    setUserConfirmPassword('')
  };

  const [newPasswordType, setNewPasswordType] = useState(false);
  const [confirmPasswordType, setConfirmPasswordType] = useState(false)

  const [btnLoading, setBtnLoading] = useState(false)

  //user id to save password
  // const password = useRef(null);
  // const confirm_password = useRef(null);
  
  const [getCompanyId, setCompanyId] = useState(null);
  
  const [getUserId, setUserId] = useState();
  const [getUserEmail, setUserEmail] = useState("");
  const [getUserFirstName, setUserFirstName] = useState("");
  const [getUserLastName, setUserLastName] = useState("");
  const [getUserPassword, setUserPassword] = useState("");
  const [getUserConfirmPassword, setUserConfirmPassword] = useState("");

  const usersRequest = () => {
    // let config = {
    //   method: "get",
    //   url: process.env.REACT_APP_API_URL + "/power-users/"+companyId,
    //   headers: {
    //     Accept: "application/json",
    //     Authorization: `Bearer ${accessToken}`,
    //   },
    // };

    // axios(config)
    // .then((response) => {
    //   const updatedRecords = response.data.users.map(record => {
    //     if (record.status === 'inactive') {
    //       return { ...record, status: 'pending' };
    //     }
    //     return record;
    //   });
    //     setUsers(updatedRecords);
    //     setComponentLoading(false);
    // })
    // .catch((error) => {
    //   setComponentLoading(false);
    // });
    

    if(stMainUser.stFetchCompanyPowerUsers.users){
      setUsers(stMainUser.stFetchCompanyPowerUsers.users);
      setComponentLoading(false);
    }

    if(stMainUser.stCompanyId){
      setCompanyId(stMainUser.stCompanyId)
    }
    
  };

  function GlobalFilter(props) {
    const { setGlobalFilter } = props;
    const count = props.preGlobalFilteredRows.length;
    const [value, setValue] = React.useState(props.globalFilter);
    const onChange = useAsyncDebounce((value) => {
      setGlobalFilter(value || undefined);
    }, 200);
    return (
      <div className="d-flex justify-content-between">
        <div className="pt-3 pl-3 pr-3 pb-3">
          <div className="card-toolbar">
            <div
              className="d-flex justify-content-center m-auto"
              data-kt-customer-table-toolbar="base"
            >
              {/* <div className="form-control d-flex justify-content-center align-items-center position-relative">
                <select
                  className="form-select form-select-sm form-select-solid px-3 m-auto dropDownRemove"
                  onChange={(e) => {
                    setGlobalFilter(e.target.value || undefined);
                  }}
                >
                  <option className="icon" value="">
                    All
                  </option>
                  <option value="active">Active</option>
                  <option value="pending">Pending</option>
                  <option value="disabled">Disable</option>
                </select>
              </div> */}
            </div>
          </div>
        </div>
        <div className="pt-3 pl-3 pr-3 pb-3">
          <div className="card-toolbar">
            <div
              className="d-flex justify-content-end"
              data-kt-customer-table-toolbar="base"
            >
              <div className="customTableSearch d-flex align-items-center position-relative">
                <span className="icon">
                  <Icon.Search />
                </span>
                <input
                  type="text"
                  data-kt-user-table-filter="search"
                  className="form-control"
                  placeholder="Search"
                  value={value || ""}
                  onChange={(e) => {
                    setValue(e.target.value);
                    onChange(e.target.value);
                  }}
                />
              </div>
              <Button variant="btn btn-primary ml-3 pl-10 pr-10" onClick={handleUserModalShow}>
                Add User
              </Button>
            </div>
          </div>
        </div>
      </div>
    );
  }

  function DefaultColumnFilter({
    column: { filterValue, preFilteredRows, setFilter },
  }) {
    const count = preFilteredRows.length;
    return (
      <input
        type="text"
        data-kt-user-table-filter="search"
        className="customTableSearch form-control form-control-solid w-250px ps-14"
        value={filterValue || ""}
        onChange={(e) => {
          setFilter(e.target.value || undefined);
        }}
        placeholder={`Search ${count} records...`}
      />
    );
  }

  function Table(props) {
    const { columns, data } = props;
    const defaultColumn = React.useMemo(
      () => ({
        // Default Filter UI
        Filter: DefaultColumnFilter,
      }),
      []
    );
    const setLocalStorage = (pageSize) => {
      // console.log('pageSize',pageSize)
      localStorage.setItem("pageSize", pageSize);
    };

    const {
      getTableProps,
      getTableBodyProps,
      headerGroups,
      rows,
      prepareRow,
      // state,
      preGlobalFilteredRows,
      setGlobalFilter,
      page,
      // canPreviousPage,
      // canNextPage,
      pageOptions,
      pageCount,
      gotoPage,
      nextPage,
      previousPage,
      setPageSize,
      state: { pageIndex, pageSize, globalFilter },
    } = useTable(
      {
        columns,
        data,
        defaultColumn,
        initialState: {
          pageIndex: 0,
          pageSize: localStorage.getItem("pageSize")
            ? Number(localStorage.getItem("pageSize"))
            : 10,
        },
      },
      useFilters,
      useGlobalFilter,
      useSortBy,
      usePagination
    );
    let currentPageStartIndex = 2;
    let previousIndexPage = 0;
    return (
      <div>
        <GlobalFilter
          preGlobalFilteredRows={preGlobalFilteredRows}
          globalFilter={globalFilter}
          setGlobalFilter={setGlobalFilter}
        />
        <div className="table-responsive">
          <table
            className="table table-striped dataTable mb-0"
            {...getTableProps()}
          >
            {headerGroups.map((headerGroup) => (
              <tr
                className="tableHeader"
                {...headerGroup.getHeaderGroupProps()}
              >
                {headerGroup.headers.map((column) => (
                  // Add the sorting props to control sorting. For this example
                  // we can add them into the header props
                  <th
                    className=""
                    {...column.getHeaderProps(column.getSortByToggleProps())}
                  >
                    {column.render("Header")}
                    {/* Add a sort direction indicator */}
                    <span>
                      {column.isSorted ? (
                        column.isSortedDesc ? (
                          <span className="table-sort-asc"></span>
                        ) : (
                          <span className="table-sort-desc"></span>
                        )
                      ) : (
                        ""
                      )}
                    </span>
                  </th>
                ))}
              </tr>
            ))}
            <tbody className="" {...getTableBodyProps()}>
              {page.length > 0 ? (
                <>
                  {page.map((row, i) => {
                    prepareRow(row);
                    return (
                      <tr {...row.getRowProps()}>
                        {row.cells.map((cell) => {
                          return (
                            <td {...cell.getCellProps()}>
                              {cell.render("Cell")}
                            </td>
                          );
                        })}
                      </tr>
                    );
                  })}
                </>
              ) : (
                <tr>
                  <td colSpan={6}>
                    <div className="card-body text-center bg-light tableDataNotFound">
                      No data found
                    </div>
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
        <div className="pl-3 pr-3">
          <div className="row">
            <div className="mt-3 mb-3 col-sm-5 d-flex align-items-center justify-content-center justify-content-sm-start">
              <div className="dataTables_length" id="kt_customers_table_length">
                <select
                  name="kt_customers_table_length"
                  className="form-select form-select-sm form-select-solid"
                  value={pageSize}
                  onChange={(e) => {
                    {
                      setPageSize(Number(e.target.value));
                      setLocalStorage(e.target.value);
                    }
                  }}
                  style={{ width: "120px", height: "38px" }}
                >
                  {[10, 20, 30, 40, 50].map((pageSize) => (
                    <option key={pageSize} value={pageSize}>
                      {pageSize}
                    </option>
                  ))}
                </select>
              </div>
            </div>
            <div className="mt-3 mb-3 col-sm-7 d-flex align-items-center justify-content-center justify-content-sm-end">
              <div id="kt_table_users_paginate">
                <ul className="pagination mb-0">
                  <li className="page-item" onClick={() => previousPage()}>
                    <a className="page-link prev-angle">
                      <i className="fa fa-chevron-left"></i>
                    </a>
                  </li>
                  {pageOptions.map((pageSize, rowIndex) => {
                    if (currentPageStartIndex <= rowIndex) {
                      currentPageStartIndex = currentPageStartIndex + 1;
                      previousIndexPage = rowIndex - 1;
                    }
                    return previousIndexPage <= currentPageStartIndex ? (
                      <li
                        className={`page-item ${pageIndex == 0 && rowIndex == 0
                          ? "active"
                          : pageIndex + 1 == rowIndex + 1
                            ? "active"
                            : ""
                          }`}
                        onClick={() => gotoPage(rowIndex)}
                      >
                        <a className="page-link">{pageSize + 1} </a>
                        {/* {pageIndex + 1} */}
                      </li>
                    ) : null;
                  })}
                  <li className="page-item" onClick={() => nextPage()}>
                    <a className="page-link next-angle">
                      {/* {'Next »'} */}
                      <i className="fa fa-chevron-right"></i>
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }

  const setPassword = () => {
    setBtnLoading(true)
    let payload = {
      id: getUserId,
      password: getUserPassword,
      confirm_password: getUserConfirmPassword,
    }
    axiosClient.put('/power-users/'+getUserId+'/password', payload)
      .then((res) => {
        setBtnLoading(false)
        toast.success(res.data.message, {
          position: "bottom-right",
          autoClose: 1500,
          theme: "colored",
        });
        //close model
        handlePasswordModalClose();
      })
      .catch(err => {
        setBtnLoading(false)
        var firstErrorMessage = '';
        if (err.response.data.errors && Object.keys(err.response.data.errors).length != 0) {
          var errors = err.response.data.errors
          const errorMessages = Object.values(errors); // Get an array of all error messages
          firstErrorMessage = errorMessages.shift(); // Get the first error message
          toast.error(firstErrorMessage[0], {
            position: "bottom-right",
            autoClose: 1500,
            theme: "colored",
          });
        } else {
          // console.log('firstErrorMessage',firstErrorMessage[0])
          toast.error(err.response.data.message, {
            position: "bottom-right",
            autoClose: 1500,
            theme: "colored",
          });
        }
        // console.log(err);
      });

  };

  const saveBtn = () => {
    setBtnLoading(true)
    let payload = {
      company_id: getCompanyId,
      email: getUserEmail,
      first_name: getUserFirstName,
      last_name: getUserLastName,
      password: getUserPassword,
      confirm_password: getUserConfirmPassword,
    }
    axiosClient.post('/power-users', payload)
      .then((res) => {
        toast.success(res.data.message, {
          position: "bottom-right",
          autoClose: 1500,
          theme: "colored",
        });
        setBtnLoading(false)
        usersRequest()
        handleUserModalClose() // close company model
        refreshEditCompany()
      })
      .catch(err => {
        setBtnLoading(false)
        var firstErrorMessage = '';
        if (err.response.data.errors && Object.keys(err.response.data.errors).length != 0) {
          var errors = err.response.data.errors
          const errorMessages = Object.values(errors); // Get an array of all error messages
          firstErrorMessage = errorMessages.shift(); // Get the first error message
          toast.error(firstErrorMessage[0], {
            position: "bottom-right",
            autoClose: 1500,
            theme: "colored",
          });
        } else {
          // console.log('firstErrorMessage',firstErrorMessage[0])
          toast.error(err.response.data.message, {
            position: "bottom-right",
            autoClose: 1500,
            theme: "colored",
          });
        }
        // console.log(err);
      });

  };

  const updateBtn = () => {
    setBtnLoading(true)
    let payload = {
      email: getUserEmail,
      first_name: getUserFirstName,
      last_name: getUserLastName,
    }
    axiosClient.put('/power-users/'+getUserId, payload)
      .then((res) => {
        toast.success(res.data.message, {
          position: "bottom-right",
          autoClose: 1500,
          theme: "colored",
        });
        setBtnLoading(false)
        usersRequest()
        handleEditUserModalClose() // close company model
      })
      .catch(err => {
        setBtnLoading(false)
        var firstErrorMessage = '';
        if (err.response.data.errors && Object.keys(err.response.data.errors).length != 0) {
          var errors = err.response.data.errors
          const errorMessages = Object.values(errors); // Get an array of all error messages
          firstErrorMessage = errorMessages.shift(); // Get the first error message
          toast.error(firstErrorMessage[0], {
            position: "bottom-right",
            autoClose: 1500,
            theme: "colored",
          });
        } else {
          // console.log('firstErrorMessage',firstErrorMessage[0])
          toast.error(err.response.data.message, {
            position: "bottom-right",
            autoClose: 1500,
            theme: "colored",
          });
        }
        // console.log(err);
      });

  };

  const updatePassword = async (data) => {
    setShowPasswordModal(true);
    setUserId(data.id);
  };

  const editUserModalBtn = async (data) => {
    setEditComponentLoading(true);
    setShowEditUserModal(true);
    let config = {
        method: "get",
        url: process.env.REACT_APP_API_URL + "/power-users/"+data.id+"/get",
        headers: {
          Accept: "application/json",
          Authorization: `Bearer ${accessToken}`,
        },
      };
  
      axios(config)
      .then((response) => {
        setUserId(response.data.user.id);
        setUserEmail(response.data.user.email)
        setUserFirstName(response.data.user.first_name)
        setUserLastName(response.data.user.last_name)
        setEditComponentLoading(false);
      })
      .catch((error) => {
        setEditComponentLoading(false);
      });
  };

  const disableBtn = async (data) => {
    Swal.fire({
      title: "Are you sure you want to disable?",
      icon: "question",
      confirmButtonColor: "#f32013",
      cancelButtonColor: "#d33",
      confirmButtonText: "Disable",
      showCancelButton: true,
    }).then((result) => {
      if (result.isConfirmed) {
        let payload = {
          id: data.id,
          status: "disabled",
        };
        axiosClient.put("/companies/"+data.id+'/status', payload).then(() => {
          usersRequest();
          toast.success("Disabled successfully", {
            position: "bottom-right",
            autoClose: 1500,
            theme: "colored",
          });
        });
      }
    });
  };

  const activeUser = async (data) => {
    Swal.fire({
      title: "Are you sure you want to activate?",
      icon: "question",
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Activate",
      showCancelButton: true,
    }).then((result) => {
      if (result.isConfirmed) {
        let payload = {
          id: data.id,
          status: "active",
        };
        axiosClient.put("/companies/"+data.id+'/status', payload).then(() => {
          usersRequest();
          toast.success("Activated successfully", {
            position: "bottom-right",
            autoClose: 1500,
            theme: "colored",
          });
        });
      }
    });
  };

  const deleteBtn = async (data) => {
    Swal.fire({
      title: "Are you sure want to delete?",
      icon: "question",
      confirmButtonColor: "#f32013",
      cancelButtonColor: "#d33",
      confirmButtonText: "Delete",
      showCancelButton: true,
    }).then((result) => {
      if (result.isConfirmed) {
        setComponentLoading(true)
        let payload = {
          id: data.id,
          status: "deleted",
        };
        // console.log('data=',data)
        axiosClient.delete("/power-users/"+companyId+"/"+data.id, payload).then((res) => {
          usersRequest();
          toast.success(res.data.message, {
            position: "bottom-right",
            autoClose: 1500,
            theme: "colored",
          });
          refreshEditCompany()
          setComponentLoading(false)
      });
      }
    });
  };

  // useEffect(() => {
  //   if(role != 'admin'){
  //     navigate('/companies')
  //   }
  // }, [role]);

  useEffect(() => {
      if(companyId){
        setCompanyId(companyId)
        dispatch(stFetchCompanyPowerUsers({companyId}));
      }
  }, [dispatch, companyId])
  
  useEffect(() => {
    if(companyId){
      usersRequest()
    }
  }, [companyId,stMainUser]);
  
  const columns = React.useMemo(
    () => [
      {
        Header: "First Name",
        accessor: "first_name",
        Cell: ({ row }) => {
          const data = row.original;
          var firstName = data.first_name?data.first_name:'';
          return firstName;
        },
      },
      {
        Header: "Last Name",
        accessor: "last_name",
        Cell: ({ row }) => {
          const data = row.original;
          var lastName = data.last_name?data.last_name:'';
          return lastName;
        },
      },
      {
        Header: "Email",
        accessor: "email",
        Cell: ({ row }) => {
          const data = row.original;
          return data.email;
        },
      },
      {
        Header: "Action",
        accessor: "action",
        disableSortBy: true,
        Cell: ({ row }) => {
          const data = row.original;
          return (
            <>
              <div className="actionButtons">
                  <OverlayTrigger
                    trigger={['hover', 'focus']}
                    placement="top"
                    overlay={<Tooltip id="example-tooltip">Edit User</Tooltip>}
                  >
                    <button
                      className="btn btn-primary ml-1 mb-1"
                      aria-expanded="false"
                      onClick={() => {
                        editUserModalBtn(data);
                      }}
                    >
                      <Icon.Edit />
                    </button>
                  </OverlayTrigger>

                  <OverlayTrigger
                    trigger={['hover', 'focus']}
                    placement="top"
                    overlay={<Tooltip id="example-tooltip">Change Password</Tooltip>}
                  >
                    <button
                      className="btn btn-primary ml-1 mb-1"
                      aria-expanded="false"
                      onClick={() => {
                        updatePassword(data);
                      }}
                    >
                      <Icon.Lock />
                    </button>
                  </OverlayTrigger>

                  <OverlayTrigger
                    trigger={['hover', 'focus']}
                    placement="top"
                    overlay={<Tooltip id="example-tooltip">Delete User</Tooltip>}
                  >
                    <button
                      className="btn btn-danger ml-1 mb-1"
                      aria-expanded="false"
                      onClick={() => {
                        deleteBtn(data);
                      }}
                    >
                      <Icon.Trash />
                    </button>
                  </OverlayTrigger>
              </div>
            </>
          );
        },
      },
    ],
    []
  );

  const data = getUsers;

  return (
    <>
      {/* Set Password Modal */}
      <Modal
        className="customModal"
        backdrop="static"
        centered
        size="xl"
        show={showPasswordModal}
        onHide={handlePasswordModalClose}
      >
        <Modal.Header>
          <Modal.Title><img className="modalLogo" src={logo} /> Set Password</Modal.Title>
          <button
            type="button"
            className="btn-close"
            onClick={handlePasswordModalClose}
          >
          </button>
        </Modal.Header>
        <Modal.Body>
          <form className="formData">
            <div className="row">
              <div className="col-sm-3"></div>
              <div className="col-sm-6">
                <div className="row">
                  <div class="col-sm-12">
                    <div class="form-group mb-3">
                      <label for="password">New Password</label>
                      <div className="innerInputIcon">
                        <input
                          type={newPasswordType?("text"):("password")}
                          className="form-control"
                          id="password"
                          name="password"
                          placeholder=''
                          onChange={e => setUserPassword(e.target.value)}
                        />
                        <div className="innerInputIcons">
                          {
                            newPasswordType?
                            (
                              <span onClick={() => {setNewPasswordType(false)}} className="eyeOff"><Icon.EyeOff /></span>
                            ):(
                              <span onClick={() => {setNewPasswordType(true)}} className="eye"><Icon.Eye /></span>
                            )
                          }
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-sm-12">
                    <div class="form-group mb-3">
                      <label for="password_confirmation">Confirm Password</label>
                      <div className="innerInputIcon">
                        <input
                          type={confirmPasswordType?("text"):("password")}
                          class="form-control"
                          id="email_confirmation"
                          name="email_confirmation"
                          placeholder=''
                          onChange={e => setUserConfirmPassword(e.target.value)}
                        />
                        <div className="innerInputIcons">
                          {
                            confirmPasswordType?
                            (
                              <span onClick={() => {setConfirmPasswordType(false)}} className="eyeOff"><Icon.EyeOff /></span>
                            ):(
                              <span onClick={() => {setConfirmPasswordType(true)}} className="eye"><Icon.Eye /></span>
                            )
                          }
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-sm-3"></div>
            </div>
          </form>
        </Modal.Body>
        <Modal.Footer>
          <div className="row">
            <div className="col-sm-3"></div>
            <div className="col-sm-6">
              <div className="centeredBtns">
                <Button
                  variant="btn btn-outline-primary"
                  onClick={handlePasswordModalClose}
                >
                  Close
                </Button>
                <button type="submit" className="btn btn-primary" onClick={setPassword} disabled={btnLoading}>
                  {
                    btnLoading ?
                      (
                        <>Please Wait <span className="spinner-grow spinner-grow-sm ml-2" role="status" aria-hidden="true"></span></>
                      ) : (
                        <>Set Password</>
                      )
                  }
                </button>
              </div>
            </div>
            <div className="col-sm-3"></div>
          </div>
        </Modal.Footer>
      </Modal>

      {/* Add User Modal */}
      <Modal
        className="customModal" // hide-btn-close
        backdrop="static"
        centered
        size="xl"
        show={showUserModal}
        onHide={handleUserModalClose}
      >
        <Modal.Header closeButton>
          <Modal.Title><img className="modalLogo" src={logo} /> Add User</Modal.Title>
          {/* <button
            type="button"
            className="btnClose custom"
            onClick={() => { handleUserModalClose() }}
          >
            <i className="fa fa-close"></i>
          </button> */}
        </Modal.Header>
        <Modal.Body>
          <form>
            <div className="row">
              <div className="col-sm-3"></div>
              <div className="col-sm-6">
                <div className="row">
                  <div className="col-sm-12">
                      <div className="mb-3">
                          <label for="email" className="form-label">
                              Email
                          </label>
                          <input type="email" className="form-control" id="email" required value={getUserEmail} onChange={e => setUserEmail(e.target.value)} />
                      </div>
                  </div>
                  <div className="col-sm-12">
                      <div className="mb-3">
                          <label for="first_name" className="form-label">
                              First Name
                          </label>
                          <input type="text" className="form-control" id="first_name" required value={getUserFirstName} onChange={e => setUserFirstName(e.target.value)} />
                      </div>
                  </div>
                  <div className="col-sm-12">
                      <div className="mb-3">
                          <label for="last_name" className="form-label">
                              Last Name
                          </label>
                          <input type="text" className="form-control" id="last_name" required value={getUserLastName} onChange={e => setUserLastName(e.target.value)} />
                      </div>
                  </div>
                  <div className="col-sm-12">
                      <div className="mb-3">
                          <label for="password" className="form-label">
                              Password
                          </label>
                          {/* <input type="password" className="form-control" id="password" required onChange={e => setUserPassword(e.target.value)} /> */}
                          <div className="innerInputIcon">
                            <input
                              type={newPasswordType?("text"):("password")}
                              className="form-control"
                              id="password"
                              name="password"
                              placeholder=''
                              onChange={e => setUserPassword(e.target.value)}
                            />
                            <div className="innerInputIcons">
                              {
                                newPasswordType?
                                (
                                  <span onClick={() => {setNewPasswordType(false)}} className="eyeOff"><Icon.EyeOff /></span>
                                ):(
                                  <span onClick={() => {setNewPasswordType(true)}} className="eye"><Icon.Eye /></span>
                                )
                              }
                            </div>
                          </div>
                      </div>
                  </div>
                  <div className="col-sm-12">
                      <div className="mb-3">
                          <label for="confirm_password" className="form-label">
                              Confirm Password
                          </label>
                          {/* <input type="password" className="form-control" id="confirm_password" required onChange={e => setUserConfirmPassword(e.target.value)} /> */}
                          <div className="innerInputIcon">
                            <input
                              type={confirmPasswordType?("text"):("password")}
                              class="form-control"
                              id="email_confirmation"
                              name="email_confirmation"
                              placeholder=''
                              onChange={e => setUserConfirmPassword(e.target.value)}
                            />
                            <div className="innerInputIcons">
                              {
                                confirmPasswordType?
                                (
                                  <span onClick={() => {setConfirmPasswordType(false)}} className="eyeOff"><Icon.EyeOff /></span>
                                ):(
                                  <span onClick={() => {setConfirmPasswordType(true)}} className="eye"><Icon.Eye /></span>
                                )
                              }
                            </div>
                          </div>
                      </div>
                  </div>
                </div>
              </div>
              <div className="col-sm-3"></div>
            </div>
          </form>
        </Modal.Body>
        <Modal.Footer>
          <div className="row">
            <div className="col-sm-3"></div>
            <div className="col-sm-6">
              <div className="centeredBtns">
                <Button
                  variant="btn btn-outline-primary"
                  onClick={handleUserModalClose}
                >
                  Close
                </Button>
                <button type="submit" className="btn btn-primary" disabled={btnLoading} onClick={saveBtn}>
                  {
                    btnLoading ?
                      (
                        <>Please Wait <span className="spinner-grow spinner-grow-sm ml-2" role="status" aria-hidden="true"></span></>
                      ) : (
                        <>Save</>
                      )
                  }
                </button>
              </div>
            </div>
            <div className="col-sm-3"></div>
          </div>
        </Modal.Footer>
      </Modal>

      {/* Edit User Modal */}
      <Modal
        className="customModal" // hide-btn-close
        backdrop="static"
        centered
        size="xl"
        show={showEditUserModal}
        onHide={handleEditUserModalClose}
      >
        <Modal.Header closeButton>
          <Modal.Title><img className="modalLogo" src={logo} /> Edit User</Modal.Title>
          {/* <button
            type="button"
            className="btnClose custom"
            onClick={() => { handleEditUserModalClose() }}
          >
            <i className="fa fa-close"></i>
          </button> */}
        </Modal.Header>
        <Modal.Body>
          <form>
            <div className="formData">
              {getEditComponentLoading ? (
                <div className="card loadingCard">
                  <div className="card-body">
                    <ComponentLoading></ComponentLoading>
                  </div>
                </div>
              ) : (
                <div className="row">
                  <div className="col-sm-3"></div>
                  <div className="col-sm-6">
                    <div className="row">
                      <div className="col-sm-12">
                          <div className="mb-3">
                              <label for="email" className="form-label">
                                  Email
                              </label>
                              <input type="email" className="form-control" id="email" required value={getUserEmail} onChange={e => setUserEmail(e.target.value)} />
                          </div>
                      </div>
                      <div className="col-sm-12">
                          <div className="mb-3">
                              <label for="first_name" className="form-label">
                                  First Name
                              </label>
                              <input type="text" className="form-control" id="first_name" required value={getUserFirstName} onChange={e => setUserFirstName(e.target.value)} />
                          </div>
                      </div>
                      <div className="col-sm-12">
                          <div className="mb-3">
                              <label for="last_name" className="form-label">
                                  Last Name
                              </label>
                              <input type="text" className="form-control" id="last_name" required value={getUserLastName} onChange={e => setUserLastName(e.target.value)} />
                          </div>
                      </div>
                    </div>
                  </div>
                <div className="col-sm-3"></div>
              </div>
              )}
            </div>
          </form>
        </Modal.Body>
        <Modal.Footer>
          <div className="row">
            <div className="col-sm-3"></div>
            <div className="col-sm-6">
              <div className="centeredBtns">
                <Button
                  variant="btn btn-outline-primary"
                  onClick={handleEditUserModalClose}
                >
                  Close
                </Button>
                <button type="submit" className="btn btn-primary" disabled={btnLoading} onClick={updateBtn}>
                  {
                    btnLoading ?
                      (
                        <>Please Wait <span className="spinner-grow spinner-grow-sm ml-2" role="status" aria-hidden="true"></span></>
                      ) : (
                        <>Save</>
                      )
                  }
                </button>
              </div>
            </div>
            <div className="col-sm-3"></div>
          </div>
        </Modal.Footer>
      </Modal>

      {/* Page Content */}
      {getComponentLoading ? (
        <div className="card loadingCard">
          <div className="card-body">
            <ComponentLoading></ComponentLoading>
          </div>
        </div>
      ) : (
        <div className="card customCard">
          <Table columns={columns} data={data} />
        </div>
      )}
    </>
  );
};

export default CompanyDetailsPage;
