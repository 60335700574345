import React, { useContext, useEffect, useRef, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import axiosClient from "../axios-client";
import Userimg from "../images/man-2.png";
import axios from "axios";
import {
  useTable,
  useFilters,
  useGlobalFilter,
  useSortBy,
  useAsyncDebounce,
  usePagination,
} from "react-table";
import * as Icon from "react-feather";
import { ComponentLoading } from "../Components/ComponentLoading";
import { ToastContainer, toast } from "react-toastify";
import Swal from "sweetalert2";
import { Modal, Button, Nav, Tab, Row, Col } from "react-bootstrap";
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import moment from 'moment';
import Select from 'react-select'
import { ActionMeta, OnChangeValue } from 'react-select';
import { useStateContext } from "../Context/ContextProvider";

import logo from '../images/cr-computer.png'

// ** Store Imports
import { useDispatch, useSelector } from 'react-redux'

// Import Components
import CompaniesOtherSiteAddresses from '../PageComponents/CompaniesOtherSiteAddresses';
import CompaniesOtherSitePhoneNumbers from '../PageComponents/CompaniesOtherSitePhoneNumbers';

//token
const accessToken = localStorage.getItem("ACCESS_TOKEN");

const CompanyDetailsPage = ({refreshEditCompany}) => {
    
  const stMainUser = useSelector((state) => state.stMainUser)

  const { user, role, notification } = useStateContext();

  const navigate = useNavigate();

  let { companyId, userType } = useParams();

  const [getCompanySites, setCompanySites] = useState([]);

  const [getComponentLoading, setComponentLoading] = useState(true);
  const [getEditComponentLoading, setEditComponentLoading] = useState(true);

  // Edit Company Modal
  const [showEditUserModal, setEditSiteModal] = useState(false);
  const handleEditCloseSiteModal = () => setEditSiteModal(false);
  const handleEditUserModalShow = () => setEditSiteModal(true);

  // Company Modal
  const [showUserModal, setShowUserModal] = useState(false);
  const handleAddCloseSiteModal = () => setShowUserModal(false);
  const handleAddSiteModal = () => {
    setShowUserModal(true)
    setCompanySiteId(null);
    set_name('')
    set_phone_number('')
    set_address('')
  };

  const [btnLoading, setBtnLoading] = useState(false)
  const [getCompanyId, setCompanyId] = useState(null);
  
  const [getCompanySiteId, setCompanySiteId] = useState();
  const [name, set_name] = useState("");
  const [phone_number, set_phone_number] = useState("");
  const [address, set_address] = useState("");

  const mainRequest = () => {
    // let config = {
    //   method: "get",
    //   url: process.env.REACT_APP_API_URL + "/company-sites/"+companyId,
    //   headers: {
    //     Accept: "application/json",
    //     Authorization: `Bearer ${accessToken}`,
    //   },
    // };

    // axios(config)
    // .then((response) => {
    //     setCompanySites(response.data.company_sites);
    //     setComponentLoading(false);
    // })
    // .catch((error) => {
    //   setComponentLoading(false);
    // });

    // console.log('stMainUser.stCompanySites=',stMainUser.stFetchCompanySites)
    if(stMainUser.stFetchCompanySites.company_sites){
      setCompanySites(stMainUser.stFetchCompanySites.company_sites);
      setComponentLoading(false);
    }
  
  };

  function GlobalFilter(props) {
    const { setGlobalFilter } = props;
    const count = props.preGlobalFilteredRows.length;
    const [value, setValue] = React.useState(props.globalFilter);
    const onChange = useAsyncDebounce((value) => {
      setGlobalFilter(value || undefined);
    }, 200);
    return (
      <div className="d-flex justify-content-between">
        <div className="pt-3 pl-3 pr-3 pb-3">
          <div className="card-toolbar">
            <div
              className="d-flex justify-content-center m-auto"
              data-kt-customer-table-toolbar="base"
            >
              {/* <div className="form-control d-flex justify-content-center align-items-center position-relative">
                <select
                  className="form-select form-select-sm form-select-solid px-3 m-auto dropDownRemove"
                  onChange={(e) => {
                    setGlobalFilter(e.target.value || undefined);
                  }}
                >
                  <option className="icon" value="">
                    All
                  </option>
                  <option value="active">Active</option>
                  <option value="pending">Pending</option>
                  <option value="disabled">Disable</option>
                </select>
              </div> */}
            </div>
          </div>
        </div>
        <div className="pt-3 pl-3 pr-3 pb-3">
          <div className="card-toolbar">
            <div
              className="d-flex justify-content-end"
              data-kt-customer-table-toolbar="base"
            >
              <div className="customTableSearch d-flex align-items-center position-relative">
                <span className="icon">
                  <Icon.Search />
                </span>
                <input
                  type="text"
                  data-kt-user-table-filter="search"
                  className="form-control"
                  placeholder="Search"
                  value={value || ""}
                  onChange={(e) => {
                    setValue(e.target.value);
                    onChange(e.target.value);
                  }}
                />
              </div>
              <Button variant="btn btn-primary ml-3 pl-10 pr-10" onClick={handleAddSiteModal}>
                Add New Site
              </Button>
            </div>
          </div>
        </div>
      </div>
    );
  }

  function DefaultColumnFilter({
    column: { filterValue, preFilteredRows, setFilter },
  }) {
    const count = preFilteredRows.length;
    return (
      <input
        type="text"
        data-kt-user-table-filter="search"
        className="customTableSearch form-control form-control-solid w-250px ps-14"
        value={filterValue || ""}
        onChange={(e) => {
          setFilter(e.target.value || undefined);
        }}
        placeholder={`Search ${count} records...`}
      />
    );
  }

  function Table(props) {
    const { columns, data } = props;
    const defaultColumn = React.useMemo(
      () => ({
        // Default Filter UI
        Filter: DefaultColumnFilter,
      }),
      []
    );
    const setLocalStorage = (pageSize) => {
      // console.log('pageSize',pageSize)
      localStorage.setItem("pageSize", pageSize);
    };

    const {
      getTableProps,
      getTableBodyProps,
      headerGroups,
      rows,
      prepareRow,
      // state,
      preGlobalFilteredRows,
      setGlobalFilter,
      page,
      // canPreviousPage,
      // canNextPage,
      pageOptions,
      pageCount,
      gotoPage,
      nextPage,
      previousPage,
      setPageSize,
      state: { pageIndex, pageSize, globalFilter },
    } = useTable(
      {
        columns,
        data,
        defaultColumn,
        initialState: {
          pageIndex: 0,
          pageSize: localStorage.getItem("pageSize")
            ? Number(localStorage.getItem("pageSize"))
            : 10,
        },
      },
      useFilters,
      useGlobalFilter,
      useSortBy,
      usePagination
    );
    let currentPageStartIndex = 2;
    let previousIndexPage = 0;
    return (
      <div>
        <GlobalFilter
          preGlobalFilteredRows={preGlobalFilteredRows}
          globalFilter={globalFilter}
          setGlobalFilter={setGlobalFilter}
        />
        <div className="table-responsive">
          <table
            className="table table-striped dataTable mb-0"
            {...getTableProps()}
          >
            {headerGroups.map((headerGroup) => (
              <tr
                className="tableHeader"
                {...headerGroup.getHeaderGroupProps()}
              >
                {headerGroup.headers.map((column) => (
                  // Add the sorting props to control sorting. For this example
                  // we can add them into the header props
                  <th
                    className=""
                    {...column.getHeaderProps(column.getSortByToggleProps())}
                  >
                    {column.render("Header")}
                    {/* Add a sort direction indicator */}
                    {
                      column.render("Header") != 'Action' && (
                        <span>
                          {column.isSorted ? (
                            column.isSortedDesc ? (
                              <span className="table-sort-asc"></span>
                            ) : (
                              <span className="table-sort-desc"></span>
                            )
                          ) : (
                            ""
                          )}
                        </span>
                      )
                    }
                  </th>
                ))}
              </tr>
            ))}
            <tbody className="" {...getTableBodyProps()}>
              {page.length > 0 ? (
                <>
                  {page.map((row, i) => {
                    prepareRow(row);
                    return (
                      <tr {...row.getRowProps()}>
                        {row.cells.map((cell) => {
                          return (
                            <td {...cell.getCellProps()}>
                              {cell.render("Cell")}
                            </td>
                          );
                        })}
                      </tr>
                    );
                  })}
                </>
              ) : (
                <tr>
                  <td colSpan={6}>
                    <div className="card-body text-center bg-light tableDataNotFound">
                      No data found
                    </div>
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
        <div className="pl-3 pr-3">
          <div className="row">
            <div className="mt-3 mb-3 col-sm-5 d-flex align-items-center justify-content-center justify-content-sm-start">
              <div className="dataTables_length" id="kt_customers_table_length">
                <select
                  name="kt_customers_table_length"
                  className="form-select form-select-sm form-select-solid"
                  value={pageSize}
                  onChange={(e) => {
                    {
                      setPageSize(Number(e.target.value));
                      setLocalStorage(e.target.value);
                    }
                  }}
                  style={{ width: "120px", height: "38px" }}
                >
                  {[10, 20, 30, 40, 50].map((pageSize) => (
                    <option key={pageSize} value={pageSize}>
                      {pageSize}
                    </option>
                  ))}
                </select>
              </div>
            </div>
            <div className="mt-3 mb-3 col-sm-7 d-flex align-items-center justify-content-center justify-content-sm-end">
              <div id="kt_table_users_paginate">
                <ul className="pagination mb-0">
                  <li className="page-item" onClick={() => previousPage()}>
                    <a className="page-link prev-angle">
                      <i className="fa fa-chevron-left"></i>
                    </a>
                  </li>
                  {pageOptions.map((pageSize, rowIndex) => {
                    if (currentPageStartIndex <= rowIndex) {
                      currentPageStartIndex = currentPageStartIndex + 1;
                      previousIndexPage = rowIndex - 1;
                    }
                    return previousIndexPage <= currentPageStartIndex ? (
                      <li
                        className={`page-item ${pageIndex == 0 && rowIndex == 0
                          ? "active"
                          : pageIndex + 1 == rowIndex + 1
                            ? "active"
                            : ""
                          }`}
                        onClick={() => gotoPage(rowIndex)}
                      >
                        <a className="page-link">{pageSize + 1} </a>
                        {/* {pageIndex + 1} */}
                      </li>
                    ) : null;
                  })}
                  <li className="page-item" onClick={() => nextPage()}>
                    <a className="page-link next-angle">
                      {/* {'Next »'} */}
                      <i className="fa fa-chevron-right"></i>
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }

  const saveBtn = () => {
    setBtnLoading(true)
    let payload = {
      company_id: getCompanyId,
      name: name,
      phone_number: phone_number,
      address: address,
    }
    axiosClient.post('/company-sites', payload)
      .then((res) => {
        toast.success(res.data.message, {
          position: "bottom-right",
          autoClose: 1500,
          theme: "colored",
        });
        setBtnLoading(false)
        mainRequest()
        handleAddCloseSiteModal()
        refreshEditCompany()
      })
      .catch(err => {
        setBtnLoading(false)
        var firstErrorMessage = '';
        if (err.response.data.errors && Object.keys(err.response.data.errors).length != 0) {
          var errors = err.response.data.errors
          const errorMessages = Object.values(errors); // Get an array of all error messages
          firstErrorMessage = errorMessages.shift(); // Get the first error message
          toast.error(firstErrorMessage[0], {
            position: "bottom-right",
            autoClose: 1500,
            theme: "colored",
          });
        } else {
          // console.log('firstErrorMessage',firstErrorMessage[0])
          toast.error(err.response.data.message, {
            position: "bottom-right",
            autoClose: 1500,
            theme: "colored",
          });
        }
        // console.log(err);
      });

  };

  const updateBtn = () => {
    setBtnLoading(true)
    let payload = {
      name: name,
      phone_number: phone_number,
      address: address,
    }
    axiosClient.put('/company-sites/'+getCompanySiteId, payload)
      .then((res) => {
        toast.success(res.data.message, {
          position: "bottom-right",
          autoClose: 1500,
          theme: "colored",
        });
        setBtnLoading(false)
        mainRequest()
        handleEditCloseSiteModal()
        refreshEditCompany()
      })
      .catch(err => {
        setBtnLoading(false)
        var firstErrorMessage = '';
        if (err.response.data.errors && Object.keys(err.response.data.errors).length != 0) {
          var errors = err.response.data.errors
          const errorMessages = Object.values(errors); // Get an array of all error messages
          firstErrorMessage = errorMessages.shift(); // Get the first error message
          toast.error(firstErrorMessage[0], {
            position: "bottom-right",
            autoClose: 1500,
            theme: "colored",
          });
        } else {
          // console.log('firstErrorMessage',firstErrorMessage[0])
          toast.error(err.response.data.message, {
            position: "bottom-right",
            autoClose: 1500,
            theme: "colored",
          });
        }
        // console.log(err);
      });

  };

  const editSiteModalBtn = async (data) => {
    setEditComponentLoading(true);
    setEditSiteModal(true);
    let config = {
        method: "get",
        url: process.env.REACT_APP_API_URL + "/company-sites/"+data.id+"/get",
        headers: {
          Accept: "application/json",
          Authorization: `Bearer ${accessToken}`,
        },
      };
  
      axios(config)
      .then((response) => {
        setCompanySiteId(response.data.CompanySite.id);
        set_name(response.data.CompanySite.name)
        set_phone_number(response.data.CompanySite.phone_number)
        set_address(response.data.CompanySite.address)
        setEditComponentLoading(false);
      })
      .catch((error) => {
        setEditComponentLoading(false);
      });
  };

  const disableBtn = async (data) => {
    Swal.fire({
      title: "Are you sure you want to disable?",
      icon: "question",
      confirmButtonColor: "#f32013",
      cancelButtonColor: "#d33",
      confirmButtonText: "Disable",
      showCancelButton: true,
    }).then((result) => {
      if (result.isConfirmed) {
        let payload = {
          id: data.id,
          status: "disabled",
        };
        axiosClient.put("/companies/"+data.id+'/status', payload).then(() => {
          mainRequest();
          toast.success("Disabled successfully", {
            position: "bottom-right",
            autoClose: 1500,
            theme: "colored",
          });
        });
      }
    });
  };

  const activeUser = async (data) => {
    Swal.fire({
      title: "Are you sure you want to activate?",
      icon: "question",
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Activate",
      showCancelButton: true,
    }).then((result) => {
      if (result.isConfirmed) {
        let payload = {
          id: data.id,
          status: "active",
        };
        axiosClient.put("/companies/"+data.id+'/status', payload).then(() => {
          mainRequest();
          toast.success("Activated successfully", {
            position: "bottom-right",
            autoClose: 1500,
            theme: "colored",
          });
        });
      }
    });
  };

  const deleteBtn = async (data) => {
    Swal.fire({
      title: "Are you sure want to delete?",
      icon: "question",
      confirmButtonColor: "#f32013",
      cancelButtonColor: "#d33",
      confirmButtonText: "Delete",
      showCancelButton: true,
    }).then((result) => {
      if (result.isConfirmed) {
        setComponentLoading(true)
        let payload = {
          id: data.id,
          status: "deleted",
        };
        // console.log('data=',data)
        axiosClient.delete("/company-sites/"+companyId+"/"+data.id, payload).then((res) => {
          mainRequest();
          toast.success(res.data.message, {
            position: "bottom-right",
            autoClose: 1500,
            theme: "colored",
          });
          refreshEditCompany()
          setComponentLoading(false)
      });
      }
    });
  };

  useEffect(() => {
    // if(role != 'admin'){
    //   navigate('/companies')
    // }
    // console.log('companyId=',companyId)
    if(companyId){
      setCompanyId(companyId)
    }
    console.log('getCompanyId=',getCompanyId)
  }, [companyId, userType]);
  
  useEffect(() => {
    if(companyId){
      mainRequest();
    }
  }, [companyId,stMainUser]);
  
  const columns = React.useMemo(
    () => [
      {
        Header: "Name",
        accessor: "name",
        Cell: ({ row }) => {
          const data = row.original;
          return data.name;
        },
      },
      {
        Header: "Phone Number",
        accessor: "phone_number",
        Cell: ({ row }) => {
          const data = row.original;
          return data.phone_number;
        },
      },
      {
        Header: "Address",
        accessor: "address",
        Cell: ({ row }) => {
          const data = row.original;
          return data.address;
        },
      },
      {
        Header: "Action",
        accessor: "action",
        disableSortBy: true,
        Cell: ({ row }) => {
          const data = row.original;
          return (
            <>
              <div className="actionButtons">
                
                {
                  !data.type && (
                    <>
                    <div class="dropdown">
                      <button class="btn btn-secondary dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                        <Icon.MoreVertical />
                      </button>
                      <ul class="dropdown-menu">
                        <li>
                          <button
                            className="dropdown-item"
                            aria-expanded="false"
                            onClick={() => {
                              editSiteModalBtn(data);
                            }}
                          >
                            <span className="btn btn-primary btnIcon">
                              <Icon.Eye />
                            </span>
                            Edit
                          </button>
                        </li>
                        <li>
                          <button
                            className="dropdown-item"
                            aria-expanded="false"
                            onClick={() => {
                              deleteBtn(data);
                            }}
                          >
                            <span className="btn btn-danger btnIcon">
                              <Icon.Trash />
                            </span>
                            Delete
                          </button>
                        </li>
                      </ul>
                    </div>
                    </>
                  )
                }
                  {/* <OverlayTrigger
                    trigger={['hover', 'focus']}
                    placement="top"
                    overlay={<Tooltip id="example-tooltip">Edit Site</Tooltip>}
                  >
                    <button
                      className="btn btn-primary ml-1 mb-1"
                      aria-expanded="false"
                      onClick={() => {
                        editSiteModalBtn(data);
                      }}
                    >
                      <Icon.Edit />
                    </button>
                  </OverlayTrigger>

                  <OverlayTrigger
                    trigger={['hover', 'focus']}
                    placement="top"
                    overlay={<Tooltip id="example-tooltip">Delete Site</Tooltip>}
                  >
                    <button
                      className="btn btn-danger ml-1 mb-1"
                      aria-expanded="false"
                      onClick={() => {
                        deleteBtn(data);
                      }}
                    >
                      <Icon.Trash />
                    </button>
                  </OverlayTrigger> */}
              </div>
            </>
          );
        },
      },
    ],
    []
  );

  const data = getCompanySites;

  return (
    <>

      {/* Add Site Modal */}
      <Modal
        className="customModal" // hide-btn-close
        backdrop="static"
        centered
        size="xl"
        show={showUserModal}
        onHide={handleAddCloseSiteModal}
      >
        <Modal.Header closeButton>
          <Modal.Title><img className="modalLogo" src={logo} /> Add New Site</Modal.Title>
          {/* <button
            type="button"
            className="btnClose custom"
            onClick={() => { handleAddCloseSiteModal() }}
          >
            <i className="fa fa-close"></i>
          </button> */}
        </Modal.Header>
        <Modal.Body>
          <form>
            <div className="row">
              <div className="col-sm-3"></div>
              <div className="col-sm-6">
                <div className="row">
                  <div className="col-sm-12">
                      <div className="mb-3">
                          <label for="name" className="form-label">
                              Name
                          </label>
                          <input type="name" className="form-control" id="name" required value={name} onChange={e => set_name(e.target.value)} />
                      </div>
                  </div>
                  <div className="col-sm-12">
                      <div className="mb-3">
                          <label for="phone_number" className="form-label">
                              Phone Number
                          </label>
                          <input type="text" className="form-control" id="phone_number" required value={phone_number} onChange={e => set_phone_number(e.target.value)} />
                      </div>
                  </div>
                  <div className="col-sm-12">
                      <div className="mb-3">
                          <label for="address" className="form-label">
                              Address
                          </label>
                          <input type="text" className="form-control" id="address" required value={address} onChange={e => set_address(e.target.value)} />
                      </div>
                  </div>
                </div>
              </div>
              <div className="col-sm-3"></div>
            </div>
          </form>
        </Modal.Body>
        <Modal.Footer>
          <div className="row">
            <div className="col-sm-3"></div>
            <div className="col-sm-6">
              <div className="centeredBtns">
                <Button
                  variant="btn btn-outline-primary"
                  onClick={handleAddCloseSiteModal}
                >
                  Close
                </Button>
                <button type="submit" className="btn btn-primary" disabled={btnLoading} onClick={saveBtn}>
                  {
                    btnLoading ?
                      (
                        <>Please Wait <span className="spinner-grow spinner-grow-sm ml-2" role="status" aria-hidden="true"></span></>
                      ) : (
                        <>Save</>
                      )
                  }
                </button>
              </div>
            </div>
            <div className="col-sm-3"></div>
          </div>
        </Modal.Footer>
      </Modal>

      {/* Edit Site Modal */}
      <Modal
        className="customModal" // hide-btn-close
        backdrop="static"
        centered
        size="xl"
        show={showEditUserModal}
        onHide={handleEditCloseSiteModal}
      >
        <Modal.Header closeButton>
          <Modal.Title><img className="modalLogo" src={logo} /> Edit Site</Modal.Title>
          {/* <button
            type="button"
            className="btnClose custom"
            onClick={() => { handleEditCloseSiteModal() }}
          >
            <i className="fa fa-close"></i>
          </button> */}
        </Modal.Header>
        <Modal.Body>
          <form>
            <div className="formData">
              {getEditComponentLoading ? (
                <div className="card loadingCard">
                  <div className="card-body">
                    <ComponentLoading></ComponentLoading>
                  </div>
                </div>
              ) : (
                <div className="row">
                  <div className="col-sm-3"></div>
                  <div className="col-sm-6">
                    <div className="row">
                      <div className="col-sm-12">
                          <div className="mb-3">
                              <label for="name" className="form-label">
                                  Name
                              </label>
                              <input type="name" className="form-control" id="name" required value={name} onChange={e => set_name(e.target.value)} />
                          </div>
                      </div>
                      <div className="col-sm-12">
                          <div className="mb-3">
                              <label for="phone_number" className="form-label">
                                  Phone Number
                              </label>
                              <input type="text" className="form-control" id="phone_number" required value={phone_number} onChange={e => set_phone_number(e.target.value)} />
                          </div>
                      </div>
                      <div className="col-sm-12">
                          <div className="mb-3">
                              <label for="address" className="form-label">
                                  Address
                              </label>
                              <input type="text" className="form-control" id="address" required value={address} onChange={e => set_address(e.target.value)} />
                          </div>
                      </div>
                    </div>
                  </div>
                <div className="col-sm-3"></div>
              </div>
              )}
            </div>
          </form>
        </Modal.Body>
        <Modal.Footer>
          <div className="row">
            <div className="col-sm-3"></div>
            <div className="col-sm-6">
              <div className="centeredBtns">
                <Button
                  variant="btn btn-outline-primary"
                  onClick={handleEditCloseSiteModal}
                >
                  Close
                </Button>
                <button type="submit" className="btn btn-primary" disabled={btnLoading} onClick={updateBtn}>
                  {
                    btnLoading ?
                      (
                        <>Please Wait <span className="spinner-grow spinner-grow-sm ml-2" role="status" aria-hidden="true"></span></>
                      ) : (
                        <>Save</>
                      )
                  }
                </button>
              </div>
            </div>
            <div className="col-sm-3"></div>
          </div>
        </Modal.Footer>
      </Modal>

      {/* Page Content */}
      {getComponentLoading ? (
        <div className="card loadingCard">
          <div className="card-body">
            <ComponentLoading></ComponentLoading>
          </div>
        </div>
      ) : (
        <div className="card customCard">
          <Table columns={columns} data={data} />
        </div>
      )}
    </>
  );
};

export default CompanyDetailsPage;
