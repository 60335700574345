import { useState, useEffect } from "react";
import { Link, useNavigate, useLocation, useParams } from "react-router-dom";
import axios from "axios";
import axiosClient from "../axios-client";
import { ToastContainer, toast } from "react-toastify";
import * as Icon from "react-feather";

const Signup = () => {

    let { userId, token } = useParams();

    const navigate = useNavigate();
    const location = useLocation();

    //state to store our password 
    const [password, setPassword] = useState("");
    const [confirm_password, setConfirm_password] = useState("")
    const [btnLoading, setBtnLoading] = useState(false)
    const [error, setError] = useState("");
  
    const [passwordType, setPasswordType] = useState(false);
    const [confirmPasswordType, setConfirmPasswordType] = useState(false)

    const handleSubmit = (e) => {
        setBtnLoading(true)
        e.preventDefault();
        const payload = {
            password: password,
            confirm_password: confirm_password,
            userId: userId,
            token: token,
        }

        axiosClient.post('/reset-password', payload)
            .then((response) => {
                setBtnLoading(false)
                console.log('response', response)
                // response.json({ message: 'Request received!', response })
                toast.success(response.data.message, {
                    position: "bottom-right",
                    autoClose: 1500,
                    theme: "colored",
                });
                // setPassword("");
                // setConfirm_password("");
                navigate("/login")
            }).catch(err => {
                setBtnLoading(false)
                var firstErrorMessage = '';
                if (err.response.data.errors && Object.keys(err.response.data.errors).length != 0) {
                    var errors = err.response.data.errors
                    const errorMessages = Object.values(errors); // Get an array of all error messages
                    firstErrorMessage = errorMessages.shift(); // Get the first error message
                    toast.error(firstErrorMessage[0], {
                        position: "bottom-right",
                        autoClose: 1500,
                        theme: "colored",
                    });
                } else {
                    // console.log('firstErrorMessage',firstErrorMessage[0])
                    toast.error(err.response.data.message, {
                        position: "bottom-right",
                        autoClose: 1500,
                        theme: "colored",
                    });
                }
                //   console.log(err);
            });

    }


    return (
        <div>

            <div className="container darkMode">
                <div className="centerForm">
                    <div className="authForm box-shadow-1 mb-4">
                        <form onSubmit={handleSubmit}>

                            <h4 className="mt-1 text-white">Reset Password</h4>
                            <p className="text-white">Welcome Palenti Cars</p>

                            <div className="form-outline mb-3">
                                <div className="inputIcon">
                                <input type={passwordType?("text"):("password")} className="form-control" placeholder="Password" aria-label="Username" aria-describedby="addon-wrapping" value={password} onChange={e => setPassword(e.target.value)} />
                                <div className="inputIcons">
                                    {
                                    passwordType?
                                    (
                                        <span onClick={() => {setPasswordType(false)}} className="eyeOff"><Icon.EyeOff /></span>
                                    ):(
                                        <span onClick={() => {setPasswordType(true)}} className="eye"><Icon.Eye /></span>
                                    )
                                    }
                                </div>
                                </div>
                            </div>

                            <div className="form-outline mb-3">
                                <div className="inputIcon">
                                <input type={confirmPasswordType?("text"):("password")} className="form-control" placeholder="Confirm Password" aria-label="Username" aria-describedby="addon-wrapping" value={confirm_password} onChange={e => setConfirm_password(e.target.value)} />
                                <div className="inputIcons">
                                    {
                                    confirmPasswordType?
                                    (
                                        <span onClick={() => {setConfirmPasswordType(false)}} className="eyeOff"><Icon.EyeOff /></span>
                                    ):(
                                        <span onClick={() => {setConfirmPasswordType(true)}} className="eye"><Icon.Eye /></span>
                                    )
                                    }
                                </div>
                                </div>
                            </div>

                            <div className="d-grid">
                                <button type="submit" className="btn btn-primary btn-block" disabled={btnLoading}>
                                    {
                                        btnLoading ?
                                            (
                                                <>Please Wait <span className="spinner-grow spinner-grow-sm ml-2" role="status" aria-hidden="true"></span></>
                                            ) : (
                                                <>Reset Password</>
                                            )
                                    }
                                </button>
                            </div>

                        </form>
                    </div>
                    <div className="text-center">
                        <p className="text-white">
                            <span>Already registered? </span>
                            <Link to="/login" className="ml-2">Login</Link>
                        </p>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Signup;