// ** Toolkit imports
import { configureStore } from '@reduxjs/toolkit';

// ** Reducers
import stPowerUser from './apps/powerUser';
import stMainUser from './apps/mainUser';
// import { AppDispatch, RootState } from './types';

export const store = configureStore({
  reducer: {
    stPowerUser,
    stMainUser,
  },
  middleware: getDefaultMiddleware =>
    getDefaultMiddleware({
      serializableCheck: false
    })
});

// rest of your code
