// ** Redux Imports
import { Dispatch } from 'redux'
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'

// ** Axios Imports
import axios from 'axios'

// ** Token
const accessToken = localStorage.getItem("ACCESS_TOKEN");

// // ** Fetch Roles
export const stCompanyId = createAsyncThunk('appPowerUser/stCompanyId', async (data) => {
  return data.user.company_id;
})

// // ** Fetch Roles
export const stGetIndustriesData = createAsyncThunk('appPowerUser/stGetIndustriesData', async (data) => {

  // console.log('accessToken============>>>>>>>>>>>',accessToken)
  // console.log('data============>>>>>>>>>>>',data.user.company_id)

  const response = await axios.get(process.env.REACT_APP_API_URL + "/companies", {
    headers: {
      Authorization: "Bearer "+accessToken
    }
  })
  return response.data
})

// ** Fetch Power user company data **
export const stGetCompanyData = createAsyncThunk('appPowerUser/stGetCompanyData', async (data) => {

  const response = await axios.get(process.env.REACT_APP_API_URL + "/companies/"+data.user.company_id, {
    headers: {
      Authorization: "Bearer "+accessToken
    }
  })
  return response.data
})

// ** Fetch Power user company data **
export const stFetchCompanySites = createAsyncThunk('appPowerUser/stFetchCompanySites', async (data) => {

  const response = await axios.get(process.env.REACT_APP_API_URL + "/company-sites/"+data.user.company_id, {
    headers: {
      Authorization: "Bearer "+accessToken
    }
  })
  return response.data
})

// ** Fetch Power user company data **
export const stFetchCompanyEmployees = createAsyncThunk('appPowerUser/stFetchCompanyEmployees', async (data) => {

  const response = await axios.get(process.env.REACT_APP_API_URL + "/employees/"+data.user.company_id, {
    headers: {
      Authorization: "Bearer "+accessToken
    }
  })
  return response.data
})

// // ** Get Mode
// export const stActionMode = createAsyncThunk('appPowerUser/stActionMode', async (params) => {
//   // console.log('stActionMode params=',params)
//   return params
// })

// // ** Response
// export const stGetResponse = createAsyncThunk('appPowerUser/stGetResponse', async (params) => {
//   console.log('stGetResponse params=',params)
//   return params
// })

export const appPowerUserSlice = createSlice({
  name: 'appPowerUser',
  initialState: {
    stCompanyId: '',
    stGetIndustriesData: [],
    stGetCompanyData: {},
    stFetchCompanySites: [],
    stFetchCompanyEmployees: [],
    // stFetchCompanySites: [],
    // stActionMode: {},
    // stGetResponse: {},
  },
  reducers: {},
  extraReducers: builder => {
    // Handle the fulfilled action of stEditData
    builder.addCase(stCompanyId.fulfilled, (state, action) => {
      state.stCompanyId = action.payload;
    })
    // Handle the fulfilled action of stEditData
    builder.addCase(stGetIndustriesData.fulfilled, (state, action) => {
      state.stGetIndustriesData = action.payload;
    })
    // Handle the fulfilled action of Company Data
    builder.addCase(stGetCompanyData.fulfilled, (state, action) => {
      state.stGetCompanyData = action.payload;
    })
    // Handle the fulfilled action of Company Sites Data
    builder.addCase(stFetchCompanySites.fulfilled, (state, action) => {
      state.stFetchCompanySites = action.payload;
    })
    // Handle the fulfilled action of Company Employees Data
    builder.addCase(stFetchCompanyEmployees.fulfilled, (state, action) => {
      state.stFetchCompanyEmployees = action.payload;
    })
  }
})

export default appPowerUserSlice.reducer
