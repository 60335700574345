import React, { useContext, useEffect, useRef, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import axiosClient from "../axios-client";
import Userimg from "../images/man-2.png";
import axios from "axios";
import {
  useTable,
  useFilters,
  useGlobalFilter,
  useSortBy,
  useAsyncDebounce,
  usePagination,
} from "react-table";
import * as Icon from "react-feather";
import { ComponentLoading } from "../Components/ComponentLoading";
import { ToastContainer, toast } from "react-toastify";
import Swal from "sweetalert2";
import { Modal, Button, Nav, Tab, Row, Col } from "react-bootstrap";
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import moment from 'moment';
import Select from 'react-select'
import { ActionMeta, OnChangeValue } from 'react-select';
import { useStateContext } from "../Context/ContextProvider";

import logo from '../images/cr-computer.png'

// Import Components
import CompaniesEditModal from '../PageComponents/CompaniesEditModal';
import CompaniesOtherSiteAddresses from '../PageComponents/CompaniesOtherSiteAddresses';
import CompaniesOtherSitePhoneNumbers from '../PageComponents/CompaniesOtherSitePhoneNumbers';

//token
const accessToken = localStorage.getItem("ACCESS_TOKEN");

const CompaniesPage = (props) => {

  const { user, role, notification } = useStateContext();
  const navigate = useNavigate();

  const [getCompanies, setCompanies] = useState([]);
  const [getIndustries, setIndustries] = useState([]);

  const [getComponentLoading, setComponentLoading] = useState(true);
  const [getEditComponentLoading, setEditComponentLoading] = useState(true);
  const [getPhonesComponentLoading, setPhonesComponentLoading] = useState(true);

  // Edit Company Modal
  const [showEditCompanyModal, setShowEditCompanyModal] = useState(false);
  const handleEditCompanyModalClose = () => setShowEditCompanyModal(false);
  const handleEditCompanyModalShow = () => setShowEditCompanyModal(true);

  // Password Modal
  const [showPasswordModal, setShowPasswordModal] = useState(false);
  const handlePasswordModalClose = () => setShowPasswordModal(false);
  const handlePasswordModalShow = () => setShowPasswordModal(true);

  // Company Modal
  const [showCompanyModal, setShowCompanyModal] = useState(false);
  const handleCompanyModalClose = () => setShowCompanyModal(false);
  const handleCompanyModalShow = () => {
    setShowCompanyModal(true)
    setCompanyId(null);
    setCompanyName('')
    setCompanyMainAddress('')
    setCompanyMainPhone('')
    setCompanyIndustry(null)
    setCompanyOtherSiteAddresses([])
    setCompanyOtherSitePhoneNumbers([])
  };

  const [newPasswordType, setNewPasswordType] = useState(false);
  const [confirmPasswordType, setConfirmPasswordType] = useState(false)

  const [btnLoading, setBtnLoading] = useState(false)

  //user id to save password
  const [userId, setUserId] = useState();
  const new_password = useRef(null);
  const confirm_new_password = useRef(null);

  const [getCompanyEmail, setCompanyEmail] = useState("");
  const [getCompanyId, setCompanyId] = useState(null);
  const [getCompanyName, setCompanyName] = useState("");
  const [getCompanyMainAddress, setCompanyMainAddress] = useState("");
  const [getCompanyMainPhone, setCompanyMainPhone] = useState("");
  const [getCompanyIndustry, setCompanyIndustry] = useState(null);
  const [getCompanyOtherSiteAddresses, setCompanyOtherSiteAddresses] = useState([]);
  const [getCompanyOtherSitePhoneNumbers, setCompanyOtherSitePhoneNumbers] = useState([]);

  const [getCompanyData, setCompanyData] = useState({});

  const handleChangeCompanyIndustry = (
    newValue: any,
    actionMeta: ActionMeta<any>
  ) => {
    setCompanyIndustry({id:newValue.id, name: newValue.name})
  };

  const companiesRequest = () => {
    let config = {
      method: "get",
      url: process.env.REACT_APP_API_URL + "/companies",
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${accessToken}`,
      },
    };

    axios(config)
    .then((response) => {
      const updatedRecords = response.data.companies.map(record => {
        if (record.status === 'inactive') {
          return { ...record, status: 'pending' };
        }
        return record;
      });
        // var dummyCompanies = {
        //     "companies": [
        //         {
        //             id: 1,
        //             name: "Google LLC",
        //             industry: "Technology",
        //             main_address: "1600 Amphitheatre Parkway, Mountain View, CA 94043, United States",
        //             main_phone: "+1 (650) 253-0000",
        //             other_site_address:	"345 Spear Street, San Francisco, CA 94105, United States",
        //             other_site_phone: "+1 (415) 736-0000"
        //             },
        //             {
        //             id: 2,
        //                 name: "Tesla, Inc.",
        //             industry: "Automotive",
        //             main_address: "3500 Deer Creek Road, Palo Alto, CA 94304, United States",
        //             main_phone: "+1 (650) 681-5000",
        //             other_site_address:	"900 Cherry Avenue, San Bruno, CA 94066, United States",
        //             other_site_phone: "+1 (650) 681-5100"
        //             },
        //             {
        //             id: 3,
        //                 name: "Microsoft Corporation",
        //             industry: "Technology",
        //             main_address: "One Microsoft Way, Redmond, WA 98052, United States",
        //             main_phone: "+1 (425) 882-8080",
        //             other_site_address:	"265-273 Corporation Street, Birmingham B4 7DX, United Kingdom",
        //             other_site_phone: "+44 (0)345 600 2001"
        //             },
        //             {
        //             id: 4,
        //                 name: "Coca-Cola Company",
        //             industry: "Beverage",
        //             main_address: "One Coca-Cola Plaza, Atlanta, GA 30313, United States",
        //             main_phone: "+1 (404) 676-2121",
        //             other_site_address:	"85/2 Hazaribagh Road, Dhaka 1209, Bangladesh",
        //             other_site_phone: "+880 2-9860387"
        //             },
        //             {
        //             id: 5,
        //                 name: "Amazon.com, Inc.",
        //             industry: "E-commerce",
        //             main_address: "410 Terry Avenue North, Seattle, WA 98109, United States",
        //             main_phone: "+1 (206) 266-1000",
        //             other_site_address:	"Rue du Marché 15, 1204 Geneva, Switzerland",
        //             other_site_phone: "+41 22 719 01 00"
        //             },
        //     ]
        // }
        // const updatedRecords = dummyCompanies.companies.map(record => {
        //     if (record.status === 'inactive') {
        //         return { ...record, status: 'pending' };
        //     }
        //     return record;
        // });
        setCompanies(updatedRecords);
        setIndustries(response.data.industries);
        setComponentLoading(false);
    })
    .catch((error) => {
      setComponentLoading(false);
    });
  };

  function GlobalFilter(props) {
    const { setGlobalFilter } = props;
    const count = props.preGlobalFilteredRows.length;
    const [value, setValue] = React.useState(props.globalFilter);
    const onChange = useAsyncDebounce((value) => {
      setGlobalFilter(value || undefined);
    }, 200);
    return (
      <div className="d-flex justify-content-between">
        <div className="pt-3 pl-3 pr-3 pb-3">
          <div className="card-toolbar">
            <div
              className="d-flex justify-content-center m-auto"
              data-kt-customer-table-toolbar="base"
            >
              {/* <div className="form-control d-flex justify-content-center align-items-center position-relative">
                <select
                  className="form-select form-select-sm form-select-solid px-3 m-auto dropDownRemove"
                  onChange={(e) => {
                    setGlobalFilter(e.target.value || undefined);
                  }}
                >
                  <option className="icon" value="">
                    All
                  </option>
                  <option value="active">Active</option>
                  <option value="pending">Pending</option>
                  <option value="disabled">Disable</option>
                </select>
              </div> */}
            </div>
          </div>
        </div>
        <div className="pt-3 pl-3 pr-3 pb-3">
          <div className="card-toolbar">
            <div
              className="d-flex justify-content-end"
              data-kt-customer-table-toolbar="base"
            >
              <div className="customTableSearch d-flex align-items-center position-relative">
                <span className="icon">
                  <Icon.Search />
                </span>
                <input
                  type="text"
                  data-kt-user-table-filter="search"
                  className="form-control"
                  placeholder="Search"
                  value={value || ""}
                  onChange={(e) => {
                    setValue(e.target.value);
                    onChange(e.target.value);
                  }}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }

  function DefaultColumnFilter({
    column: { filterValue, preFilteredRows, setFilter },
  }) {
    const count = preFilteredRows.length;
    return (
      <input
        type="text"
        data-kt-user-table-filter="search"
        className="customTableSearch form-control form-control-solid w-250px ps-14"
        value={filterValue || ""}
        onChange={(e) => {
          setFilter(e.target.value || undefined);
        }}
        placeholder={`Search ${count} records...`}
      />
    );
  }

  function Table(props) {
    const { columns, data } = props;
    const defaultColumn = React.useMemo(
      () => ({
        // Default Filter UI
        Filter: DefaultColumnFilter,
      }),
      []
    );
    const setLocalStorage = (pageSize) => {
      // console.log('pageSize',pageSize)
      localStorage.setItem("pageSize", pageSize);
    };

    const {
      getTableProps,
      getTableBodyProps,
      headerGroups,
      rows,
      prepareRow,
      // state,
      preGlobalFilteredRows,
      setGlobalFilter,
      page,
      // canPreviousPage,
      // canNextPage,
      pageOptions,
      pageCount,
      gotoPage,
      nextPage,
      previousPage,
      setPageSize,
      state: { pageIndex, pageSize, globalFilter },
    } = useTable(
      {
        columns,
        data,
        defaultColumn,
        initialState: {
          pageIndex: 0,
          pageSize: localStorage.getItem("pageSize")
            ? Number(localStorage.getItem("pageSize"))
            : 10,
        },
      },
      useFilters,
      useGlobalFilter,
      useSortBy,
      usePagination
    );
    let currentPageStartIndex = 2;
    let previousIndexPage = 0;
    return (
      <div>
        <GlobalFilter
          preGlobalFilteredRows={preGlobalFilteredRows}
          globalFilter={globalFilter}
          setGlobalFilter={setGlobalFilter}
        />
        <div className="table-responsive">
          <table
            className="table table-striped dataTable mb-0"
            {...getTableProps()}
          >
            {headerGroups.map((headerGroup) => (
              <tr
                className="tableHeader"
                {...headerGroup.getHeaderGroupProps()}
              >
                {headerGroup.headers.map((column) => (
                  // Add the sorting props to control sorting. For this example
                  // we can add them into the header props
                  <th
                    className=""
                    {...column.getHeaderProps(column.getSortByToggleProps())}
                  >
                    {column.render("Header")}
                    {/* Add a sort direction indicator */}
                    {
                      column.render("Header") != 'Action' && (
                        <span>
                          {column.isSorted ? (
                            column.isSortedDesc ? (
                              <span className="table-sort-asc"></span>
                            ) : (
                              <span className="table-sort-desc"></span>
                            )
                          ) : (
                            ""
                          )}
                        </span>
                      )
                    }
                  </th>
                ))}
              </tr>
            ))}
            <tbody className="" {...getTableBodyProps()}>
              {page.length > 0 ? (
                <>
                  {page.map((row, i) => {
                    prepareRow(row);
                    return (
                      <tr {...row.getRowProps()}>
                        {row.cells.map((cell) => {
                          return (
                            <td {...cell.getCellProps()}>
                              {cell.render("Cell")}
                            </td>
                          );
                        })}
                      </tr>
                    );
                  })}
                </>
              ) : (
                <tr>
                  <td colSpan={6}>
                    <div className="card-body text-center bg-light tableDataNotFound">
                      No data found
                    </div>
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
        <div className="pl-3 pr-3">
          <div className="row">
            <div className="mt-3 mb-3 col-sm-5 d-flex align-items-center justify-content-center justify-content-sm-start">
              <div className="dataTables_length" id="kt_customers_table_length">
                <select
                  name="kt_customers_table_length"
                  className="form-select form-select-sm form-select-solid"
                  value={pageSize}
                  onChange={(e) => {
                    {
                      setPageSize(Number(e.target.value));
                      setLocalStorage(e.target.value);
                    }
                  }}
                  style={{ width: "120px", height: "38px" }}
                >
                  {[10, 20, 30, 40, 50].map((pageSize) => (
                    <option key={pageSize} value={pageSize}>
                      {pageSize}
                    </option>
                  ))}
                </select>
              </div>
            </div>
            <div className="mt-3 mb-3 col-sm-7 d-flex align-items-center justify-content-center justify-content-sm-end">
              <div id="kt_table_users_paginate">
                <ul className="pagination mb-0">
                  <li className="page-item" onClick={() => previousPage()}>
                    <a className="page-link prev-angle">
                      <i className="fa fa-chevron-left"></i>
                    </a>
                  </li>
                  {pageOptions.map((pageSize, rowIndex) => {
                    if (currentPageStartIndex <= rowIndex) {
                      currentPageStartIndex = currentPageStartIndex + 1;
                      previousIndexPage = rowIndex - 1;
                    }
                    return previousIndexPage <= currentPageStartIndex ? (
                      <li
                        className={`page-item ${pageIndex == 0 && rowIndex == 0
                          ? "active"
                          : pageIndex + 1 == rowIndex + 1
                            ? "active"
                            : ""
                          }`}
                        onClick={() => gotoPage(rowIndex)}
                      >
                        <a className="page-link">{pageSize + 1} </a>
                        {/* {pageIndex + 1} */}
                      </li>
                    ) : null;
                  })}
                  <li className="page-item" onClick={() => nextPage()}>
                    <a className="page-link next-angle">
                      {/* {'Next »'} */}
                      <i className="fa fa-chevron-right"></i>
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }

  const setPassword = () => {
    setBtnLoading(true)
    // console.log(userId)
    let payload = {
      id: userId,
      new_password: new_password.current.value,
      confirm_new_password: confirm_new_password.current.value,
    }
    axiosClient.post('setPassword', payload)
      .then(() => {
        setBtnLoading(false)
        toast.success("Password Saved", {
          position: "bottom-right",
          autoClose: 1500,
          theme: "colored",
        });
        //close model
        handlePasswordModalClose();
      })
      .catch(err => {
        setBtnLoading(false)
        var firstErrorMessage = '';
        if (err.response.data.errors && Object.keys(err.response.data.errors).length != 0) {
          var errors = err.response.data.errors
          const errorMessages = Object.values(errors); // Get an array of all error messages
          firstErrorMessage = errorMessages.shift(); // Get the first error message
          toast.error(firstErrorMessage[0], {
            position: "bottom-right",
            autoClose: 1500,
            theme: "colored",
          });
        } else {
          // console.log('firstErrorMessage',firstErrorMessage[0])
          toast.error(err.response.data.message, {
            position: "bottom-right",
            autoClose: 1500,
            theme: "colored",
          });
        }
        // console.log(err);
      });

  };

  const saveBtn = () => {
    setBtnLoading(true)
    let payload = {
      name: getCompanyName,
      main_address: getCompanyMainAddress,
      main_phone: getCompanyMainPhone,
      industry_id: getCompanyIndustry,
      other_site_addresses: getCompanyOtherSiteAddresses,
      other_site_phone_numbers: getCompanyOtherSitePhoneNumbers,
    }
    axiosClient.post('/companies', payload)
      .then((res) => {
        toast.success(res.data.message, {
          position: "bottom-right",
          autoClose: 1500,
          theme: "colored",
        });
        setBtnLoading(false)
        companiesRequest()
        handleCompanyModalClose() // close company model
      })
      .catch(err => {
        setBtnLoading(false)
        var firstErrorMessage = '';
        if (err.response.data.errors && Object.keys(err.response.data.errors).length != 0) {
          var errors = err.response.data.errors
          const errorMessages = Object.values(errors); // Get an array of all error messages
          firstErrorMessage = errorMessages.shift(); // Get the first error message
          toast.error(firstErrorMessage[0], {
            position: "bottom-right",
            autoClose: 1500,
            theme: "colored",
          });
        } else {
          // console.log('firstErrorMessage',firstErrorMessage[0])
          toast.error(err.response.data.message, {
            position: "bottom-right",
            autoClose: 1500,
            theme: "colored",
          });
        }
        // console.log(err);
      });

  };

  const updateBtn = () => {
    setBtnLoading(true)
    let payload = {
      name: getCompanyName,
      main_address: getCompanyMainAddress,
      main_phone: getCompanyMainPhone,
      industry_id: getCompanyIndustry,
      other_site_addresses: getCompanyOtherSiteAddresses,
      other_site_phone_numbers: getCompanyOtherSitePhoneNumbers,
    }
    axiosClient.put('/companies/'+getCompanyId, payload)
      .then((res) => {
        toast.success(res.data.message, {
          position: "bottom-right",
          autoClose: 1500,
          theme: "colored",
        });
        setBtnLoading(false)
        companiesRequest()
        handleEditCompanyModalClose() // close company model
      })
      .catch(err => {
        setBtnLoading(false)
        var firstErrorMessage = '';
        if (err.response.data.errors && Object.keys(err.response.data.errors).length != 0) {
          var errors = err.response.data.errors
          const errorMessages = Object.values(errors); // Get an array of all error messages
          firstErrorMessage = errorMessages.shift(); // Get the first error message
          toast.error(firstErrorMessage[0], {
            position: "bottom-right",
            autoClose: 1500,
            theme: "colored",
          });
        } else {
          // console.log('firstErrorMessage',firstErrorMessage[0])
          toast.error(err.response.data.message, {
            position: "bottom-right",
            autoClose: 1500,
            theme: "colored",
          });
        }
        // console.log(err);
      });

  };

  const updatePassword = async (data) => {
    setShowPasswordModal(true);
    setUserId(data.id);
  };

  const editCompanyModalBtn = async (data) => {
    setCompanyData(data)
    setEditComponentLoading(true)
    setShowEditCompanyModal(true)
    let config = {
        method: "get",
        url: process.env.REACT_APP_API_URL + "/companies/"+data.id,
        headers: {
          Accept: "application/json",
          Authorization: `Bearer ${accessToken}`,
        },
      };
  
      axios(config)
      .then((response) => {
        setCompanyId(response.data.company.id);
        setCompanyName(response.data.company.name)
        setCompanyMainAddress(response.data.company.main_address)
        setCompanyMainPhone(response.data.company.main_phone)
        if(response.data.company.industry){
            setCompanyIndustry(response.data.company.industry)
        }
        if(response.data.company.company_other_site_addresses_data){
            setCompanyOtherSiteAddresses(response.data.company.company_other_site_addresses_data)
        } else {
            setCompanyOtherSiteAddresses([])
        }
        if(response.data.company.company_other_site_phone_numbers_data){
            setCompanyOtherSitePhoneNumbers(response.data.company.company_other_site_phone_numbers_data)
        } else {
            setCompanyOtherSitePhoneNumbers([])
        }
        setEditComponentLoading(false)
      })
      .catch((error) => {
        setEditComponentLoading(false);
      });
  };

  const disableBtn = async (data) => {
    Swal.fire({
      title: "Are you sure you want to disable?",
      icon: "question",
      confirmButtonColor: "#f32013",
      cancelButtonColor: "#d33",
      confirmButtonText: "Disable",
      showCancelButton: true,
    }).then((result) => {
      if (result.isConfirmed) {
        let payload = {
          id: data.id,
          status: "disabled",
        };
        axiosClient.put("/companies/"+data.id+'/status', payload).then(() => {
          companiesRequest();
          toast.success("Disabled successfully", {
            position: "bottom-right",
            autoClose: 1500,
            theme: "colored",
          });
        });
      }
    });
  };

  const activeUser = async (data) => {
    Swal.fire({
      title: "Are you sure you want to activate?",
      icon: "question",
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Activate",
      showCancelButton: true,
    }).then((result) => {
      if (result.isConfirmed) {
        let payload = {
          id: data.id,
          status: "active",
        };
        axiosClient.put("/companies/"+data.id+'/status', payload).then(() => {
          companiesRequest();
          toast.success("Activated successfully", {
            position: "bottom-right",
            autoClose: 1500,
            theme: "colored",
          });
        });
      }
    });
  };

  const deleteBtn = async (data) => {
    Swal.fire({
      title: "Are you sure want to delete?",
      icon: "question",
      confirmButtonColor: "#f32013",
      cancelButtonColor: "#d33",
      confirmButtonText: "Delete",
      showCancelButton: true,
    }).then((result) => {
      if (result.isConfirmed) {
        setComponentLoading(true)
        let payload = {
          id: data.id,
          status: "deleted",
        };
        // console.log('data=',data)
        axiosClient.delete("/companies/"+data.id, payload).then((res) => {
          companiesRequest();
          setComponentLoading(false)
          toast.success(res.data.message, {
            position: "bottom-right",
            autoClose: 1500,
            theme: "colored",
          });
        });
      }
    });
  };

  const handleOtherSiteAddressesChange = (addresses) => {
      console.log('addresses=',addresses)
      setCompanyOtherSiteAddresses(addresses);
  };
  
  const handleOtherSitePhoneNumbersChange = (phoneNumbers) => {
      console.log('phoneNumbers=',phoneNumbers)
      setCompanyOtherSitePhoneNumbers(phoneNumbers);
  };

  const viewCompanyDetailBtn = (data) => {
    navigate('/companies/'+data.id)
  }

  useEffect(()=>{
    if(role == 'employee'){
      navigate('/')
    }
  },[role])

  useEffect(() => {
    companiesRequest();
    // if(getCompanyIndustry != ''){
    //     getCompanyIndustry
    // }
  }, [getCompanyIndustry]);

  const columns = React.useMemo(
    () => [
    //   {
    //     Header: "Company Name",
    //     accessor: "name",
    //     Cell: ({ row }) => {
    //       const data = row.original;
    //       var charAt = data.name?data.name.slice(0, 1).toUpperCase():data.email.slice(0, 1).toUpperCase()
    //       return (
    //         <>
    //           <div className="shortAvatar">
    //             <div className="avatarSm d-flex">

    //               <img className={"symbol-circle bg-" + charAt + " img-alt"} src={data.avatar} alt={charAt} />

    //             </div>
    //             <strong className="pl-2">{data.name}</strong>
    //           </div>
    //         </>
    //       );
    //     },
    //   },
      {
        Header: "Name",
        accessor: "name",
        Cell: ({ row }) => {
          const data = row.original;
          return data.name;
        },
      },
      {
        Header: "Phone Number",
        accessor: "main_phone",
        Cell: ({ row }) => {
          const data = row.original;
          return data.main_phone;
        },
      },
      {
        Header: "Address",
        accessor: "main_address",
        Cell: ({ row }) => {
          const data = row.original;
          return data.main_address;
        },
      },
      {
        Header: "Industry",
        accessor: "industry",
        Cell: ({ row }) => {
          const data = row.original;
          if(data.industry){
            return data.industry.name?data.industry.name:'N/A';
          } else {
            return 'N/A'
          }
        },
      },
    //   {
    //     Header: "Created At",
    //     accessor: "created_at",
    //     Cell: ({ row }) => {
    //       const data = row.original;
    //       const formattedDate = data.created_at ? moment(data.created_at).format('MMM D, YYYY h:mm a') : 'N/A';
    //       return formattedDate
    //     },
    //   },
    //   {
    //     Header: "Status",
    //     accessor: "status",
    //     Cell: ({ row }) => {
    //       const data = row.original;
    //       return (
    //         <>
    //           <OverlayTrigger
    //             trigger={['hover', 'focus']}
    //             placement="top"
    //             overlay={<Tooltip id="example-tooltip">
    //               {data.status == "active" ? <>Click the toggle to disable this company.</>
    //                 : data.status == "inactive" ? <>This company has not verified their email yet. Click the toggle to manually activate them</> : <>Click the toggle to enable this company.</>}
    //             </Tooltip>}
    //           >
    //             <label class="switch">

    //               <input
    //                 type="checkbox"
    //                 checked={data.status == "active" ? true : false}
    //                 onChange={() => {
    //                   if (data.status == "active") {
    //                     disableBtn(data);
    //                   } else {
    //                     activeUser(data);
    //                   }
    //                 }}
    //               />
    //               <span class={`slider round ${data.status == "inactive" ? 'bgcolorRed' : <></>} `}></span>
    //             </label>
    //           </OverlayTrigger>
    //         </>
    //       );
    //     },
    //   },
      {
        Header: "Action",
        accessor: "action",
        disableSortBy: true,
        Cell: ({ row }) => {
          const data = row.original;
          return (
            <>
              <div className="actionButtons">
                <div class="dropdown">
                  <button class="btn btn-secondary dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                    <Icon.MoreVertical />
                  </button>
                  <ul class="dropdown-menu">
                    <li>
                      <button
                        className="dropdown-item"
                        aria-expanded="false"
                        onClick={() => {
                          viewCompanyDetailBtn(data);
                        }}
                      >
                        <span className="btn btn-primary btnIcon">
                        <Icon.Eye />
                        </span>
                        View
                      </button>
                    </li>
                    {
                      role == 'admin' && (
                        <>    
                        <li>
                          <button
                            className="dropdown-item"
                            aria-expanded="false"
                            onClick={() => {
                              editCompanyModalBtn(data);
                            }}
                          >
                            <span className="btn btn-primary btnIcon">
                              <Icon.Edit />
                            </span>
                            Edit
                          </button>
                        </li>
                        <li>
                          <button
                            className="dropdown-item"
                            aria-expanded="false"
                            onClick={() => {
                              deleteBtn(data);
                            }}
                          >
                            <span className="btn btn-danger btnIcon">
                              <Icon.Trash />
                            </span>
                            Delete
                          </button>
                        </li>
                        </>
                      )
                    }
                  </ul>
                </div>

                  {/* <OverlayTrigger
                    trigger={['hover', 'focus']}
                    placement="top"
                    overlay={<Tooltip id="example-tooltip">Basic Details</Tooltip>}
                  >
                    <Link
                      className="btn btn-primary ml-1 mb-1"
                      aria-expanded="false"
                      to={'/companies/'+data.id}
                    >
                      <Icon.Eye />
                    </Link>
                  </OverlayTrigger>
                  
                  {
                    role == 'admin' && (
                      <>
                        <OverlayTrigger
                          trigger={['hover', 'focus']}
                          placement="top"
                          overlay={<Tooltip id="example-tooltip">Edit Company</Tooltip>}
                        >
                          <button
                            className="btn btn-primary ml-1 mb-1"
                            aria-expanded="false"
                            onClick={() => {
                              editCompanyModalBtn(data);
                            }}
                          >
                            <Icon.Edit />
                          </button>
                        </OverlayTrigger>

                        <OverlayTrigger
                          trigger={['hover', 'focus']}
                          placement="top"
                          overlay={<Tooltip id="example-tooltip">Delete</Tooltip>}
                        >
                          <button
                            className="btn btn-danger ml-1 mb-1"
                            aria-expanded="false"
                            onClick={() => {
                              deleteBtn(data);
                            }}
                          >
                            <Icon.Trash />
                          </button>
                        </OverlayTrigger>
                      </>
                    )
                  } */}
              </div>
            </>
          );
        },
      },
    ],
    []
  );

  const data = getCompanies;

  return (
    <>
      <div className="pageTitle">
        <div className="">
          <h1 className="page-heading">Companies</h1>
          <nav aria-label="breadcrumb">
            <ol className="breadcrumb">
              <li className="breadcrumb-item"><a href="/">Home</a></li>
              <li className="breadcrumb-item active" aria-current="page">Companies</li>
            </ol>
          </nav>
        </div>
        <div>
          {
            role == 'admin' && (
              <Button variant="btn btn-primary" onClick={handleCompanyModalShow}>
                <Icon.Plus /> Add Company
              </Button>
            )
          }
        </div>
      </div>

      {/* Set Password Modal */}
      <Modal
        className="customModal" // hide-btn-close
        backdrop="static"
        centered
        show={showPasswordModal}
        onHide={handlePasswordModalClose}
      >
        <Modal.Header>
          <Modal.Title><Icon.Key></Icon.Key>Set Password</Modal.Title>
          <button
            type="button"
            className="btn-close"
            onClick={handlePasswordModalClose}
          >
          </button>
        </Modal.Header>
        <Modal.Body>
          <form>
            {/* <div className="mb-3">
              <label for="old_password" className="form-label">
                Old Password
              </label>
              <input type="password" className="form-control" id="old_password" required/>
            </div> */}
            
            {/* <div className="mb-3">
              <label for="new_password" className="form-label">
                New Password
              </label>
              <input type="password" className="form-control" ref={new_password} required />
            </div>
            <div className="mb-3">
              <label for="confirm_new_password" className="form-label">
                Confirm Password
              </label>
              <input type="password" className="form-control" ref={confirm_new_password} required />
            </div> */}

            <div className="row">
            <div class="col-xl-12 col-lg-12">
                <div class="form-group mb-3">
                  <label for="new_password">New Password</label>
                  <div className="innerInputIcon">
                    <input
                      type={newPasswordType?("text"):("password")}
                      className="form-control"
                      id="new_password"
                      name="new_password"
                      placeholder='minimum 8 digit password'
                      ref={new_password}
                    />
                    <div className="innerInputIcons">
                      {
                        newPasswordType?
                        (
                          <span onClick={() => {setNewPasswordType(false)}} className="eyeOff"><Icon.EyeOff /></span>
                        ):(
                          <span onClick={() => {setNewPasswordType(true)}} className="eye"><Icon.Eye /></span>
                        )
                      }
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-xl-12 col-lg-12">
                <div class="form-group mb-3">
                  <label for="password_confirmation">Confirm Password</label>
                  <div className="innerInputIcon">
                    <input
                      type={confirmPasswordType?("text"):("password")}
                      class="form-control"
                      id="email_confirmation"
                      name="email_confirmation"
                      placeholder='Must be same as new password'
                      ref={confirm_new_password}
                    />
                    <div className="innerInputIcons">
                      {
                        confirmPasswordType?
                        (
                          <span onClick={() => {setConfirmPasswordType(false)}} className="eyeOff"><Icon.EyeOff /></span>
                        ):(
                          <span onClick={() => {setConfirmPasswordType(true)}} className="eye"><Icon.Eye /></span>
                        )
                      }
                    </div>
                  </div>
                </div>
              </div>
            </div>

          </form>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="btn btn-outline-primary"
            onClick={handlePasswordModalClose}
          >
            Close
          </Button>
          <button type="submit" className="btn btn-primary" onClick={setPassword} disabled={btnLoading}>
            {
              btnLoading ?
                (
                  <>Please Wait <span className="spinner-grow spinner-grow-sm ml-2" role="status" aria-hidden="true"></span></>
                ) : (
                  <>Set Password</>
                )
            }
          </button>
        </Modal.Footer>
      </Modal>

      {/* Add Company Modal */}
      <Modal
        className="customModal" // hide-btn-close
        backdrop="static"
        centered
        size="xl"
        show={showCompanyModal}
        onHide={handleCompanyModalClose}
      >
        <Modal.Header closeButton>
          <Modal.Title><img className="modalLogo" src={logo} /> Add Company</Modal.Title>
          {/* <button
            type="button"
            className="btnClose custom"
            onClick={() => { handleCompanyModalClose() }}
          >
            <i className="fa fa-close"></i>
          </button> */}
        </Modal.Header>
        <Modal.Body>
            <form>
              <div className="row">
                <div className="col-sm-3"></div>
                <div className="col-sm-6">
                  <div className="row">
                      <div className="col-sm-12">
                          <div className="mb-3">
                              <label for="email" className="form-label">
                                  Company Name
                              </label>
                              <input type="email" className="form-control" id="email" required value={getCompanyName} onChange={e => setCompanyName(e.target.value)} />
                          </div>
                      </div>
                      <div className="col-sm-12">
                          <div className="mb-3">
                              <label for="email" className="form-label">
                                  Main Phone Number
                              </label>
                              <input type="email" className="form-control" id="email" required value={getCompanyMainPhone} onChange={e => setCompanyMainPhone(e.target.value)} />
                          </div>
                      </div>
                  </div>
                  <div className="row">
                      <div className="col-sm-12">
                          <div className="mb-3">
                              <label for="email" className="form-label">
                                  Main Address
                              </label>
                              <input type="email" className="form-control" id="email" required value={getCompanyMainAddress} onChange={e => setCompanyMainAddress(e.target.value)} />
                          </div>
                      </div>
                      <div className="col-sm-12">
                          <div className="mb-3">
                              <label for="industry" className="form-label">
                                  Industry
                              </label>
                              <Select
                                  className='customSelectDropdown'
                                  isClearable={false}
                                  onChange={handleChangeCompanyIndustry}
                                  options={getIndustries}
                                  name='industry_id'
                                  getOptionValue={(option) => option.id}
                                  getOptionLabel={(option) => option.name}
                                  placeholder='Select an industry...'
                              />
                          </div>
                      </div>
                  </div>
                </div>
                <div className="col-sm-3"></div>
              </div>
              {/* <div className="row mb-3">
                <div className="col-sm-7">
                  <label className="form-label">
                      Other site addresses
                  </label>
                  <CompaniesOtherSiteAddresses 
                    otherSiteAddresses={getCompanyOtherSiteAddresses} 
                    onSelect={handleOtherSiteAddressesChange} 
                  />
                </div>
                <div className="col-sm-5">
                  <label className="form-label">
                      Other site phone numbers
                  </label>
                  <CompaniesOtherSitePhoneNumbers 
                      otherSitePhoneNumbers={getCompanyOtherSitePhoneNumbers} 
                      onSelect={handleOtherSitePhoneNumbersChange} 
                  />
                </div>
              </div> */}
            </form>
        </Modal.Body>
        <Modal.Footer>
          <div className="row">
            <div className="col-sm-3"></div>
            <div className="col-sm-6">
              <div className="centeredBtns">
                <Button
                  variant="btn btn-outline-primary"
                  onClick={handleCompanyModalClose}
                >
                  Close
                </Button>
                <button type="submit" className="btn btn-primary" disabled={btnLoading} onClick={saveBtn}>
                  {
                    btnLoading ?
                      (
                        <>Please Wait <span className="spinner-grow spinner-grow-sm ml-2" role="status" aria-hidden="true"></span></>
                      ) : (
                        <>Save</>
                      )
                  }
                </button>
              </div>
            </div>
            <div className="col-sm-3"></div>
          </div>
        </Modal.Footer>
      </Modal>

      {/* Edit Company Modal */}
      <CompaniesEditModal propgetCompanyData={getCompanyData} propshowEditCompanyModal={showEditCompanyModal} prophandleEditCompanyModalClose={handleEditCompanyModalClose} propgetEditComponentLoading={getEditComponentLoading} companiesRequest={companiesRequest}></CompaniesEditModal>
      {/* <Modal
        className="customModal" // hide-btn-close
        backdrop="static"
        centered
        size="xl"
        show={showEditCompanyModal}
        onHide={handleEditCompanyModalClose}
      >
        <Modal.Header closeButton>
          <Modal.Title><img className="modalLogo" src={logo} /> Edit Company</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form>
            {getEditComponentLoading ? (
              <div className="card loadingCard">
                <div className="card-body">
                  <ComponentLoading></ComponentLoading>
                </div>
              </div>
            ) : (
              <>
                <div className="row">
                    <div className="col-sm-7">
                        <div className="mb-3">
                            <label for="email" className="form-label">
                                Company Name
                            </label>
                            <input type="email" className="form-control" id="email" required value={getCompanyName} onChange={e => setCompanyName(e.target.value)} />
                        </div>
                    </div>
                    <div className="col-sm-5">
                        <div className="mb-3">
                            <label for="email" className="form-label">
                                Main Phone Number
                            </label>
                            <input type="email" className="form-control" id="email" required value={getCompanyMainPhone} onChange={e => setCompanyMainPhone(e.target.value)} />
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-sm-7">
                        <div className="mb-3">
                            <label for="email" className="form-label">
                                Main Address
                            </label>
                            <input type="email" className="form-control" id="email" required value={getCompanyMainAddress} onChange={e => setCompanyMainAddress(e.target.value)} />
                        </div>
                    </div>
                    <div className="col-sm-5">
                        <div className="mb-3">
                            <label for="industry" className="form-label">
                                Industry
                            </label>
                            <Select
                                className='customSelectDropdown'
                                isClearable={false}
                                onChange={handleChangeCompanyIndustry}
                                value={getCompanyIndustry}
                                options={getIndustries}
                                getOptionValue={(option) => option.id}
                                getOptionLabel={(option) => option.name}
                                name='industry_id'
                                placeholder='Select an industry...'
                            />
                        </div>
                    </div>
                </div>
                <div className="row mb-3">
                  <div className="col-sm-7">
                    <label className="form-label">
                        Other site addresses
                    </label>
                    <CompaniesOtherSiteAddresses 
                      otherSiteAddresses={getCompanyOtherSiteAddresses} 
                      onSelect={handleOtherSiteAddressesChange} 
                    />
                  </div>
                  <div className="col-sm-5">
                    <label className="form-label">
                        Other site phone numbers
                    </label>
                    <CompaniesOtherSitePhoneNumbers 
                        otherSitePhoneNumbers={getCompanyOtherSitePhoneNumbers} 
                        onSelect={handleOtherSitePhoneNumbersChange} 
                    />
                  </div>
                </div>
              </>
            )}
          </form>
        </Modal.Body>
        <Modal.Footer>
          <div className="row">
            <div className="col-sm-3"></div>
            <div className="col-sm-6">
              <div className="centeredBtns">
                <Button
                  variant="btn btn-outline-primary closeBtn"
                  onClick={handleEditCompanyModalClose}
                >
                  Close
                </Button>
                <button type="submit" className="btn btn-primary saveBtn" disabled={btnLoading} onClick={updateBtn}>
                  {
                    btnLoading ?
                      (
                        <>Please Wait <span className="spinner-grow spinner-grow-sm ml-2" role="status" aria-hidden="true"></span></>
                      ) : (
                        <>Save</>
                      )
                  }
                </button>
              </div>
            </div>
            <div className="col-sm-3"></div>
          </div>
        </Modal.Footer>
      </Modal> */}

      {/* Page Content */}
      {getComponentLoading ? (
        <div className="card loadingCard">
          <div className="card-body">
            <ComponentLoading></ComponentLoading>
          </div>
        </div>
      ) : (
        <div className="card customCard">
          <Table columns={columns} data={data} />
        </div>
      )}
    </>
  );
};

export default CompaniesPage;
