import React, { useContext, useEffect, useRef, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import axiosClient from "../axios-client";
import Userimg from "../images/man-2.png";
import axios from "axios";
import {
  useTable,
  useFilters,
  useGlobalFilter,
  useSortBy,
  useAsyncDebounce,
  usePagination,
} from "react-table";
import * as Icon from "react-feather";
import { ComponentLoading } from "../Components/ComponentLoading";
import { ToastContainer, toast } from "react-toastify";
import Swal from "sweetalert2";
import { Modal, Button, Nav, Tab, Row, Col } from "react-bootstrap";
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import moment from 'moment';
import Select from 'react-select'
import { ActionMeta, OnChangeValue } from 'react-select';

import DatePicker from "react-datepicker";

import logo from '../images/cr-computer.png'

// ** Store Imports
import { useDispatch, useSelector } from 'react-redux'

//token
const accessToken = localStorage.getItem("ACCESS_TOKEN");

function CompanyViewModal({
    prop_employeeViewModal,
    prop_handleEmployeeModalViewClose,
    prop_employeeId
}) {

    const modalBodyRef = useRef(null);
    
    const stMainUser = useSelector((state) => state.stMainUser)

    // const [getViewComponentLoading, setViewComponentLoading] = useState(true);
  const [getComponentLoading, setComponentLoading] = useState(true);
    const [btnLoading, setBtnLoading] = useState(false)

    // View Company Modal
    const [employeeViewModal, setEmployeeViewModal] = useState(false);
    const handleEmployeeViewModalClose = () => {
        setEmployeeViewModal(false)
        prop_handleEmployeeModalViewClose()
        scrollToTop()
    };

    const [getLocations, setCompanySites] = useState([])

    // const [getLocations, setLocations] = useState(["main","warehouse","port_chester","sunrise_warehouse","blendex"])

    const [getSharedDriveAccess, setSharedDriveAccess] = useState(["renu","commercial","doors","accounting","accounting2","compliance","ea","hr","marketing","payroll","photography","quickbooks","w_9","warehouse"])

    const [getSoftwares, setSoftwares] = useState(["office_360","rfms","timberline","timberscan","measure_it","pro_tech","quickbooks","quicken","nitropdf","plan_swift","xactimate","ms_project","adobe_acrobat_pro","rms_operations"])

    const [getCameraSystem, setCameraSystem] = useState(["main","warehouse","port_chester","sunrise_warehouse","blendex","all","none"])

    const [getLaptop, setLaptop] = useState(["standard","new_desk_setup","other"])

    const [getPrimaryEmailDomain, setPrimaryEmailDomain] = useState(["no_email","crcomputers.com","crcomputers2.com","crcomputers3.com"])

    const [getGroupMembership, setGroupMembership] = useState(["remove_web","admin","other"])

    const [getFormStep, setFormStep] = useState("first");
    const [getEmployeeId, setEmployeeId] = useState(null);

    // Step first
    const [get_requesting_user, set_requesting_user] = useState("N/A");
    const [get_new_employee_name, set_new_employee_name] = useState("N/A");
    const [get_job_title, set_job_title] = useState("N/A");
    const [get_phone_number, set_phone_number] = useState("N/A");
    const [get_start_date, set_start_date] = useState("N/A");
    const [get_location, set_location] = useState("N/A");
    const [get_other_information, set_other_information] = useState("N/A");
    
    // Step second
    const [get_group_membership, set_group_membership] = useState("N/A");
    const [get_shared_drive_access, set_shared_drive_access] = useState("N/A");
    const [get_software, set_software] = useState("N/A");
    const [get_camera_system, set_camera_system] = useState("N/A");
    const [get_other_things_note, set_other_things_note] = useState("N/A");
    
    // Step third
    const [get_laptop, set_laptop] = useState("N/A");
    const [get_laptop_other, set_laptop_other] = useState("N/A");
    const [get_personal_printer, set_personal_printer] = useState(null);
    const [get_desk_scanner, set_desk_scanner] = useState(null);
    const [get_desk_phone, set_desk_phone] = useState(null);
    const [get_ipad, set_ipad] = useState(null);
    const [get_other_equipment, set_other_equipment] = useState("N/A");

    // Step fourth
    const [get_setup_email_on_cellphone, set_setup_email_on_cellphone] = useState("N/A");
    const [get_primary_email_domain, set_primary_email_domain] = useState("N/A");
    const [get_special_requests, set_special_requests] = useState("N/A");

    const [get_approved_supervisor_name, set_approved_supervisor_name] = useState("N/A");
    const [get_on_date, set_on_date] = useState("N/A");
    const [get_approval_other_information, set_approval_other_information] = useState("N/A");
    
    const [getStepsData, setStepsData] = useState({
        step_first: 'on',
        step_second: 'completed',
        step_third: 'completed',
        step_fourth: 'completed',
    });

    const handleFormStepBtn = (step) => {
        setFormStep(step)
        scrollToTop()
    }

    const init = () => {
        if(stMainUser.stFetchCompanySites.company_sites){
            setCompanySites(stMainUser.stFetchCompanySites.company_sites);
        }
    };

    const getEmployeeData = (empId) => {
        setComponentLoading(true)
        let config = {
          method: "get",
          url: process.env.REACT_APP_API_URL + "/employees/"+empId+"/get",
          headers: {
            Accept: "application/json",
            Authorization: `Bearer ${accessToken}`,
          },
        };
    
        axios(config)
            .then((response) => {

                const emp = response.data.employee
                
                // Step first
                if(emp.requesting_user){ set_requesting_user(emp.requesting_user) }
                if(emp.new_employee_name){ set_new_employee_name(emp.new_employee_name) }
                if(emp.job_title){ set_job_title(emp.job_title) }
                if(emp.phone_number){ set_phone_number(emp.phone_number) }
                if(emp.start_date){
                    const d = moment(emp.start_date).format('MM/D/YYYY');
                    set_start_date(d)
                    // set_start_date(new Date(d))
                }
                if(emp.location){ set_location(emp.location) }
                if(emp.other_information){ set_other_information(emp.other_information) }

                // Step second
                if(emp.group_membership){ set_group_membership(JSON.parse(emp.group_membership)) }
                if(emp.shared_drive_access){ set_shared_drive_access(JSON.parse(emp.shared_drive_access)) }
                if(emp.software){ set_software(JSON.parse(emp.software)) }
                if(emp.camera_system){ set_camera_system(JSON.parse(emp.camera_system)) }
                if(emp.other_things_note){ set_other_things_note(emp.other_things_note) }
                
                // Step third
                if(emp.laptop){ set_laptop(JSON.parse(emp.laptop)) }
                if(emp.laptop_other){ set_laptop_other(emp.laptop_other) }
                if(emp.personal_printer){ set_personal_printer(emp.personal_printer) }
                if(emp.desk_scanner){ set_desk_scanner(emp.desk_scanner) }
                if(emp.desk_phone){ set_desk_phone(emp.desk_phone) }
                if(emp.ipad){ set_ipad(emp.ipad) }
                if(emp.other_equipment){ set_other_equipment(emp.other_equipment) }

                // // Step fourth
                if(emp.setup_email_on_cellphone){ set_setup_email_on_cellphone(emp.setup_email_on_cellphone) }
                if(emp.primary_email_domain){ set_primary_email_domain(emp.primary_email_domain) }
                if(emp.special_requests){ set_special_requests(emp.special_requests) }

                if(emp.approved_supervisor_name){ set_approved_supervisor_name(emp.approved_supervisor_name) }
                if(emp.on_date){
                    const d = moment(emp.on_date).format('MM/D/YYYY');
                    set_on_date(d)
                    // console.log('d=',d)
                }
                if(emp.approval_other_information){ set_approval_other_information(emp.approval_other_information) }

                setComponentLoading(false);

            })
            .catch((error) => {
                setComponentLoading(false);
            });
    };

    useEffect(() => {

        init()

        if(prop_employeeId){
            // console.log('prop_employeeId=',prop_employeeId)
            setEmployeeId(prop_employeeId)
            getEmployeeData(prop_employeeId)
        }

        if(prop_employeeViewModal){
            setEmployeeViewModal(prop_employeeViewModal)
        }

        // console.log('getFormStep=>>>',getFormStep)
        
    }, [prop_employeeViewModal, prop_employeeId]);

    const handleGroupMembershipChange = (value) => {
        if (get_group_membership.includes(value)) {
            set_group_membership(get_group_membership.filter(item => item !== value));
        } else {
            set_group_membership([...get_group_membership, value]);
        }
    }

    const handleSharedDriveAccessChange = (value) => {
        if (get_shared_drive_access.includes(value)) {
            set_shared_drive_access(get_shared_drive_access.filter(item => item !== value));
        } else {
            set_shared_drive_access([...get_shared_drive_access, value]);
        }
    }

    const handleSoftwareChange = (value) => {
        if (get_software.includes(value)) {
            set_software(get_software.filter(item => item !== value));
        } else {
            set_software([...get_software, value]);
        }
    }

    const handleCameraSystemChange = (value) => {
        if (get_camera_system.includes(value)) {
            set_camera_system(get_camera_system.filter(item => item !== value));
        } else {
            set_camera_system([...get_camera_system, value]);
        }
    }

    const handleLaptopChange = (value) => {
        if (get_laptop.includes(value)) {
            set_laptop(get_laptop.filter(item => item !== value));
        } else {
            set_laptop([...get_laptop, value]);
        }
    }

    const handleDateSelectStartDate = (date) => {
        console.log('handleDateSelectStartDate=',date)
        set_start_date(date)
    }

    const handleDateSelectOnDate = (date) => {
        // console.log('handleDateSelectOnDate=',date)
        set_on_date(date)
    }

    const scrollToTop = () =>{
        if (modalBodyRef.current) {
            modalBodyRef.current.scrollTop = 0;
        }
    }

    const saveBtn = (stepData) => {
        setFormStep(stepData)
        console.log('stepData=',stepData)
        if(stepData == 'first'){
            setStepsData(prevState => {
                return {
                    ...prevState,
                    step_first: 'on',
                    step_second: 'completed',
                    step_third: 'completed',
                    step_fourth: 'completed',
                };
            });
        }
        if(stepData == 'second'){
            setStepsData(prevState => {
                return {
                    ...prevState,
                    step_first: 'completed',
                    step_second: 'on',
                    step_third: 'completed',
                    step_fourth: 'completed',
                };
            });
        }
        if(stepData == 'third'){
            setStepsData(prevState => {
                return {
                    ...prevState,
                    step_first: 'completed',
                    step_second: 'completed',
                    step_third: 'on',
                    step_fourth: 'completed',
                };
            });
        }
        if(stepData == 'fourth'){
            setStepsData(prevState => {
                return {
                    ...prevState,
                    step_first: 'completed',
                    step_second: 'completed',
                    step_third: 'completed',
                    step_fourth: 'on',
                };
            });
        }
        if(stepData == 'fifth'){
            setStepsData(prevState => {
                return {
                    ...prevState,
                    step_fourth: 'completed'
                };
            });
        }
        scrollToTop()
        console.log('getFormStep2=',getFormStep)

    }

    useEffect(() => {
        console.log('getFormStep=>>>',getFormStep)
    }, [getFormStep]);

  return (
    <>
    <Modal
        className="customModal view" // hide-btn-close
        backdrop="static"
        centered
        size="xl"
        show={employeeViewModal}
        onHide={handleEmployeeViewModalClose}
      >
        <Modal.Header closeButton>
          <Modal.Title>
            <img className="modalLogo" src={logo} />

            <div>
                <ul className="stepsHeading">
                    <li onClick={() => {saveBtn('first')}} className={getStepsData.step_first}>
                        <span className={"step step_first "+getStepsData.step_first}>
                            <Icon.CheckCircle />
                        </span>
                        <span className="heading">Employee Information</span>
                    </li>
                    <li onClick={() => {saveBtn('second')}} className={getStepsData.step_second}>
                        <span className={"step step_second "+getStepsData.step_second}>
                            <Icon.CheckCircle />
                        </span>
                        <span className="heading">Network Access & Software</span>
                    </li>
                    <li onClick={() => {saveBtn('third')}} className={getStepsData.step_third}>
                        <span className={"step step_third "+getStepsData.step_third}>
                            <Icon.CheckCircle />
                        </span>
                        <span className="heading">Equipment</span>
                    </li>
                    <li onClick={() => {saveBtn('fourth')}} className={getStepsData.step_fourth}>
                        <span className={"step step_fourth "+getStepsData.step_fourth}>
                            <Icon.CheckCircle />
                        </span>
                        <span className="heading">Configurations</span>
                    </li>
                </ul>
            </div>

          </Modal.Title>
        </Modal.Header>
        <Modal.Body ref={modalBodyRef}>
            {setFormStep}
          <form>
            {getComponentLoading ? (
                <div className="card loadingCard">
                <div className="card-body">
                    <ComponentLoading></ComponentLoading>
                </div>
                </div>
            ) : (
                <>
                {/* {getEmployeeId} */}
                <div className="row">
                    <div className="col-sm-3"></div>
                    <div className="col-sm-6">
                        {
                            getFormStep == 'first'?
                            (
                                <>
                                <h4>Employee Information</h4>
                                <div className="mb-3">
                                    <label for="get_requesting_user" className="form-label">
                                        Requesting User: (Your name, first and last)<span className="text-danger">*</span>
                                        <strong className="inputVal"> {get_requesting_user}</strong>
                                    </label>
                                </div>
                                <div className="mb-3">
                                    <label for="get_new_employee_name" className="form-label">
                                        New Employee Name (Their name, first and last)<span className="text-danger">*</span>
                                        <strong className="inputVal"> {get_new_employee_name}</strong>
                                    </label>
                                </div>
                                <div className="mb-3">
                                    <label for="get_job_title" className="form-label">
                                        Job Title<span className="text-danger">*</span>
                                        <strong className="inputVal"> {get_job_title}</strong>
                                    </label>
                                </div>
                                <div className="mb-3">
                                    <label for="get_phone_number" className="form-label">
                                        Phone Number: 
                                        <strong className="inputVal"> {get_phone_number}</strong>
                                    </label>
                                </div>
                                <div className="mb-3">
                                    <label for="get_start_date" className="form-label">
                                        Start Date<span className="text-danger">*</span>
                                        <strong className="inputVal"> {get_start_date}</strong>
                                    </label>
                                </div>
                                <div className="mb-3">
                                    <label for="get_location" className="form-label">
                                        Location<span className="text-danger">*</span>
                                    </label>
                                    {
                                        getLocations.length > 0 && (
                                            getLocations.map((item,index) => (
                                                get_location == item.id && (
                                                    <>
                                                    <strong className="inputVal"> {item.name}</strong>
                                                    </>
                                                )
                                                // <>
                                                //     <div className="form-check" key={index}>
                                                //         <input
                                                //             className="form-check-input"
                                                //             type="radio"
                                                //             id={'location-'+index}
                                                //             name="location"
                                                //             value={item.id}
                                                //             checked={get_location == item.id}
                                                //             onChange={e => set_location(e.target.value)}
                                                //         />
                                                //         <label className="form-check-label" htmlFor={'location-'+index}>{item.name}</label>
                                                //     </div>
                                                // </>
                                            ))
                                        )
                                    }
                                    {/* {getLocations.map(location => (
                                        <div className="form-check" key={location}>
                                            <input
                                                disabled
                                                className="form-check-input"
                                                type="radio"
                                                id={location}
                                                name="location"
                                                value={location}
                                                checked={get_location === location}
                                                onChange={e => set_location(e.target.value)}
                                            />
                                            <label className="form-check-label" htmlFor={location}>{location}</label>
                                        </div>
                                    ))} */}
                                </div>
                                <div className="mb-3">
                                    <label for="get_other_information" className="form-label">
                                        Other Information (Desk location)
                                        <strong className="inputVal"> {get_other_information}</strong>
                                    </label>
                                </div>
                                </>
                            ):getFormStep == 'second'?
                            (
                                <>
                                <h4>Network Access and Software</h4>
                                <p>Each full-time employee will be issued a network login which includes Domain access and access to file shares.</p>
                                <div className="mb-3 d-flex">
                                    <label for="get_location" className="form-label">
                                    Group Membership<span className="text-danger">*</span>
                                    </label>
                                    <ul className="pl-10">
                                        {getGroupMembership.map(item => (
                                            get_group_membership.includes(item)?
                                            (
                                                <>
                                                <li key={item}>
                                                <strong className="inputVal"> {item}</strong>
                                                </li>
                                                </>
                                            ):(
                                                <>
                                                {/* <li key={item}>
                                                <del>{item}</del>
                                                </li> */}
                                                </>
                                            )
                                        ))}
                                    </ul>
                                </div>
                                <div className="mb-3 d-flex">
                                    <label for="get_location" className="form-label">
                                    Shared Drive Access: Please check only those that apply<span className="text-danger">*</span>
                                    </label>
                                    <ul className="pl-10">
                                        {getSharedDriveAccess.map(item => (
                                            get_shared_drive_access.includes(item)?
                                            (
                                                <>
                                                <li key={item}>
                                                <strong className="inputVal"> {item}</strong>
                                                </li>
                                                </>
                                            ):(
                                                <>
                                                {/* <li key={item}>
                                                <del className="inputVal"> {item}</del>
                                                </li> */}
                                                </>
                                            )
                                        ))}
                                    </ul>
                                </div>
                                <div className="mb-3 d-flex">
                                    <label className="form-label">
                                    Software: 
                                    </label>
                                    <ul className="pl-10">
                                        {getSoftwares.map(item => (
                                            get_software.includes(item)?
                                            (
                                                <>
                                                <li key={item}>
                                                <strong className="inputVal"> {item}</strong>
                                                </li>
                                                </>
                                            ):(
                                                <>
                                                {/* <li key={item}>
                                                <del className="inputVal"> {item}</del>
                                                </li> */}
                                                </>
                                            )
                                        ))}
                                    </ul>
                                </div>
                                <div className="mb-3 d-flex">
                                    <label className="form-label">
                                    Camera System:
                                    </label>
                                    <ul className="pl-10">
                                        {getCameraSystem.map(item => (
                                            get_camera_system.includes(item)?
                                            (
                                                <>
                                                <li key={item}>
                                                <strong className="inputVal"> {item}</strong>
                                                </li>
                                                </>
                                            ):(
                                                <>
                                                {/* <li key={item}>
                                                <del className="inputVal"> {item}</del>
                                                </li> */}
                                                </>
                                            )
                                        ))}
                                    </ul>
                                </div>
                                <div className="mb-3">
                                    <label for="get_other_things_note" className="form-label">
                                        Other things to note:
                                        <strong className="inputVal"> {get_other_things_note}</strong>
                                    </label>
                                </div>
                                </>
                            ):getFormStep == 'third'?
                            (
                                <>
                                <h4>Equipment</h4>
                                <p>Existing equipment will be used first.</p>
                                <div className="mb-3 d-flex">
                                    <label for="get_location" className="form-label">
                                    Laptop<span className="text-danger">*</span>
                                    </label>
                                    <ul className="pl-10">
                                        {getLaptop.map(item => (
                                            get_laptop.includes(item)?
                                            (
                                                <>
                                                <li key={item}>
                                                <strong className="inputVal"> {item}</strong>
                                                </li>
                                                {
                                                    item == 'other' ? (
                                                        <>
                                                        <strong className="inputVal"> {get_laptop_other}</strong>
                                                        </>
                                                    ):(
                                                        ''
                                                    )
                                                }
                                                </>
                                            ):(
                                                <>
                                                {/* <li key={item}>
                                                <del className="inputVal"> {item}</del>
                                                </li> */}
                                                </>
                                            )
                                        ))}
                                    </ul>
                                </div>
                                <div className="mb-3">
                                    <label className="form-label">
                                        Personal Printer<span className="text-danger">*</span>
                                        <strong className="inputVal"> {
                                            get_personal_printer === 'yes'?('Yes'):('No')
                                        }</strong>
                                    </label>
                                </div>
                                <div className="mb-3">
                                    <label className="form-label">
                                        Desk Scanner<span className="text-danger">*</span>
                                        <strong className="inputVal"> {
                                            get_desk_scanner === 'yes'?('Yes'):('No')
                                        }</strong>
                                    </label>
                                </div>
                                <div className="mb-3">
                                    <label className="form-label">
                                        Desk Phone<span className="text-danger">*</span>
                                        <strong className="inputVal"> {
                                            get_desk_phone === 'yes'?('Yes'):('No')
                                        }</strong>
                                    </label>
                                </div>
                                <div className="mb-3">
                                    <label className="form-label">
                                        iPad (Generally PM's, Supers & Sales)<span className="text-danger">*</span>
                                        <strong className="inputVal"> {
                                            get_ipad === 'yes'?('Yes'):('No')
                                        }</strong>
                                    </label>
                                </div>
                                <div className="mb-3">
                                    <label for="get_other_equipment" className="form-label">
                                        Other Equipment and Special Requests
                                        <strong className="inputVal"> {get_other_equipment}</strong>
                                    </label>
                                </div>
                                </>
                            ):(
                                <>
                                <h4>Configurations</h4>
                                <div className="mb-3">
                                    <label className="form-label">
                                        Set up email on cellphone<span className="text-danger">*</span>
                                        <strong className="inputVal"> {
                                            get_setup_email_on_cellphone === 'yes'?('Yes'):('No')
                                        }</strong>
                                    </label>
                                </div>
                                <div className="mb-3">
                                    <label for="get_location" className="form-label">
                                        Primary Email Domain (crcomputers.com is used for all users)<span className="text-danger">*</span>
                                    </label>
                                    {
                                        getPrimaryEmailDomain.length > 0 && (
                                            getPrimaryEmailDomain.map((item,index) => (
                                                get_primary_email_domain == item && (
                                                    <>
                                                    <strong className="inputVal"> {item}</strong>
                                                    </>
                                                )
                                            ))
                                        )
                                    }
                                </div>
                                <div className="mb-3">
                                    <label for="get_special_requests" className="form-label">
                                        Special Requests:
                                        <strong className="inputVal"> {get_special_requests}</strong>
                                    </label>
                                </div>
                                <h4>Approval</h4>
                                <div className="mb-3">
                                    <label for="get_approved_supervisor_name" className="form-label">
                                        Approved by Supervisor: (Name)<span className="text-danger">*</span>
                                        <strong className="inputVal"> {get_approved_supervisor_name}</strong>
                                    </label>
                                </div>
                                <div className="mb-3">
                                    <label for="get_on_date" className="form-label">
                                        On date<span className="text-danger">*</span>
                                        <strong className="inputVal"> {get_on_date}</strong>
                                    </label>
                                </div>
                                <div className="mb-3">
                                    <label for="get_approval_other_information" className="form-label">
                                        Other information
                                        <strong className="inputVal"> {get_approval_other_information}</strong>
                                    </label>
                                </div>
                                </>
                            )
                        }
                    </div>
                    <div className="col-sm-3"></div>
                </div>
                </>
            )}
          </form>
        </Modal.Body>
        {/* <Modal.Footer>
          <div className="row">
            <div className="col-sm-3"></div>
            <div className="col-sm-6">
              <div className="centeredBtns">
                {
                    getFormStep == 'first'?
                    (
                        <>
                            <Button
                            variant="btn btn-outline-primary"
                            onClick={handleEmployeeViewModalClose}
                            >
                                Close
                            </Button>
                            <button type="submit" className="btn btn-primary" disabled={btnLoading} onClick={() => {saveBtn('second')}}>
                            {
                                btnLoading ?
                                (
                                    <>Please Wait <span className="spinner-grow spinner-grow-sm ml-2" role="status" aria-hidden="true"></span></>
                                ) : (
                                    <>Next</>
                                )
                            }
                            </button>
                        </>
                    ):getFormStep == 'second'?
                    (
                        <>
                            <Button
                            variant="btn btn-outline-primary"
                            onClick={() => {saveBtn('first')}}
                            >
                                Back
                            </Button>
                            <button type="submit" className="btn btn-primary" disabled={btnLoading} onClick={() => {saveBtn('third')}}>
                            {
                                btnLoading ?
                                (
                                    <>Please Wait <span className="spinner-grow spinner-grow-sm ml-2" role="status" aria-hidden="true"></span></>
                                ) : (
                                    <>Next</>
                                )
                            }
                            </button>
                        </>
                    ):getFormStep == 'third'?
                    (
                        <>
                            <Button
                            variant="btn btn-outline-primary"
                            onClick={() => {saveBtn('second')}}
                            >
                                Back
                            </Button>
                            <button type="submit" className="btn btn-primary" disabled={btnLoading} onClick={() => {saveBtn('fourth')}}>
                            {
                                btnLoading ?
                                (
                                    <>Please Wait <span className="spinner-grow spinner-grow-sm ml-2" role="status" aria-hidden="true"></span></>
                                ) : (
                                    <>Next</>
                                )
                            }
                            </button>
                        </>
                    ):(
                        <>
                        <Button
                        variant="btn btn-outline-primary"
                        onClick={() => {saveBtn('third')}}
                        >
                            Back
                        </Button>
                        <Button
                        variant="btn btn-outline-primary"
                        onClick={handleEmployeeViewModalClose}
                        >
                            Close
                        </Button>
                        </>
                    )
                }
              </div>
            </div>
            <div className="col-sm-3"></div>
          </div>
        </Modal.Footer> */}
      </Modal>
    </>
  );
}

export default CompanyViewModal;
